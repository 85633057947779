import React from "react"

import "./style.css"

const ItemRow = ({ item, onItemClick, onDeleteClick, background }) => {
  const { title, amount } = item

  const trashIcon = require("../../../../../../assets/images/trash.svg")

  return (
    <div
      className={`itls-itm-wrap ${background}`}
      onClick={() => onItemClick(item)}
    >
      <div
        className="trash"
        onClick={(e) => onDelClick(e, item, onDeleteClick)}
      >
        {title && amount ? <img src={trashIcon} alt="" /> : ""}
      </div>

      <div className="item-title">{title}</div>
      <div className="amount">{amount}</div>
    </div>
  )
}

const onDelClick = (event, item, onDeleteClick) => {
  event.stopPropagation()

  onDeleteClick(item)
}

export default ItemRow
