import { PRICE_LIST_FETCH_SUCCESS, DYNAMIC_ITEMS_FETCH_SUCCESS } from '../actions/types';

const INIT_STATE = {
    pricesArrs: [],
    dynamicProducts: null
};

const PricesReducer = (state = INIT_STATE, action) => {
    switch (action.type) {
        case PRICE_LIST_FETCH_SUCCESS:
            return {
                ...state,
                pricesArrs: action.payload
            };
        case DYNAMIC_ITEMS_FETCH_SUCCESS:
            return {
                ...state,
                dynamicProducts: action.payload
            };
            
        default:
            return state;
    }
};

export default PricesReducer;
