import React from "react"

import "./style.css"
import PendingBtn from "../PendingSuccessFailBtn/PendingBtn"
import { DisableEnableButton } from "../DisableEnableButton"

const DisEnbPendBtn = ({
  children,
  isDisabled,
  isPending,
  onClick,
  extraClass,
  ...props
}) => {
  return (
    <DisableEnableButton
      extraClass={extraClass}
      isDisabled={isDisabled}
      onClick={(e) => onClickHandler(onClick, isPending)}
      tabIndex="0"
      role="button"
      {...props}
    >
      {renderChildrenOrPending(children, isPending)}
    </DisableEnableButton>
  )
}

const onClickHandler = (onClick, isPending) => {
  if (isPending) {
    return
  }
  onClick()
  return
}

const renderChildrenOrPending = (children, isPending) => {
  if (!isPending) return children

  return (
    <div className="dis-enb-pend-pending-btn-wrapper">
      <PendingBtn sizeInPx={40} />
    </div>
  )
}

export { DisEnbPendBtn }
