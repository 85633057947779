import React, { Component } from "react"
import Modal from "react-modal"
import { connect } from "react-redux"
import { withRouter } from "react-router-dom"
import {
  logScreenEnterance,
  setChosenCell,
  LogoutUser,
  setNetworkErrorIfExist,
} from "../../../../actions"
import CardCheckModal from "../../../Modals/CardCheckModal"
import CardCheckNAYAXModal from "../../../Modals/CardCheckNAYAXModal"
import { FixedFooter, FixedHeader, LockerCells } from "../../../reduxified"
import api from "../../../../api/LocalServiceApi"
import azureApi from "../../../../api/AzureApi"
import axios from "axios"
import errorImg from "../../../../assets/svg/error-sign.svg"
import "./style.css"
import { OrangeButton } from "../../../common"
import Loader from "../../../common/Loader"

class RentLockerScreen extends Component {
  constructor(props) {
    super(props)
    this.INIT_STATE = {
      isClickWaiting: false,
      showCardCheckModal: false,
      showCardCheckNAYAXModal: false,
      showYesNoInfoModal: false,
      cardToken: "",
      invalidToken: false,
      isOldUser: false,
      showLoader: true,
      sessionCanceled: false,
    }
    this.state = this.INIT_STATE
  }

  async componentDidMount() {
    const { authenticated, userId, stationPaymentVersion } = this.props
    if (!authenticated) {
      this.props.LogoutUser()
      this.props.history.push("")
    }
    this.props.logScreenEnterance("RENT_LOCKER_SCREEN")
    await axios
      .get(azureApi.isOldUser(userId))
      .then((res) => {
        this.setState({ isOldUser: res.data, showLoader: false })
      })
      .then(() => {
        !this.state.isOldUser &&
          this.props.logScreenEnterance("CREDIT_CARD_CHECK")
      })
      .catch((err) => console.error(err))
    console.log("this.state.isOldUser", this.state.isOldUser)
    if (!this.state.isOldUser && stationPaymentVersion !== 3) {
      this.setState({ showCardCheckModal: true })
    }
    if (!this.state.isOldUser && stationPaymentVersion === 3) {
      this.setState({ showCardCheckNAYAXModal: true })
    }
  }

  cancelPaymentNayax() {
    axios
      .get(api.cancelNayaxMultiSession)
      .then(() => {
        this.setState({ sessionCanceled: true })
        console.log("NAYAX session canceled", this.state.sessionCanceled)
      })
      .catch((err) => {
        console.log(err)
        this.props.setNetworkErrorIfExist(err)
      })
  }

  checkCreditCard(cCard, formattedDate) {
    axios
      .post(api.getPelecardToken, { cCard, formattedDate })
      .then((res) => {
        console.log(" res.data.receivedToken", res.data.receivedToken)
        res.data.receivedToken
          ? this.setState({
              cardToken: res.data.receivedToken,
              showCardCheckModal: false,
              showCardCheckNAYAXModal: false,
              showYesNoInfoModal: false,
            })
          : this.setState({
              invalidToken: true,
              showCardCheckModal: false,
              showCardCheckNAYAXModal: false,
              showYesNoInfoModal: true,
            })
      })
      .catch((err) => {
        console.log(err)
        this.setState({
          invalidToken: true,
          showCardCheckModal: false,
          showCardCheckNAYAXModal: false,
          showYesNoInfoModal: true,
        })
      })
  }

  checkNAYAXCreditCard = () => {
    axios
      .get(api.validationNAYAX)
      .then((res) => {
        if (res.data.isApproved) {
          console.log("res.data.isApproved", res.data.isApproved)
          this.setState({ showCardCheckNAYAXModal: false })
        }

        if (!this.state.sessionCanceled && !res.data.isApproved) {
          console.log("!res.data", res)
          this.setState({
            showCardCheckNAYAXModal: false,
            showYesNoInfoModal: true,
          })
        }
      })
      .catch(() => {
        !this.state.sessionCanceled &&
          this.setState({
            showCardCheckNAYAXModal: false,
          })
      })
  }

  render() {
    const {
      blinkers,
      errLockers,
      close_text,
      try_again,
      card_not_detected,
      choose_locker,
    } = this.props
    const {
      showCardCheckModal,
      showYesNoInfoModal,
      showLoader,
      showCardCheckNAYAXModal,
    } = this.state

    return (
      <>
        <div className="ls-wrapper">
          <FixedHeader buttonsFlag="unlockLockersScreen" />

          <div className="rent-locker-title">{choose_locker}</div>

          <div className="ddocs-inner-wrapper">
            {showLoader ? (
              <Loader />
            ) : (
              <LockerCells
                blinkers={blinkers}
                errLockers={errLockers}
                hideInfo={true}
                lockersForRent={true}
                showMinified
              />
            )}
          </div>
          <FixedFooter history={this.props.history} />
        </div>
        <CardCheckModal
          show={showCardCheckModal}
          onCloseModal={() => {
            this.setState({ showCardCheckModal: false })
            this.props.history.goBack()
          }}
          checkCreditCard={this.checkCreditCard.bind(this)}
        />
        {showCardCheckNAYAXModal && (
          <CardCheckNAYAXModal
            show={showCardCheckNAYAXModal}
            onCloseModal={() => {
              this.cancelPaymentNayax()
              this.props.history.goBack()
            }}
            cancelPaymentNayax={() => this.cancelPaymentNayax()}
            checkCreditCard={this.checkCreditCard.bind(this)}
            checkNAYAXCreditCard={this.checkNAYAXCreditCard.bind(this)}
          />
        )}
        <Modal
          isOpen={showYesNoInfoModal}
          className="react-modal-content"
          overlayClassName="modal-overlay"
        >
          <img src={errorImg} alt="_X_" />
          <div className="retry-text">{card_not_detected}</div>
          <div className="retry-buttons">
            <OrangeButton
              onClick={() => {
                const { stationPaymentVersion } = this.props
                this.setState({
                  showYesNoInfoModal: false,
                })
                stationPaymentVersion === 3
                  ? this.setState({
                      sessionCanceled: false,
                      showCardCheckNAYAXModal: true,
                    })
                  : this.setState({ showCardCheckModal: true })
              }}
            >
              {try_again}
            </OrangeButton>
            <OrangeButton onClick={() => this.props.history.goBack()}>
              {close_text}
            </OrangeButton>
          </div>
        </Modal>
      </>
    )
  }
}

const mapStateToProps = ({
  userLogin,
  lockersCells,
  translate,
  bgService,
  stationServiceTypes,
}) => {
  const { authenticated, role, userId } = userLogin
  const { openedLockersArr, errorLockersArr } = lockersCells
  const { stationPaymentVersion } = stationServiceTypes

  const {
    driver_menu_lockers_cells_opening,
    driver_lockers_cells_opening_subtitle,
    close_text,
    try_again,
    card_not_detected,
    choose_locker,
  } = translate
  const blinkers = {}
  openedLockersArr.map((lockNum) => (blinkers[lockNum] = lockNum))
  const errLockers = {}
  errorLockersArr.map((lockNum) => (errLockers[lockNum] = lockNum))
  const { stationId } = bgService

  return {
    authenticated,
    role,
    userId,
    stationPaymentVersion,
    blinkers,
    errLockers,
    driver_menu_lockers_cells_opening,
    driver_lockers_cells_opening_subtitle,
    stationId,
    close_text,
    try_again,
    card_not_detected,
    choose_locker,
  }
}

export default connect(mapStateToProps, {
  logScreenEnterance,
  setChosenCell,
  LogoutUser,
  setNetworkErrorIfExist,
})(withRouter(RentLockerScreen))
