//put this Component on page with no ping network
import React, { Component } from "react"
import { connect } from "react-redux"
import { readBGServiceAlive, goAliveAPI } from "../../actions"
import "./style.scss"

class PingBGInternet extends Component {
  constructor(props) {
    super(props)

    this.state = {
      checkBGInternet: 0,
    }
  }

  componentDidMount() {
    //console.log("network: start checkBGInternet")
    this.props.readBGServiceAlive()
    this.props.goAliveAPI()

    const checkBGInternet = setInterval(() => {
      //console.log("network: call checkBGInternet")
      this.props.readBGServiceAlive()
      this.props.goAliveAPI()
    }, 60000)
    this.setState({ checkBGInternet: checkBGInternet })
  }

  componentWillUnmount() {
    const { checkBGInternet } = this.state

    if (checkBGInternet) {
      clearInterval(checkBGInternet)
      //console.log("network: clear checkBGInternet")
      this.setState({ checkBGInternet: 0 })
    }
  }

  render() {
    return <div className="ping-bg-internet">Ping</div>
  }
}

const mapStateToProps = ({ userLogin, bgService, errorMessages }) => {
  //const { stationId } = bgService
  //const { serviceInternetError, reactInternetError } = errorMessages

  //const { authenticated } = userLogin

  return {
    //stationId,
    //authenticated,
    //serviceInternetError,
    //reactInternetError,
  }
}

export default connect(mapStateToProps, {
  readBGServiceAlive,
  goAliveAPI,
})(PingBGInternet)
