import React from "react"

import "./style.css"

const Header = ({ children }) => {
  return <div className="rodl-header-wrapper">{renderTitles(children)}</div>
}

const renderTitles = (items) => {
  return items.map((itm, index) => {
    const className = `rodl-header-title-wrapper ${
      (index === 2 || index === 3) && "move-right"
    }`

    return (
      <div className={className} key={itm}>
        {itm}
      </div>
    )
  })
}

export default Header
