import {
    ON_PAYMENT_REQUEST_START, ON_PAYMENT_FAIL, ON_PAYMENT_SUCCESS,
    ON_PAYMENT_NETWORK_FAIL, RESET_PAYMENT_TO_TRY_AGAIN, RESET_ALL_REDUCERS, ON_USER_SIGN_OUT
} from '../actions/types';

const INIT_STATE = {
    okBtnStatus: 0,     // IT WAS - "2,  // REGULAR BUTTON STATE.." - NOW - INITIAL STATE IS PENDING..
    loadingRequest: false,
    paymentFailed: false,
    paymentSuccess: false,
    showTryAgain: false,
    showConnectionErrMsg: false,
    pelecardToken: null,
    cardReadReset:true,

}

const PaymentModalReducer = (state = INIT_STATE, action) => {
   
    switch (action.type) {
        case ON_PAYMENT_REQUEST_START:
            return {
                ...state,
                okBtnStatus: 0,  // PENDING..!
                loadingRequest: true,
                paymentFailed: false,
                paymentSuccess: false,
                cardReadReset: false,
                pelecardToken: null,
            };
        case ON_PAYMENT_SUCCESS:
            return {
                ...state,
                okBtnStatus: 1, // SUCCESS..!
                loadingRequest: false,
                paymentFailed: false,
                paymentSuccess: true,
                pelecardToken: action.payload,
                cardReadReset: false,
                cardReadReset: true,
            };
        case ON_PAYMENT_FAIL:
            return {
                ...state,
                okBtnStatus: -1,  // FAIL..!
                showTryAgain: true,
                loadingRequest: false,
                paymentFailed: true,
                paymentSuccess: false,
                cardReadReset: true,
                pelecardToken: null,
            };
        case ON_PAYMENT_NETWORK_FAIL:
            return {
                ...state,
                okBtnStatus: -1,  // FAIL..!
                showConnectionErrMsg: true,
                loadingRequest: false,
                paymentFailed: true,
                paymentSuccess: false,
                cardReadReset: true,
                pelecardToken: null,
            };
        case ON_USER_SIGN_OUT:
        case RESET_PAYMENT_TO_TRY_AGAIN:
            return {
                ...INIT_STATE
            };
        case RESET_ALL_REDUCERS:
            return {
                ...INIT_STATE
            };
        default:
            return state;
    }
}

export default PaymentModalReducer;
