import React, { Component } from "react"
import { connect } from "react-redux"
import VirtalKeyboard from "../../common/VirtualKeyboard"
import {
  WhiteLabel,
  OrangeButton,
  InputFiled,
  OrangeSelect,
} from "../../common"
import Modal from "react-modal"
import errorImg from "../../../assets/svg/error-sign.svg"
import "./style.css"

class CardManuallyInputs extends Component {
  constructor(props) {
    super(props)

    this.INIT_STATE = {
      manualCreditCard: "",
      manualCreditCardErr: false,
      manualCreditCardRef: null,
      activeInput: null,
      activeInputName: null,
      month: 1,
      year: new Date().getFullYear(),
      showYesNoInfoModal: false,
    }

    this.state = this.INIT_STATE
  }

  onRefReady(inputName, ref) {
    if (this.state[`${inputName}Ref`]) {
      return
    }

    this.setState({
      [`${inputName}Ref`]: ref,
    })
  }

  onInputFocusChange(refName, isFocused) {
    if (isFocused) {
      this.setState({
        activeInput: this.state[refName],
        activeInputName: refName.replace("Ref", ""),
      })
    } else {
      if (this.state.activeInput === this.state[refName])
        this.setState({ activeInput: null })
    }
  }

  onKeyboardTextChange(text) {
    const { activeInputName, manualCreditCardErr } = this.state

    this.setState({ [activeInputName]: text })

    if (activeInputName === "manualCreditCard" && manualCreditCardErr)
      this.setState({ manualCreditCardErr: false })
  }

  onManualDataSubmit(month, year, manualCreditCard) {
    const { startPayingProcess } = this.props
    const creditCardData = manualCreditCard.trim().replace(/\s/g, "")

    if (!this.isManualCreditCardValueValid(creditCardData)) {
      this.setState({ manualCreditCardErr: true })

      return
    }

    const prefixMonth = month > 9 ? month : `0${month}`
    const dateMMYY = `${prefixMonth}${year.toString().substr(2, 2)}`

    startPayingProcess(creditCardData, dateMMYY)
  }

  isManualCreditCardValueValid(manualCreditCard) {
    if (manualCreditCard.length !== 9 && manualCreditCard.length !== 16) {
      return false
    }
    return true
  }

  render() {
    if (!this.props.show) {
      return
    }

    const {
      year,
      month,
      manualCreditCard,
      manualCreditCardErr,
      activeInputName,
      showYesNoInfoModal,
    } = this.state
    const {
      payment_credit_card_number,
      payment_month,
      payment_year,
      payment_approval,
      card_not_detected,
      try_again,
      close_text,
      dirRtl,
    } = this.props

    const allMonthsArr = [...new Array(12)].map((itm, index) => index + 1)
    const currYear = new Date().getFullYear()
    const allYearsArr = [...new Array(8)].map((itm, index) => index + currYear)

    return (
      <div className="paymdl2-manual-inputs-wrapper">
        <InputFiled
          value={manualCreditCard}
          onChange={(e) => this.onKeyboardTextChange(e.target.value)}
          displayErr={manualCreditCardErr}
          placeholder={payment_credit_card_number}
          onRefReady={(ref) => this.onRefReady("manualCreditCard", ref)}
          onFocusChanged={(isFocused) =>
            this.onInputFocusChange("manualCreditCardRef", isFocused)
          }
          maxLength={16}
          cardNumber
        />

        <div className="paymdl2-manual-month-year-wrapper" dir="rtl">
          <WhiteLabel extraClass="paymdl2-month-year-label" dirRtl={dirRtl}>
            {payment_month}
          </WhiteLabel>
          <OrangeSelect
            extraClass="paymdl2-blue-select"
            items={allMonthsArr}
            preSelectedIndex={allMonthsArr.indexOf(month)}
            onItemSelected={(month) => this.setState({ month })}
          />
          <WhiteLabel extraClass="paymdl2-month-year-label" dirRtl={dirRtl}>
            {payment_year}
          </WhiteLabel>
          <OrangeSelect
            extraClass="paymdl2-blue-select"
            items={allYearsArr}
            preSelectedIndex={allYearsArr.indexOf(year)}
            onItemSelected={(year) => this.setState({ year })}
          />
        </div>
        <div className="manual-pay-btns" dir={dirRtl ? "rtl" : "ltr"}>
          <OrangeButton
            onClick={() => {
              this.onManualDataSubmit(month, year, manualCreditCard)
              this.props.closeManualEnter &&
                this.props.closeEnterCardDataManually()
            }}
          >
            {payment_approval}
          </OrangeButton>

          <OrangeButton onClick={() => this.props.closeEnterCardDataManually()}>
            {close_text}
          </OrangeButton>
        </div>
        <Modal
          isOpen={showYesNoInfoModal}
          className="react-modal-content"
          overlayClassName="modal-overlay"
        >
          <img src={errorImg} alt="_X_" />
          <div className="retry-text">{card_not_detected}</div>
          <div className="retry-buttons">
            <OrangeButton
              onClick={() =>
                this.setState({
                  showYesNoInfoModal: false,
                })
              }
            >
              {try_again}
            </OrangeButton>
            <OrangeButton onClick={() => this.props.history.goBack()}>
              {close_text}
            </OrangeButton>
          </div>
        </Modal>

        <VirtalKeyboard
          currentValue={this.state[activeInputName]}
          onKeyboardInputChange={(txt) => this.onKeyboardTextChange(txt)}
          show={this.state.activeInput}
          closeKeyboard={() => this.setState({ activeInput: false })}
          lang="num"
          cardNumber
        />
      </div>
    )
  }
}

const mapStateToProps = ({ translate }) => {
  const {
    payment_credit_card_number,
    payment_month,
    payment_year,
    payment_approval,
    payment_swipe_a_card_click_here,
    card_not_detected,
    try_again,
    close_text,
    dirRtl,
  } = translate

  return {
    payment_credit_card_number,
    payment_month,
    payment_year,
    payment_approval,
    payment_swipe_a_card_click_here,
    close_text,
    try_again,
    card_not_detected,
    dirRtl,
  }
}

export default connect(mapStateToProps)(CardManuallyInputs)
