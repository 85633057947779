import React, { Component } from "react"
import { connect } from "react-redux"
import {
  //checkIfMobileAlreadyExists,
  logScreenEnterance,
  onStep1UserDataSubmit,
  requestRegisterVerifyCode,
} from "../../../../../actions"
import { validation } from "../../../../../utils/Validations"
import {
  DisableEnableButton,
  InputFiled,
  OrangeButton,
  Progressbar,
} from "../../../../common"
import VirtalKeyboard from "../../../../common/VirtualKeyboard"
import YesNoInfoModal from "../../../../Modals/YesNoInfoModal"
import { FixedHeader } from "../../../../reduxified"
import TermsAndPolicyCb from "../Step2Screen/TermsAndPolicyCb"
import { privacyPolicy } from "../Step2Screen/privacyPolicy"
import { termsOfUse } from "../Step2Screen/termsOfUse"
import "./style.scss"

class SignUpStep1Screen extends Component {
  constructor(props) {
    super(props)

    this.state = {
      firstName: "",
      lastName: "",
      mobile: "",
      email: "",
      isCheckBoxChecked: false,
      firstNameRef: null,
      lastNameRef: null,
      mobileRef: null,
      emailRef: null,

      activeInput: null,
      activeInputName: null,

      mobileErr: false,
      emailErr: false,
      firstNameErr: false,
      lastNameErr: false,
      isSubmitBtnDisabled: true,
      errorsTimeout: null,

      showYesNoModal: false,
      showMobileAlreadyExistsModal: false,

      runValidationsTimeOut: null,
    }
  }

  componentDidMount() {
    if (!this.props.stationId || this.props.stationId.length < 1) {
      this.props.history.push("/")
    }

    const { mobile, email, firstName, lastName } = this.props

    this.setState({ mobile, email, firstName, lastName })

    this.runValidationsWithTimoutDebounce()
    this.props.logScreenEnterance("USER_SIGN_UP_1_SCREEN")
  }

  componentWillReceiveProps(newProps) {
    const { userMobileFree, loadingIfMobileTakenInBg } = newProps

    const {
      userMobileFree: oldMobileFree,
      loadingIfMobileTakenInBg: oldLoading,
    } = this.props

    if (
      oldMobileFree === userMobileFree &&
      oldLoading === loadingIfMobileTakenInBg
    ) {
      return
    }

    if (!userMobileFree && !loadingIfMobileTakenInBg) {
      this.setState({ showMobileAlreadyExistsModal: true })
    }

    this.runValidationsWithTimoutDebounce()
  }

  onKeyboardTextChange(text) {
    const { activeInputName, errorsTimeout } = this.state

    if (errorsTimeout) {
      clearTimeout(errorsTimeout)
    }

    this.setState({
      [activeInputName]: text,
      mobileErr: false,
      emailErr: false,
      errorsTimeout: null,
    })

    if (activeInputName === "mobile" && validation.Mobile05(text)) {
      //this.props.checkIfMobileAlreadyExists(text)
    }

    this.runValidationsWithTimoutDebounce()
  }

  onRefReady(refName, ref) {
    if (!this.state[refName]) {
      this.setState({ [refName]: ref })
    }
  }

  onInputFocusChange(refName, isFocused) {
    if (isFocused) {
      this.setState({
        activeInput: this.state[refName],
        activeInputName: refName.replace("Ref", ""),
      })
    } else {
      if (this.state.activeInput === this.state[refName])
        this.setState({ activeInput: null })
    }
  }

  onNextClick() {
    const { firstName, lastName, email, mobile } = this.state
    const { stationId } = this.props

    this.props.onStep1UserDataSubmit(firstName, lastName, email, mobile, "")
    const userdata = {
      mobile,
      email,
      firstName,
      lastName,
      password: "",
    }
    this.props.requestRegisterVerifyCode(userdata, stationId)
    this.props.history.push("/SignUpStepVerifyScreen")
    //this.props.history.push("SignUpStep2Screen")
  }

  onBackClick() {
    const { firstName, lastName, email, mobile } = this.state

    if (
      (!firstName || firstName.length < 1) &&
      (!lastName || lastName.length < 1) &&
      (!email || email.length < 1) &&
      (!mobile || mobile.length < 1)
    ) {
      this.discardChangesAndGoBack()
      return
    }

    this.setState({ showYesNoModal: true })
  }

  runValidations() {
    const { mobile, email, firstName, lastName, isCheckBoxChecked } = this.state
    const { userMobileFree } = this.props

    const isMobileNumValid = validation.Mobile05(mobile)
    const isEmailValid = validation.Email(email)

    // const isEmptyMobile = mobile.length < 1
    // const isEmptyEmail = email.length < 1

    const firstNameLastNameEmpty = firstName.length < 1 || lastName.length < 1
    const isFirstNameValid = validation.Name(firstName)
    const isLastNameValid = validation.Name(lastName)

    const isSubmitBtnDisabled =
      !isCheckBoxChecked ||
      !isMobileNumValid ||
      !isCheckBoxChecked ||
      !isEmailValid ||
      firstNameLastNameEmpty ||
      !isFirstNameValid ||
      !isLastNameValid
    // || !userMobileFree
    const mobileErr = !isMobileNumValid //&& !isEmptyMobile
    const emailErr = !isEmailValid //&& !isEmptyEmail

    if (!isSubmitBtnDisabled) {
      this.setState({
        isSubmitBtnDisabled,
        mobileErr,
        emailErr,
        firstNameErr: !isFirstNameValid,
        lastNameErr: !isLastNameValid,
        runValidationsTimeOut: null,
      })
    } else {
      const errorsTimeout = setTimeout(
        () =>
          this.setState({
            isSubmitBtnDisabled,
            mobileErr,
            emailErr,
            firstNameErr: !isFirstNameValid,
            lastNameErr: !isLastNameValid,
            runValidationsTimeOut: null,
          }),
        800
      )

      this.setState({ errorsTimeout })
    }
  }

  discardChangesAndGoBack() {
    this.props.onStep1UserDataSubmit("", "", "", "", "")
    this.props.history.goBack()
  }

  gotoRecoveryPassword() {
    this.props.history.push("RecreatePasswordScreen")
  }

  onInputFieldChange(inputName, val) {
    this.setState({ [inputName]: val })

    if (inputName === "mobile" && validation.Mobile05(val)) {
      //this.props.checkIfMobileAlreadyExists(val)
    }

    this.runValidationsWithTimoutDebounce()
  }

  runValidationsWithTimoutDebounce() {
    let { runValidationsTimeOut } = this.state

    if (runValidationsTimeOut) {
      clearTimeout(runValidationsTimeOut)
    }

    runValidationsTimeOut = setTimeout(() => this.runValidations(), 500)

    this.setState({ runValidationsTimeOut })
  }

  onTermsLinkClick() {
    this.setState({
      largeModalTitle: "תנאי שימוש",
      largeModalContent: termsOfUse,
      showLegalInformationModal: true,
    })
  }

  onPrivacyPolicyClick() {
    this.setState({
      largeModalTitle: "מדיניות הפרטיות",
      largeModalContent: privacyPolicy,
      showLegalInformationModal: true,
    })
  }

  onCheckboxChange(newState) {
    let { isSubmitBtnDisabled } = this.state

    if (!newState) {
      isSubmitBtnDisabled = true
    }

    this.setState({
      isCheckBoxChecked: newState,
      isSubmitBtnDisabled,
    })

    this.runValidationsWithTimoutDebounce()
  }

  render() {
    const {
      firstName,
      lastName,
      mobile,
      email,
      activeInputName,
      mobileErr,
      emailErr,
      isSubmitBtnDisabled,
      showYesNoModal,
      showMobileAlreadyExistsModal,
      firstNameErr,
      lastNameErr,
      isCheckBoxChecked,
    } = this.state

    const {
      loadingIfMobileTakenInBg,
      dirRtl,
      welcome_title_text,
      first_name_input_hint,
      last_name_input_hint,
      mobile_input_hint,
      email_input_hint,
      next_text,
      back_btn_text,
      signup_cancel_modal_title,
      signup_cancel_modal_body,
      err_title_text,
      signup_error_modal_body,
      ok_text,
      cancel_text,
      langName,
      close_text,
      create_new_user_exist_mobile_modal_text,
      create_new_user_exist_mobile_modal_title,
      validationtext,
      termsCbTranslation,
    } = this.props

    const keyboardLang =
      activeInputName === "mobile" || activeInputName === "email"
        ? activeInputName
        : langName !== "ru"
        ? langName
        : "en"

    return (
      <div className="sus1s-wrapper">
        <FixedHeader
          title={welcome_title_text}
          buttonsFlag="signUpStep1Screen"
        />

        <div className="sus1s-body" dir={dirRtl ? "rtl" : "ltr"}>
          {/* <div className="sus1s-title"> ברוכים הבאים!</div> */}
          <Progressbar max={2} currVal={1} />

          <div className="sus1s-frame-container">
            <div className="sus1s-frame-wrapper">
              <div className="sus1s-frame-inner-wrapper">
                <InputFiled
                  value={firstName}
                  onChange={(e) =>
                    this.onInputFieldChange("firstName", e.target.value, e)
                  }
                  placeholder={first_name_input_hint}
                  onRefReady={(ref) => this.onRefReady("firstNameRef", ref)}
                  onFocusChanged={(isFocused) =>
                    this.onInputFocusChange("firstNameRef", isFocused)
                  }
                  type=""
                  dirRtl={dirRtl}
                  displayErr={firstNameErr}
                  validationtext={validationtext.name}
                  step1
                  mandatory
                />

                <InputFiled
                  value={lastName}
                  onChange={(e) =>
                    this.onInputFieldChange("lastName", e.target.value)
                  }
                  placeholder={last_name_input_hint}
                  onRefReady={(ref) => this.onRefReady("lastNameRef", ref)}
                  onFocusChanged={(isFocused) =>
                    this.onInputFocusChange("lastNameRef", isFocused)
                  }
                  dirRtl={dirRtl}
                  displayErr={lastNameErr}
                  validationtext={validationtext.name}
                  step1
                />
              </div>
              <div className="sus1s-frame-inner-wrapper">
                <InputFiled
                  value={mobile}
                  onChange={(e) =>
                    this.onInputFieldChange("mobile", e.target.value)
                  }
                  placeholder={mobile_input_hint}
                  onRefReady={(ref) => this.onRefReady("mobileRef", ref)}
                  onFocusChanged={(isFocused) =>
                    this.onInputFocusChange("mobileRef", isFocused)
                  }
                  displayErr={mobileErr}
                  isLoading={loadingIfMobileTakenInBg}
                  dirRtl={dirRtl}
                  maxLength={10}
                  validationtext={validationtext.mobile}
                  step1
                />
                <InputFiled
                  value={email}
                  onChange={(e) =>
                    this.onInputFieldChange("email", e.target.value)
                  }
                  placeholder={email_input_hint}
                  onRefReady={(ref) => this.onRefReady("emailRef", ref)}
                  onFocusChanged={(isFocused) =>
                    this.onInputFocusChange("emailRef", isFocused)
                  }
                  displayErr={emailErr}
                  validationtext={validationtext.email}
                  dirRtl={dirRtl}
                  step1
                />
              </div>
              <div className="sus2s-frame-inner-wrapper">
                <TermsAndPolicyCb
                  isCheckBoxChecked={isCheckBoxChecked}
                  onCheckboxChange={(isCheck) => this.onCheckboxChange(isCheck)}
                  onTermsClick={() => this.onTermsLinkClick()}
                  onPrivacyClick={() => this.onPrivacyPolicyClick()}
                  translation={termsCbTranslation}
                />
              </div>
              <div className="sus1s-details-btns1">
                <DisableEnableButton
                  extraClass="sus1s-details-single-btn"
                  onClick={(e) => this.onNextClick()}
                  isDisabled={isSubmitBtnDisabled}
                >
                  {next_text}
                </DisableEnableButton>
                <OrangeButton
                  extraClass="sus1s-details-single-btn"
                  onClick={(e) => this.onBackClick()}
                >
                  {back_btn_text}
                </OrangeButton>
              </div>
            </div>
          </div>
        </div>
        <VirtalKeyboard
          currentValue={this.state[activeInputName]}
          onKeyboardInputChange={(txt) => this.onKeyboardTextChange(txt)}
          show={true}
          lang={keyboardLang}
          maxLength={keyboardLang === "mobile" && 10}
          closeOnEnter
        />

        <YesNoInfoModal
          dirRtl={dirRtl}
          show={showYesNoModal}
          title={signup_cancel_modal_title}
          text={signup_cancel_modal_body}
          onModalOkClick={() => this.discardChangesAndGoBack()}
          onModalCancelClick={() => this.setState({ showYesNoModal: false })}
          okTransl={ok_text}
          cancelTransl={close_text}
        />

        <YesNoInfoModal
          dirRtl={dirRtl}
          show={showMobileAlreadyExistsModal}
          title={create_new_user_exist_mobile_modal_title}
          text={create_new_user_exist_mobile_modal_text}
          onModalOkClick={() => this.gotoRecoveryPassword()}
          onModalCancelClick={() =>
            this.setState({ showMobileAlreadyExistsModal: false })
          }
          okTransl={ok_text}
          cancelTransl={cancel_text}
        />

        <div className="sus1s-footer">{/* footer */}</div>
      </div>
    )
  }
}

const mapStateToProps = ({ registration, translate, bgService }) => {
  const { stationId } = bgService
  const {
    is1stStepSubmitted,
    mobile,
    email,
    firstName,
    lastName,
    userMobileFree,
    loadingIfMobileTakenInBg,
  } = registration

  const {
    dirRtl,
    welcome_title_text,
    first_name_input_hint,
    last_name_input_hint,
    mobile_input_hint,
    email_input_hint,
    next_text,
    back_btn_text,
    signup_cancel_modal_title,
    signup_cancel_modal_body,
    err_title_text,
    signup_error_modal_body,
    ok_text,
    cancel_text,
    close_text,
    create_new_user_exist_mobile_modal_title,
    create_new_user_exist_mobile_modal_text,
    validation_empty_text,
    validation_mobile_text,
    validation_password_text,
    validation_password_verification_text,
    validation_name_text,
    validation_email_text,
    validation_email_verification_text,
    langName,
    im_approving,
    terms_of_use,
    and_the_privacy_policy,
  } = translate

  let validationtext = {
    empty: validation_empty_text,
    mobile: validation_mobile_text,
    password: validation_password_text,
    passwordVerification: validation_password_verification_text,
    name: validation_name_text,
    email: validation_email_text,
    emailVerification: validation_email_verification_text,
  }

  const termsCbTranslation = {
    im_approving,
    terms_of_use,
    and_the_privacy_policy,
  }

  return {
    stationId,
    is1stStepSubmitted,
    mobile,
    email,
    firstName,
    lastName,
    userMobileFree,
    loadingIfMobileTakenInBg,
    langName,
    dirRtl,
    welcome_title_text,
    first_name_input_hint,
    last_name_input_hint,
    mobile_input_hint,
    email_input_hint,
    next_text,
    back_btn_text,
    signup_cancel_modal_title,
    signup_cancel_modal_body,
    err_title_text,
    signup_error_modal_body,
    ok_text,
    cancel_text,
    close_text,
    create_new_user_exist_mobile_modal_title,
    create_new_user_exist_mobile_modal_text,
    validationtext,
    termsCbTranslation,
  }
}

export default connect(mapStateToProps, {
  onStep1UserDataSubmit,
  //checkIfMobileAlreadyExists,
  logScreenEnterance,
  requestRegisterVerifyCode,
})(SignUpStep1Screen)
