import React, { Component } from "react"
import  StationListItem from "../StationListItem"
import "./style.css"

class StationList extends Component {
    
    renderItems(items){
        
        return items.map(i => {
            const isActive = this.props.activeStation ? i.stationId == this.props.activeStation.stationId : false
            return(
                <StationListItem 
                    item={i} 
                    onClick={this.props.onClick}
                    isActive={isActive}
                />
                )
        })
    }

    render() {
        const {  items } = this.props
        if(!items || items.length <=0){
            return (
                <div className="select-station-list-wrapper"> </div>
            )
        }
        return (
            <div className="select-station-list-wrapper">
                {this.renderItems(items)}
            </div>
        )
    }
}
export default StationList;