import React, { Component } from "react"
import { connect } from "react-redux"
import {
  createNewDynamicProductOrder,
  getAllDynamicProducts,
  logScreenEnterance,
  setNewCreatedOrderItems,
  setThankYouSubTitle,
} from "../../../../actions"
import { DisableEnableButton, OrangeButton } from "../../../common"
import OrderConfirmationModal from "../../../Modals/OrderConfirmationModal"
import ServiceDetailsUpdate from "../../../Modals/ServiceDetailsUpdate"
import YesNoInfoModal from "../../../Modals/YesNoInfoModal"
import { FixedFooter, FixedHeader } from "../../../reduxified"
import ItemsList from "../NewOrderScreen/ItemsList" // USING THE SAME LIST OF 'NEW ORDER'
import ItemsSquares from "./ItemsSquares"
import "./style.css"

class NewPurchaseScreen extends Component {
  constructor(props) {
    super(props)

    this.state = {
      itemToEdit: null,
      showModal: false,
      showOrderConfirmationModal: false,
      items: [],
      showYesNoModal: false,
    }
  }

  componentWillMount() {
    const { stationId, langName } = this.props

    this.props.getAllDynamicProducts(stationId, langName)
  }

  componentDidMount() {
    this.props.logScreenEnterance("USER_NEW_PURCHASE_SCREEN")
  }

  onItemClick(icon, title, item) {
    console.log("onItemClick", icon, title, item)

    const { items } = this.state

    let itemToEdit = items.filter((itm) => itm.title === title)[0] || {
      icon,
      title,
      amount: 0,
    }
    itemToEdit = { ...itemToEdit, ...item }
    this.setState({ itemToEdit, showModal: true })
  }

  onModalFinished(updatedItem) {
    const { items } = this.state

    const filteredItems = items.filter((itm) => itm.title !== updatedItem.title)

    const finalItems = [...filteredItems, updatedItem].filter(
      (itm) => itm.amount !== 0
    )

    this.setState({
      items: finalItems,
      itemToEdit: null,
      showModal: false,
    })
  }

  onDeleteItemClick(itmToDel) {
    const { items } = this.state

    const filteredItems = items.filter((itm) => itm.title !== itmToDel.title)

    this.setState({
      items: filteredItems,
      itemToEdit: null,
      showModal: false,
    })
  }

  closeOrderConfirmationModal() {
    this.setState({ showOrderConfirmationModal: false })
  }

  onOrderConfirmation() {
    const { items } = this.state

    const { stationId, userId, order_was_received } = this.props

    this.props.createNewDynamicProductOrder(userId, stationId, items)
    this.props.setThankYouSubTitle(order_was_received)
    this.props.history.push("ThankYouScreen")
  }

  onOkClick() {
    this.setState({
      showOrderConfirmationModal: true,
    })
  }

  onCancelClick() {
    this.setState({
      showYesNoModal: true,
    })
  }

  render() {
    const {
      showModal,
      itemToEdit,
      items,
      showOrderConfirmationModal,
      showYesNoModal,
    } = this.state
    const {
      dynamicProducts,
      dirRtl,
      desired_services,
      item,
      quantity,
      squaresTranslations,
      total_items,
      order_approve_btn_text,
      cancel_order_btn_text,
      add_coupon_btn_text,
      cancel_order_modal_title,
      cancel_order_modal_body,
      ok_text,
      cancel_text,
      orderConfirmTransl,
      translPackage,
    } = this.props

    return (
      <div className="ls-wrapper">
        <FixedHeader subTitle={desired_services} />

        <div className="ls-body" dir={dirRtl ? "rtl" : "ltr"}>
          <div className="nop-frame-container">
            <div className="nop-frame-inner-wrapper">
              <ItemsSquares
                onItemClick={this.onItemClick.bind(this)}
                squaresTranslations={squaresTranslations}
                dynamicProducts={dynamicProducts}
              />
              <ItemsList
                HeaderItems={["", item, quantity]}
                Items={items}
                onDeleteItemClick={this.onDeleteItemClick.bind(this)}
                onItemClick={({ icon, title }) => this.onItemClick(icon, title)}
                totalItmsTranslation={total_items}
              />
            </div>

            <div className="nop-frame-btns">
              <OrangeButton
                extraClass="nop-frame-single-btn"
                onClick={() => this.onCancelClick()}
              >
                {cancel_order_btn_text}
              </OrangeButton>
              <DisableEnableButton
                extraClass="nop-frame-single-btn"
                onClick={() => this.onOkClick()}
                isDisabled={items.length < 1}
              >
                {order_approve_btn_text}
              </DisableEnableButton>
            </div>
          </div>
        </div>

        {/* <FixedFooter history={this.props.history} /> */}

        <ServiceDetailsUpdate
          show={showModal}
          item={itemToEdit}
          onModalFinished={this.onModalFinished.bind(this)}
          translPackage={translPackage}
        />
        <OrderConfirmationModal
          dirRtl={dirRtl}
          show={showOrderConfirmationModal}
          items={items}
          onOkClick={() => this.onOrderConfirmation()}
          onCancelClick={() => this.closeOrderConfirmationModal()}
          translations={orderConfirmTransl}
        />
        <YesNoInfoModal
          dirRtl={dirRtl}
          show={showYesNoModal}
          title={cancel_order_modal_title}
          text={cancel_order_modal_body}
          okTransl={ok_text}
          cancelTransl={cancel_text}
          onModalOkClick={() => this.props.history.goBack()}
          onModalCancelClick={() => this.setState({ showYesNoModal: false })}
        />
      </div>
    )
  }
}
const mapStateToProps = ({
  userLogin,
  lockersCells,
  bgService,
  prices,
  translate,
}) => {
  const { userId } = userLogin
  const { stationId } = bgService
  const { nextAllAvailableCells } = lockersCells
  const {
    dirRtl,
    langName,
    new_order_screen_sub_title_text,
    item_description,
    item_quantity,
    total_items,
    ironing_item_btn_text: ironing,
    laundry_item_btn_text: laundry,
    ironing_and_boxing_item_btn_text: ironing_and_package,
    dry_cleaning_btn_text: dry_cleaning,
    ironing_and_packing_item_btn_text: laundry_ironing_package,
    laundry_and_ironing_item_btn_text: laundry_ironing,
    order_approve_btn_text,
    cancel_order_btn_text,
    add_coupon_btn_text,
    cancel_order_modal_title,
    cancel_order_modal_body,
    ok_text,
    cancel_text,
    back_btn_text,
    order_details_modal_title,
    order_details_modal_text,
    service_title,
    quantity_title,
    quantity_kg_title,
    comment_input_hint,
    order_was_received,
  } = translate

  const lockerToOccupy =
    nextAllAvailableCells !== null ? nextAllAvailableCells[0] : null

  const squaresTranslations = {
    ironing,
    laundry,
    ironing_and_package,
    dry_cleaning,
    laundry_ironing_package,
    laundry_ironing,
  }

  const orderConfirmTransl = {
    order_details_modal_title,
    order_details_modal_text,
    amount: item_quantity,
    item: item_description,
    ok_text,
    back_btn_text,
  }

  const translPackage = {
    dirRtl,
    service_title,
    quantity_title,
    quantity_kg_title,
    comment_input_hint,
    ok_text,
    cancel_text,
  }

  const { dynamicProducts } = prices

  return {
    userId,
    lockerToOccupy,
    stationId,
    dynamicProducts,

    dirRtl,
    langName,
    desired_services: new_order_screen_sub_title_text,
    total_items,
    item: item_description,
    quantity: item_quantity,
    squaresTranslations,
    order_approve_btn_text,
    cancel_order_btn_text,
    add_coupon_btn_text,
    cancel_order_modal_title,
    cancel_order_modal_body,
    ok_text,
    cancel_text,
    orderConfirmTransl,
    order_was_received,
    translPackage,
  }
}
export default connect(mapStateToProps, {
  setNewCreatedOrderItems,
  logScreenEnterance,
  getAllDynamicProducts,
  createNewDynamicProductOrder,
  setThankYouSubTitle,
})(NewPurchaseScreen)
