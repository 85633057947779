import React from "react"
import { WhiteCheckBox } from "../../../../../common"
import "./style.css"

const TermsAndPolicyCb = ({
  isCheckBoxChecked,
  onCheckboxChange,
  onTermsClick,
  onPrivacyClick,
  translation,
}) => {
  const { im_approving, terms_of_use, and_the_privacy_policy } = translation

  return (
    <div className="tapcb-wrapper">
      <WhiteCheckBox
        checked={isCheckBoxChecked}
        onChange={(e) => onCheckboxChange(e.target.checked)}
      />

      <p className="approving">{im_approving}</p>

      <p className="terms_of_use" onClick={() => onTermsClick()}>
        {terms_of_use}
      </p>
      <p className="private_policy" onClick={() => onPrivacyClick()}>
        {and_the_privacy_policy}
      </p>
    </div>
  )
}

export default TermsAndPolicyCb
