import {
    USER_MOBILE_ALREADY_TAKEN_REQUEST,
    USER_MOBILE_ALREADY_TAKEN_TRUE,
    USER_MOBILE_ALREADY_TAKEN_FALSE,
    ON_RECREATE_USER_PASSWORD_SUCCESS,
    // ON_RECREATE_USER_PASSWORD_REQUEST,
    // ON_RECREATE_USER_PASSWORD_SUCCESS,
    // ON_RECREATE_USER_PASSWORD_FAIL,
    ON_UPDATE_USER_PASSWORD_REQUEST,
    ON_UPDATE_USER_PASSWORD_SUCCESS,
    ON_UPDATE_USER_PASSWORD_FAIL,
    RESSET_UPDATE_USER_PASSWORD_FLOW,

} from '../actions/types';

const INIT_STATE = {
    mobile: '',
    isExists: false,
    isLoadingRequest: false,
    newTmpPwd: null,
    isUpdatedUserPassword:false,
    isErrorOnUpdateUserPassword:false,
};

const RecreatePasswordReducer = (state = INIT_STATE, action) => {
    switch (action.type) {
        case USER_MOBILE_ALREADY_TAKEN_REQUEST:
            return {
                ...state,
                isLoadingRequest: true
            };
        case USER_MOBILE_ALREADY_TAKEN_TRUE:
            return {
                ...state,
                isLoadingRequest: false,
                isExists: true
            };
        case USER_MOBILE_ALREADY_TAKEN_FALSE:
            return {
                ...INIT_STATE
            };
        // case ON_RECREATE_USER_PASSWORD_REQUEST:
        //     return {
        //         ...state,
        //     };
        case ON_RECREATE_USER_PASSWORD_SUCCESS:
            return {
                ...state,
                newTmpPwd: action.payload
            };
        // case ON_RECREATE_USER_PASSWORD_FAIL:
        //     return {
        //         ...state,
        //     };
        case ON_UPDATE_USER_PASSWORD_REQUEST:
            return {
                ...state,
                isLoadingRequest: true,
                isUpdatedUserPassword:false,
                isErrorOnUpdateUserPassword:false,
            }
        case ON_UPDATE_USER_PASSWORD_SUCCESS:
            return {
                ...state,
                isLoadingRequest: false,
                isUpdatedUserPassword:true,
                isErrorOnUpdateUserPassword:false,
            }
        case ON_UPDATE_USER_PASSWORD_FAIL:
            return {
                ...state,
                isLoadingRequest: false,
                isUpdatedUserPassword:true,
                isErrorOnUpdateUserPassword:true,
            }
        case RESSET_UPDATE_USER_PASSWORD_FLOW:
            return {
                ...state,
                isLoadingRequest: false,
                isUpdatedUserPassword:false,
                isErrorOnUpdateUserPassword:false,
            }
        default:
            return state;
    }
}


export default RecreatePasswordReducer;
