import React, { Component } from "react"
import Clock from "../../../assets/svg/timer.svg"
import "./style.scss"

class TimerClock extends Component {
  constructor(props) {
    super(props)

    this.INIT_STATE = {
      ss: 0,
      mm: 0,
      hh: 0,
    }

    this.state = this.INIT_STATE
  }

  componentDidMount() {
    const waitTimer = setInterval(() => this.getTime(), 1000)
    this.setState({ waitTimer })
  }
  componentWillUnmount() {
    if (this.state.waitTimer) {
      clearInterval(this.state.waitTimer)
    }
    this.state = this.INIT
  }

  getTime() {
    const { startTime, waitTime, onOverWaitTime } = this.props
    //const time = Date.now() - startTime
    //if (time >= waitTime) { onOverWaitTime() }

    //const waitTillDate = startTime + waitTime

    const time = startTime + waitTime - Date.now()
    //alert(waitTillDate + " - " + Date.now() + " = " + time)
    if (time <= 0) {
      onOverWaitTime()
      this.setState({ ss: 0, mm: 0, hh: 0 })
      return
    }

    //setDays(Math.floor(time / (1000 * 60 * 60 * 24)))
    const hh = Math.floor((time / (1000 * 60 * 60)) % 24)
    const mm = Math.floor((time / 1000 / 60) % 60)
    const ss = Math.floor((time / 1000) % 60)
    this.setState({ hh, mm, ss })
  }

  render() {
    const { ss, mm, hh } = this.state
    return (
      <div className="timer-wrapper">
        <div className="clock-img">
          <img src={Clock} />
        </div>
        <div className="over-time-text">
          {hh === 0 ? "" : hh + ":"}
          {mm < 10 ? "0" + mm : mm}:{ss < 10 ? "0" + ss : ss}
        </div>
      </div>
    )
  }
}

export default TimerClock
