import React from "react"

import TableHeader from "./TableHeader"
import TableBody from "./TableBody"
import "./style.css"
const PricesTableByType = ({ tableTitle, itemsArr }) => {
  return (
    <div className="price-table">
      <TableHeader>{tableTitle}</TableHeader>

      {renderItems(itemsArr)}
    </div>
  )
}

const renderItems = (itemsArr) => {
  const itmsArrSplitted = splitToSubArrsByProccessName(itemsArr)

  return itmsArrSplitted.map((subArr, index) => renderSubArr(subArr, index))
}

const splitToSubArrsByProccessName = (itemsArr) => {
  let processNamesArr = []

  itemsArr.map(({ processName }) => {
    if (!processNamesArr.includes(processName)) {
      processNamesArr = [...processNamesArr, processName]
    }

    return processName
  })

  let result = processNamesArr.map((prcsName) =>
    itemsArr.filter((itm) => itm.processName === prcsName)
  )

  return result
}

const renderSubArr = (subArr, index) => {
  if (!subArr || subArr.length < 1) {
    return
  }

  const { processName } = subArr[0]

  return (
    <div key={index}>
      <TableHeader miniHeader>{processName}</TableHeader>

      {subArr.map((itm, index) => {
        const { productName, price } = itm
        const background = index % 2 ? "grey" : "orange"
        return (
          <TableBody
            key={index}
            productName={productName}
            price={price}
            background={background}
          />
        )
      })}
    </div>
  )
}

export default PricesTableByType
