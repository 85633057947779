import React from "react"

import "./style.css"

const WhiteLabel = ({ children, activable, extraClass, dirRtl, ...props }) => {
  const whiteLabelClassName = `whlb-wrapper ${
    activable ? "whlb-on-active-effect" : ""
  } ${extraClass}`

  return (
    <div
      className={whiteLabelClassName}
      dir={dirRtl ? "rtl" : "ltr"}
      {...props}
    >
      {children}
    </div>
  )
}

export { WhiteLabel }
