import React from 'react';

import './style.css';

const Header = ({ children }) => {

    return (
        <div className='rol-ordl-header-wrapper'>
            {renderTitles(children)}
        </div>
    );
}

const renderTitles = items => {
    return items.map((itm, index) => {
        // MAKING THE FIRST COLUMN FLEX 2:
        const className = `rol-ordl-header-title-wrapper ${index === 0 ? 'rol-itls-header-flex2' : ''}`;

        return (
            <div className={className} key={itm}>
                {itm}
            </div>
        );
    });
}

export default Header;
