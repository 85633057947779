import React, { Component } from "react"
import { connect } from "react-redux"
import {
  createCreditToken,
  logScreenEnterance,
  onUserSignInClick,
  retryTokenCreation,
} from "../../../../../actions"
import {
  DisableEnableButton,
  OrangeButton,
  PendingSuccessFailBtn,
  Progressbar,
  WhiteCheckBox,
  WhiteLabel,
} from "../../../../common"
import PaymentInput from "../../../../common/PaymentInput"
import VirtalKeyboard from "../../../../common/VirtualKeyboard"
import { FixedHeader } from "../../../../reduxified"
//import  PaymentInput2 from '../../../../common/PaymentInput2';
import "./style.css"

class Step3Screen extends Component {
  constructor(props) {
    super(props)

    this.state = {
      isCheckBoxChecked: false,
    }
  }

  componentDidMount() {
    this.props.logScreenEnterance("USER_SIGN_UP_3_SCREEN")
  }

  onRefReady(refName, ref) {
    if (!this.state[refName]) {
      this.setState({ [refName]: ref })
    }
  }

  onCheckboxChange(newState) {
    this.setState({ isCheckBoxChecked: newState })
  }

  renderPaymentInput(isCheckBoxChecked) {
    if (!isCheckBoxChecked) {
      return
    }

    return (
      <div className="sus3s-swipe-your-card-wrapper">
        <WhiteLabel extraClass="sus3s-swipe-your-card-label">
          העבר את כרטיסך כעת
        </WhiteLabel>

        <PaymentInput
          refreshComponent={false}
          // extraClass={showCvvIdFields ? 'paymdl-details-fade-out' : ''}
          onPaymentDataReady={this.onPaymentDataReady.bind(this)}
        />
      </div>
    )
  }

  onPaymentDataReady(cCard, formattedDate) {
    const { userId } = this.props
    this.props.createCreditToken(userId, cCard, formattedDate)
  }

  renderSavePendingSuccErrBtn(show) {
    if (!show) {
      return
    }

    const { userTokenSavedStatus } = this.props

    return (
      <div>
        <PendingSuccessFailBtn status={userTokenSavedStatus} />

        {this.renderTryAgainBtn(userTokenSavedStatus < 0)}
      </div>
    )
  }

  renderTryAgainBtn(show) {
    if (!show) {
      return
    }
    const { try_again } = this.props
    return (
      <div>
        <OrangeButton
          extraClass="sus3s-retry-btn"
          onClick={(e) => this.props.retryTokenCreation()}
        >
          {try_again}
        </OrangeButton>
      </div>
    )
  }

  onNextClick() {
    this.props.history.push("UserMenuScreen")
  }

  onBackClick() {
    this.props.history.goBack()
  }

  render() {
    const {
      userTokenSavedStatus,
      dirRtl,
      welcome_title_text,
      next_text,
      back_btn_text,
    } = this.props

    const { isCheckBoxChecked } = this.state

    return (
      <div className="sus3s-wrapper">
        <FixedHeader title={welcome_title_text} />

        {/* <div className="sus3s-body" dir={dirRtl ? "rtl" : "ltr"}>
          <Progressbar max={3} currVal={3} /> */}

        <div className="sus3s-frame-container">
          <div className="sus3s-frame-wrapper">
            <div className="sus3s-frame-checkbox-and-text-wrapper">
              <WhiteCheckBox
                checked={isCheckBoxChecked}
                onChange={(e) => this.onCheckboxChange(e.target.checked)}
              />

              {/* <div> */}
              {/* <WhiteLabel extraClass="sus3s-frame-instruction-text">
                  סמן לשמירת פרטי אשראי (לא חובה).
                </WhiteLabel>
                <WhiteLabel extraClass="sus3s-frame-instruction-text">
                  אנחנו שומרים על פרטי האשראי שלך בהתאם לכל החוקים והתקנות
                </WhiteLabel>
                <WhiteLabel extraClass="sus3s-frame-instruction-text">
                  לאבטחה מירבית ושמירה על נתוניך.
                </WhiteLabel>
                <WhiteLabel extraClass="sus3s-frame-instruction-text">
                  בכל מקרה במעמד התשלום לכל הזמנה תמיד ניתן לשלם
                </WhiteLabel>
                <WhiteLabel extraClass="sus3s-frame-instruction-text">
                  באמצעות כרטיס אשראי שונה או להשתמש בכרטיס האשראי{" "}
                </WhiteLabel>
                <WhiteLabel extraClass="sus3s-frame-instruction-text">
                  השמור במערכת לפי נוחיותך.
                </WhiteLabel> */}
              {/* </div> */}
            </div>

            {this.renderPaymentInput(
              isCheckBoxChecked && userTokenSavedStatus === -100
            )}

            {this.renderSavePendingSuccErrBtn(
              isCheckBoxChecked && userTokenSavedStatus !== -100
            )}

            <div className="sus3s-details-btns">
              <DisableEnableButton
                extraClass="sus3s-details-single-btn"
                onClick={(e) => this.onNextClick()}
                // isDisabled={isSubmitBtnDisabled}
              >
                {next_text}
              </DisableEnableButton>
              {/* <OrangeButton
                                        extraClass='sus3s-details-single-btn'
                                        onClick={e => this.onBackClick()}
                                    >
                                        {back_btn_text}
                                    </OrangeButton> */}
            </div>
          </div>
          {/* </div> */}
        </div>
        <VirtalKeyboard
          // currentValue={this.state[activeInputName]}
          onKeyboardInputChange={(txt) => this.onKeyboardTextChange(txt)}
          show={this.state.activeInput}
          lang="en"
          closeOnEnter
        />
        {/* </div> */}
        <div className="sus3s-footer">{/* footer */}</div>
      </div>
    )
  }
}

const mapStateToProps = ({ registration, userLogin, translate }) => {
  const { mobile, password, userTokenSavedStatus } = registration
  const { userId, creditCardToken } = userLogin

  const { dirRtl, welcome_title_text, next_text, back_btn_text, try_again } =
    translate

  return {
    userId,
    mobile,
    password,
    userTokenSavedStatus,

    creditCardToken,
    dirRtl,
    welcome_title_text,
    next_text,
    back_btn_text,
    try_again,
  }
}

export default connect(mapStateToProps, {
  createCreditToken,
  onUserSignInClick,
  retryTokenCreation,
  logScreenEnterance,
})(Step3Screen)
