import React from "react"
import { OrangeButton } from "../../../../common"
//import trashOrange from "../../../../../assets/svg/icon_trash_orange.svg"
import { itemProccesesName } from "../../../../../utils"
import {
  getDateOutOfTimeStamp,
  getTimeOutOfTimeStamp,
} from "../../../../../utils/DateAndTime"

import "./style.css"

const ItemRow = ({ item, onClick, btnText, dir, names }) => {
  const { orderId, orderNumber, orderDate, orderItems } = item

  const dateTime =
    getDateOutOfTimeStamp(orderDate) + " | " + getTimeOutOfTimeStamp(orderDate)
  let info = orderItems
    .map(function (i) {
      return i.amount + " " + itemProccesesName(i.title, names)
    })
    .join(", ")

  info = info.length > 42 ? info.slice(0, 42) + "..." : info
  return (
    <div className="request-order-item" key={orderId}>
      <div className="column order-number">{orderNumber}</div>
      <div className="column">{dateTime}</div>
      <div className={"column info " + dir}>{info}</div>

      <div className="column continue">
        <OrangeButton extraClass="continue-btn" onClick={() => onClick(item)}>
          {btnText}
        </OrangeButton>
      </div>
      {
        //<div className="column trash">
        //<img src={trashOrange} />
        //</div>
      }
    </div>
  )
}

export default ItemRow
