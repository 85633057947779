import React, { Component } from "react"

import "./style.css"

class AmountNumPicker extends Component {
  constructor(props) {
    super(props)

    this.state = {
      amount: 0,
    }
  }

  componentDidMount() {
    const { amount } = this.props

    this.setState({ amount })
  }

  onPlsClick() {
    let { amount } = this.state

    amount++
    this.setState({ amount })
    this.props.onAmountUpdate(amount)
  }

  onMnsClick() {
    let { amount } = this.state

    amount--

    if (amount < 0) amount = 0

    this.setState({ amount })
    this.props.onAmountUpdate(amount)
  }

  render() {
    const { amount } = this.state

    return (
      <div className="amount-num-picker-wrapper">
        <div
          className="amo-num-pick-itm amo-num-pick-pls amo-num-pick-btn"
          onClick={() => this.onPlsClick()}
        >
          +
        </div>
        <div className="amo-num-pick-itm ">{amount}</div>
        <div
          className="amo-num-pick-itm amo-num-pick-mns amo-num-pick-btn"
          onClick={() => this.onMnsClick()}
        >
          -
        </div>
      </div>
    )
  }
}

export { AmountNumPicker }
