import React, { Component } from "react"
import { connect } from "react-redux"
import {
  logScreenEnterance,
  reportFBEvent,
  LogoutUser,
  getOrderForPay,
  setLockerToLockerDeliveryPayedOrder,
  addCouponByNameToOrder,
  resetCoupons,
} from "../../../../../actions"

import { OrangeButton, OrderDetailsList } from "../../../../common"
import Loader from "../../../../common/Loader"
import PaymentModal1 from "../../../../Modals/PaymentModal1"
import PaymentModal2 from "../../../../Modals/PaymentModal2"
import PaymentModal3 from "../../../../Modals/PaymentModal3"
import AttachCouponModal from "../../../../Modals/AttachCouponModal"
import { FixedFooter, FixedHeader, LockerCells } from "../../../../reduxified"
import "./style.css"

class PayOrder extends Component {
  constructor(props) {
    super(props)
    this.INIT_STATE = {
      showPayModal: false,
      showAttachCouponModal: false,
      coupon: {
        couponName: null,
        couponId: null,
        couponDescription: null,
        discount: null,
        discountType: null,
      },
    }
    this.state = this.INIT_STATE
  }

  componentDidMount() {
    this.props.logScreenEnterance("USER_COLLECT_ORDER_SCREEN")
    const { authenticated } = this.props
    if (!authenticated) {
      this.props.reportFBEvent(
        "PayOrder",
        "Not authenticated. Redirect to homepage."
      )
      this.props.LogoutUser()
      this.props.history.push("")
    }
    const rentalOrder = this.props.history.location.state
    let { displayedOrder, createdOrder } = this.props
    if (rentalOrder) {
      displayedOrder = rentalOrder
    }

    if (createdOrder && displayedOrder == null) {
      this.props.getOrderForPay(createdOrder.orderId)
    }
  }

  componentWillUnmount() {
    this.state = this.INIT_STATE
  }

  componentWillReceiveProps(newProps) {
    const { authenticated } = newProps

    if (!authenticated) {
      //add report
      this.props.reportFBEvent(
        "PayOrder",
        "Not authenticated. Redirect to homepage."
      )
      this.props.LogoutUser()
      this.props.history.push("")
    }
  }

  onOkClick() {
    console.log("onOkClick לתשלום ולפתיחה")
    const { orderTotalPrice, isOrderPayed, isMonthlyPayment } = this.props

    if (orderTotalPrice === 0 || isOrderPayed || isMonthlyPayment) {
      this.onPaymentSuccess()
      return
    }
    this.setState({ showPayModal: true })
  }

  onPaymentSuccess() {
    let { displayedOrder } = this.props
    this.setState({ showPayModal: false })

    displayedOrder.IsOrderPayed = true
    this.props.setLockerToLockerDeliveryPayedOrder(displayedOrder)
    this.props.history.push("/LockerToLockerDeliveryScreen/SelectLocker")
  }

  onPaymentNetworkFail() {
    this.setState({ showPayModal: false })
  }

  closePaymentModal() {
    this.setState(this.INIT_STATE)
  }

  onAttachCouponClick() {
    this.setState({
      showAttachCouponModal: true,
    })
  }
  closeAttachCouponModal() {
    this.props.resetCoupons()
    this.setState({ showAttachCouponModal: false })
  }

  onAddingCoupon(coupon) {
    if (!coupon || coupon.couponId === "") {
      this.closeAttachCouponModal()
      return
    }

    this.setState({
      ...this.state,
      coupon: {
        couponName: coupon.couponName,
        couponId: coupon.couponId,
        couponDescription: coupon.couponDescription,
        discount: coupon.discount,
        discountType: coupon.discountType,
      },
    })

    //TODO update displayedOrder
    this.props.addCouponByNameToOrder(this.props.orderId, coupon.couponName)

    this.closeAttachCouponModal()
  }

  renderDiscountInfo() {
    const {
      couponId,
      discount,
      discountType,
      you_got_coupon_discount,
      dirRtl,
    } = this.props
    if (!discount || discount == 0) {
      return <></>
    }
    if (discountType == 0) {
      return (
        <div className="discount-info">
          <div className={dirRtl ? "rtl" : "ltr"}>
            {you_got_coupon_discount}
          </div>
          <div className={dirRtl ? "ltr" : "rtl"}> %{discount}</div>
        </div>
      )
    }
    return (
      <div className="discount-info">
        <div className={dirRtl ? "rtl" : "ltr"}>{you_got_coupon_discount}</div>
        <div className={dirRtl ? "ltr" : "rtl"}>
          ₪{(discount * 1.17).toFixed(2)}
        </div>
      </div>
    )
  }

  render() {
    const {
      displayedOrder,
      please_wait,
      dirRtl,
      collectable_order_details,
      locker_to_locker_summer_title,
      locker_to_locker_summer_addrssee_name,
      locker_to_locker_summer_addrssee_mobile,
      locker_to_locker_summer_addrssee_address,
    } = this.props

    if (displayedOrder == null) {
      return (
        <div
          className={`pay-order-wrapper ${
            dirRtl ? "ls-wrapper-rtl" : "ls-wrapper-ltr"
          }`}
        >
          <FixedHeader
            subTitle={collectable_order_details}
            buttonsFlag="collectOrderScreen"
          />
          <div className="nos-frame-container" dir={dirRtl ? "rtl" : "ltr"}>
            <div className="wait-wrapper">
              <div>{please_wait}</div>
              <Loader />
            </div>
          </div>
          <FixedFooter history={this.props.history} />
        </div>
      )
    }

    const {
      orderItems,
      orderTotalPrice,
      orderNumber,
      orderId,
      isOrderPayed,
      order_number,
      total_to_pay_text,
      item_description,
      item_quantity,
      textOnContinueOpanPayBtn,
      add_coupon_btn_text,
      couponId,
      orderType,
    } = this.props
    const { showPayModal, showAttachCouponModal } = this.state

    const {
      firstName,
      lastName,
      customerMobile,
      stationTargetName,
      stationTargetAddress,
    } = displayedOrder

    console.log("Order " + orderNumber + " is payed :" + isOrderPayed)
    const { stationPaymentVersion } = this.props

    const showAddCoupon =
      (!couponId || couponId.length <= 0) &&
      !isOrderPayed &&
      orderTotalPrice !== 0

    return (
      <div
        className={`pay-order-wrapper ${
          dirRtl ? "ls-wrapper-rtl" : "ls-wrapper-ltr"
        }`}
      >
        <FixedHeader
          subTitle={collectable_order_details}
          buttonsFlag="collectOrderScreen"
        />

        <div className="nos-frame-container" dir={dirRtl ? "rtl" : "ltr"}>
          <div className="cos-inner-container">
            <div className="summary-wrapper">
              <p className="summary-title">{locker_to_locker_summer_title}</p>
              <p>
                <div>
                  <div className="summary-label">
                    {" "}
                    {locker_to_locker_summer_addrssee_name}
                  </div>
                  <div className="summary-text">
                    {firstName} {lastName}
                  </div>
                </div>
                <div>
                  <div className="summary-label">
                    {locker_to_locker_summer_addrssee_mobile}
                  </div>
                  <div className="summary-text">{customerMobile}</div>
                </div>
                <div>
                  <div className="summary-label">
                    {locker_to_locker_summer_addrssee_address}
                  </div>
                  <div className="summary-text">
                    <div>{stationTargetName}</div>
                    <div>{stationTargetAddress}</div>
                  </div>
                </div>
              </p>
            </div>

            <div className="cos-column">
              <div className="cos-table-total">
                <div>
                  <div className="order-number">
                    <div className={`${dirRtl ? "rtl" : "ltr"}`}>
                      {order_number}
                    </div>
                    <div className={`${dirRtl ? "rtl" : "ltr"}`}>
                      {orderNumber}
                    </div>
                  </div>

                  <OrderDetailsList
                    HeaderItems={[item_description, item_quantity]}
                    Items={orderItems}
                    itemListNames={this.props.itemListNames}
                  />
                </div>
              </div>
              <div className="cos-frame-btns">
                {this.renderDiscountInfo()}
                <div className="order-cost">
                  <div className={dirRtl ? "rtl" : "ltr"}>
                    {total_to_pay_text}
                  </div>
                  <div className={dirRtl ? "ltr" : "rtl"}>
                    {" "}
                    {`₪${orderTotalPrice}`}
                  </div>
                </div>

                <OrangeButton
                  show={showAddCoupon}
                  extraClass="nos-frame-single-btn white-button"
                  onClick={() => this.onAttachCouponClick()}
                >
                  {add_coupon_btn_text}
                </OrangeButton>

                <OrangeButton
                  onClick={() => this.onOkClick()}
                  extraClass="collect-submit-btn button-blink"
                >
                  {textOnContinueOpanPayBtn}
                </OrangeButton>
              </div>
            </div>
          </div>
        </div>
        <div>
          <FixedFooter history={this.props.history} hideBack={true} />
        </div>

        <PaymentModal1
          show={showPayModal && stationPaymentVersion === 1}
          total={orderTotalPrice}
          orderId={orderId}
          onUserPaymentSuccess={() => this.onPaymentSuccess()}
          onPaymentNetworkFail={() => this.onPaymentNetworkFail()}
          onCloseModalClick={() => this.closePaymentModal()}
          dirRtl={dirRtl}
        />

        <PaymentModal2
          show={showPayModal && stationPaymentVersion === 2}
          total={orderTotalPrice}
          orderId={orderId}
          onUserPaymentSuccess={() => this.onPaymentSuccess()}
          onPaymentNetworkFail={() => this.onPaymentNetworkFail()}
          onCloseModalClick={() => this.closePaymentModal()}
          dirRtl={dirRtl}
        />

        <PaymentModal3
          show={showPayModal && stationPaymentVersion === 3}
          total={orderTotalPrice}
          orderId={orderId}
          orderNumber={orderNumber}
          onUserPaymentSuccess={() => this.onPaymentSuccess()}
          onCloseModalClick={() => this.closePaymentModal()}
          dirRtl={dirRtl}
        />

        <AttachCouponModal
          dirRtl={dirRtl}
          show={showAttachCouponModal}
          onOkClick={(coupon) => this.onAddingCoupon(coupon)}
          onCancelClick={() => this.closeAttachCouponModal()}
          orderType={orderType}
        />
      </div>
    )
  }
}

const mapStateToProps = ({
  //lockers,
  lockerToLockerDelivery,
  lockersCells,
  translate,
  stationServiceTypes,
  userLogin,
}) => {
  const { authenticated } = userLogin
  let { createdOrder, orderForPay } = lockerToLockerDelivery

  const displayedOrder = orderForPay
  //const { openedLocker } = lockers
  const { errorLockersArr } = lockersCells
  const { stationPaymentVersion } = stationServiceTypes

  let orderId = null

  if (displayedOrder !== null) {
    orderId = displayedOrder.orderId
  }
  let {
    orderItems,
    orderTotalPrice,
    orderNumber,
    couponId,
    orderType,
    discount,
    discountType,
  } = displayedOrder || []

  if (!orderItems) {
    orderItems = []
  }

  const { lockerNum, isOrderPayed, isMonthlyPayment } = displayedOrder || {}

  const openingErrorOccurred = errorLockersArr.includes(lockerNum)
  const blinkers = !openingErrorOccurred ? { [lockerNum]: lockerNum } : {}
  const errLockers = openingErrorOccurred ? { [lockerNum]: lockerNum } : {}

  const {
    dirRtl,
    collectable_order_details,
    order_number,
    item_description,
    item_quantity,
    total_to_pay_text,
    pay_and_open_locker_btn_text,
    open_locker_btn_text,
    please_wait,
    close_text,
    back_btn_text,
    locker_to_locker_summer_title,
    locker_to_locker_summer_addrssee_name,
    locker_to_locker_summer_addrssee_mobile,
    locker_to_locker_summer_addrssee_address,
    add_coupon_btn_text,
    you_got_coupon_discount,
    compensation,
    rent_locker,
    shipment,
    mail,
  } = translate

  const itemListNames = []

  itemListNames["Compensation"] = compensation
  itemListNames["Locker rental"] = rent_locker
  itemListNames["Shipment"] = shipment
  itemListNames["Mail"] = mail

  const textOnContinueOpanPayBtn =
    orderTotalPrice === 0 || isOrderPayed || isMonthlyPayment
      ? open_locker_btn_text
      : pay_and_open_locker_btn_text

  return {
    authenticated,
    //openedLocker,
    orderItems,
    lockerNum,
    orderTotalPrice,
    orderNumber,
    displayedOrder,
    orderId,
    couponId,
    orderType,
    discount,
    discountType,
    createdOrder,

    blinkers,
    errLockers,
    isOrderPayed,
    isMonthlyPayment,
    back_btn_text,

    dirRtl,
    collectable_order_details,
    order_number,
    item_description,
    item_quantity,
    total_to_pay_text,

    please_wait,
    close_text,

    stationPaymentVersion,
    textOnContinueOpanPayBtn,
    locker_to_locker_summer_title,
    locker_to_locker_summer_addrssee_name,
    locker_to_locker_summer_addrssee_mobile,
    locker_to_locker_summer_addrssee_address,
    add_coupon_btn_text,
    you_got_coupon_discount,
    itemListNames,
  }
}

export default connect(mapStateToProps, {
  logScreenEnterance,
  LogoutUser,
  reportFBEvent,
  getOrderForPay,
  setLockerToLockerDeliveryPayedOrder,
  addCouponByNameToOrder,
  resetCoupons,
})(PayOrder)
