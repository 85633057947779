import activeApi from './AzureApi';
import localServiceApi from './LocalServiceApi';
import FirebaseApi from './FirebaseApi';

const api = {
    ...activeApi,
    ...localServiceApi,
    ...FirebaseApi
};

export default api;
