import React from "react"

import Header from "./Header"
import ItemRow from "./ItemRow"

import "./style.css"

const ReadyOrdersDetailsList = ({
  HeaderItems,
  Items,
  onItemClick,
  orangeListItem,
  refOnSelect,
  hideSelectReadyItemBtn,
}) => {
  return (
    <div className="ready-orders-list">
      <Header>{HeaderItems}</Header>
      <div className="rodl-wrapper">
        {renderItems(
          Items,
          onItemClick,
          orangeListItem,
          refOnSelect,
          hideSelectReadyItemBtn
        )}
      </div>
    </div>
  )
}

const renderItems = (
  items,
  onItemClick,
  orangeListItem,
  refOnSelect,
  hideSelectReadyItemBtn
) => {
  return items.map((itm, index) => {
    const background = index % 2 ? "grey" : "orange"
    return (
      <ItemRow
        item={itm}
        key={index}
        onRowItemClick={onItemClick}
        roundBottomCorners={index === items.length - 1}
        isOrange={orangeListItem === itm.orderId}
        refOnSelect={refOnSelect}
        background={background}
        hideSelectReadyItemBtn={hideSelectReadyItemBtn}
      />
    )
  })
}

export default ReadyOrdersDetailsList
