import React, { Component } from "react"

import "./style.css"

class Key extends Component {
  constructor(props) {
    super(props)

    this.state = {
      pressedDown: false,
      pressedDownInterval: null,
    }
  }

  onMouseDown() {
    let pressedDownInterval = setInterval(
      this.onLongPressInterval.bind(this),
      380
    )
    this.setState({ pressedDown: true, pressedDownInterval })
  }

  onMouseUp() {
    this.setState({ pressedDown: false })

    const { pressedDownInterval } = this.state

    if (pressedDownInterval) clearInterval(pressedDownInterval)
  }

  onLongPressInterval() {
    const { onKeyClick, keyChar, pressedDownInterval, disableLongClick } =
      this.props

    if (disableLongClick) {
      clearInterval(pressedDownInterval)
      return
    }

    const { pressedDown } = this.state

    if (pressedDown) onKeyClick(keyChar)
    else {
      console.log("unpressed!!")
      clearInterval(pressedDownInterval)
    }
  }

  renderKeySign() {
    const { keyIcon, keyChar } = this.props

    if (!keyIcon) return keyChar

    try {
      const icon = require(`../../../../../assets/images/KeyboardIcons/${keyIcon}.svg`)
      return <img src={icon} alt="" />
    } catch (e) {
      return keyChar
    }
  }

  render() {
    const { keyChar, onKeyClick, extraClass, keyIcon } = this.props
    if (keyChar === null) {
      return <noscript></noscript>
    }
    const wrapperClassName = `vkb-key-wrapper ${extraClass}`
    const wrapperStyle =
      keyChar.length > 1 &&
      `special-key ${
        keyIcon === "eng_close" || keyIcon === "eng_go_back" ? "eng" : ""
      }`
    if (keyChar === "BACKSPACE") {
      return (
        <div
          className={wrapperClassName + " " + wrapperStyle}
          onClick={() => onKeyClick(keyChar)}
          onMouseDown={() => this.onMouseDown()}
          onMouseUp={() => this.onMouseUp()}
          onMouseLeave={() => this.onMouseUp()}
          onTouchStart={() => this.onMouseDown()}
          onTouchEnd={() => this.onMouseUp()}
          onTouchCancel={() => this.onMouseUp()}
        >
          {this.renderKeySign()}
        </div>
      )
    }
    return (
      <div
        className={wrapperClassName + " " + wrapperStyle}
        onClick={() => onKeyClick(keyChar)}
      >
        {this.renderKeySign()}
      </div>
    )
  }
}
export default Key
