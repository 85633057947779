import en from './en.json';
import he from './he.json';
import ru from './ru.json';

const DEFAULT_LANG = 'he';

const langs = {
    en,
    he,
    ru
};

const i18n = (lang = DEFAULT_LANG) => {
    return langs[lang];
};

export default i18n;
