import React from 'react';
import { WhiteSquare } from '../../../../common';

import './style.css';

const dummyItems = ['banana', 'tapuah', 'gitara', 'naknik', 'pita', 'avatiah',
    'banana', 'tapuah', 'gitara', 'naknik', 'pita', 'avatiah',
    'banana', 'tapuah', 'gitara', 'naknik', 'pita', 'avatiah'];

const ItemsSquares = ({ dynamicProducts, onItemClick, squaresTranslations }) => {

    const {
        ironing, laundry, ironing_and_package, dry_cleaning,
        laundry_ironing_package, laundry_ironing
    } = squaresTranslations;

    if (!dynamicProducts) {
        return <noscript />;
    }

    return (
        <div className='itsqpur-wrapper'>
            {renderItems(dynamicProducts, onItemClick)}
        </div>
    );
}

const renderItems = (items, onItemClick) => {

    let result = [];
    let innerSquares = [];
    const icon = 'shopping-bag';

    for (let i = 0; i < items.length; i++) {
        if (i % 3 === 0 && innerSquares.length > 0) {
            result.push(
                <div className='itsqpur-inner-wrapper' key={i}>
                    {innerSquares}
                </div>
            );

            innerSquares = [];
        }

        innerSquares.push(
            <WhiteSquare
                onWhiteSquareClick={(icon, title) => onItemClick(icon, title, items[i])}
                icon={icon} title={items[i].productName}
                key={i} item={items[i]}
            />
        );
    }

    if (innerSquares.length > 0) {
        result.push(
            <div className='itsqpur-inner-wrapper' key={50000}>
                {innerSquares}
            </div>
        );
    }

    return result;
}

export default ItemsSquares;
