import React from "react"

import "./style.css"

const Header = ({ children }) => {
  return <div className="itls-header-wrapper">{renderTitles(children)}</div>
}

const renderTitles = (items) => {
  return items.map((itm, index) => {
    // MAKING THE SECOND COLUMN FLEX 2:
    const className = `itls-header-title-wrapper ${
      index === 1 ? "itls-header-flex2" : "amount-header"
    }`

    return (
      <div className={className} key={itm}>
        {itm}
      </div>
    )
  })
}

export default Header
