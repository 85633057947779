import React, { Component } from "react"
import { connect } from "react-redux"
import {
  logScreenEnterance,
  readBgServiceConfiguration,
  readStationIdFromBgService,
} from "../../../../actions"

import { FixedFooter, FixedHeader } from "../../../reduxified"
import WomanLaundryBaby from "../../../../assets/svg/woman_laundry_baby.svg"
import ManOpenLocker from "../../../../assets/svg/man_open_locker.svg"
import AppQRCodeIcon from "../../../../assets/svg/app_qr_code.svg"

import "./style.css"

class AppQRScreen extends Component {
  constructor(props) {
    super(props)

    this.INIT_STATE = {
      //showScanError: false,
      //showErrorModal: false,
      showBaseErrorModal: false,
      serviceName: "",
    }
    this.state = this.INIT_STATE
  }

  componentDidMount() {
    this.setState(this.INIT_STATE)
    this.props.readStationIdFromBgService()
    this.props.readBgServiceConfiguration()
    this.props.logScreenEnterance("APP_QR_SCREEN")

    this.setState({ serviceName: this.props.history.location.state })
  }

  componentWillUnmount() {
    this.setState(this.INIT_STATE)
  }

  componentWillReceiveProps(newProps) {
    const { isError } = newProps
    this.setState({ showBaseErrorModal: isError })
  }

  render() {
    //const { showScanError, showErrorModal, showBaseErrorModal, serviceName } =this.state

    const {
      dirRtl,
      login_laundry,

      scan_app_qr_code,
      history,
    } = this.props

    return (
      <div className={"ls-wrapper app-qrcode-screen"}>
        <FixedHeader
          title={login_laundry}
          subTitle=""
          buttonsFlag="userLoginScreen"
        />

        <div className="app-qrcode-container">
          <div className="scann-app-qrcode-text">
            <h1>{scan_app_qr_code}</h1>
          </div>

          <div className="qrcode-info">
            <img
              className="woman-laundry-baby"
              src={WomanLaundryBaby}
              alt="Woman Laundry Baby"
            />
            <div className="white-part">
              <img
                className="qr-code-icon"
                src={AppQRCodeIcon}
                alt="App QR Code Icon"
              />
            </div>

            <img
              className="man-cell-phone-icon"
              src={ManOpenLocker}
              alt="Man Open Locker"
            />
          </div>
        </div>
        <FixedFooter history={history} />
      </div>
    )
  }
}

const mapStateToProps = ({ userLogin, bgService, translate }) => {
  const { stationId } = bgService
  const {
    authenticated,

    isLoadingRequest,
    userId,
    isError,
  } = userLogin
  const {
    dirRtl,
    langName,
    close_text,
    login_laundry,

    scan_app_qr_code,
  } = translate

  return {
    authenticated,
    isLoadingRequest,
    userId,
    stationId,
    isError,
    scan_app_qr_code,
    dirRtl,
    langName,
    close_text,
    login_laundry,
  }
}

export default connect(mapStateToProps, {
  readStationIdFromBgService,
  readBgServiceConfiguration,
  logScreenEnterance,
})(AppQRScreen)
