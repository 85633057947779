import React from "react"

import "./style.css"

const ScrollBoxWithTitle = ({
  show,
  children,
  icon,
  title,
  className,
  dirRtl,
  ...props
}) => {
  if (!show) {
    return <></>
  }

  return (
    <div className={"scroll-box-wrapper " + className} {...props}>
      <div className="title" dir={dirRtl ? "rtl" : "ltr"}>
        <div className="title-icon">
          <img src={icon} />
        </div>
        <div className="title-text">{title}</div>
      </div>
      <div className={"scroll-box-content "} dir={dirRtl ? "ltr" : "rtl"}>
        {children}
      </div>
    </div>
  )
}

export { ScrollBoxWithTitle }
