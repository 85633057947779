import React from "react"

import { OrangeButton } from "../OrangeButton"
import "./style.css"

const MenuOrangeButton = ({
  children,
  hide,
  disabled,
  onClick,
  textStyle,
  ...props
}) => {
  if (hide) {
    return <noscript />
  }

  const extraClass = `user-menu-btn ${disabled && "mbb-disabled"} ${textStyle}`
  // const innerExtraClass = `mbb-blue-menu-white-border ${
  //   disabled ? "mbb-disabled" : ""
  // }`

  return (
    <OrangeButton
      extraClass={extraClass}
      onClick={(e) => (disabled ? null : onClick(e))}
      {...props}
    >
      {children}
    </OrangeButton>
  )
}

export { MenuOrangeButton }
