import axios from "axios"
import { ENABLE_RETRIES } from "./Env"

const defaultTimeout = 100000 //500000; - develop version.

const regularAxiosRequest = (method, url, body, _timeout) => {
  return new Promise(async (resolve, reject) => {
    let triesCounter = 0
    let timeStamp = new Date().getTime()
    let error

    while (triesCounter < 3) {
      triesCounter++
      const tmBeforCall = new Date().getTime()

      try {
        let resp
        if (method === "POST") {
          resp = await axios.post(url, body, { timeout: defaultTimeout })
        } else if (method === "GET") {
          resp = await axios.get(url, { timeout: defaultTimeout })
        } else if (method === "PUT") {
          resp = await axios.put(url, body, { timeout: defaultTimeout })
        }

        if (resp) {
          resolve(resp)
          const timeItTook = new Date().getTime() - tmBeforCall

          console.log(
            "RESPONSE ARRIVED - RETRIES = ",
            triesCounter,
            " TIME IN MILLIS - ",
            timeItTook
          )
          return
        }
      } catch (err) {
        error = err
        if (
          error.response &&
          error.response.status >= 400 &&
          error.response.status <= 408
        ) {
          reject(error)
          return
        }

        if (error && error.message === "Network Error") {
          await sleep(5000) // Network err => sleeping for 5 secs
        }

        const timeItTook = new Date().getTime() - tmBeforCall
        console.log("ERR!", triesCounter, err, timeItTook, "ms")
      }

      let currTT = new Date().getTime()
      if (currTT - timeStamp > _timeout) {
        break
      }
    }

    const timeItTook = new Date().getTime() - timeStamp
    reject(error)
    console.log(
      "NETWORK ERR - RETRIES = ",
      triesCounter,
      " TIME IN MILLIS - ",
      timeItTook
    )
  })
}

export const axiosPostRetry = (url, body, _timeout = defaultTimeout) => {
  if (!ENABLE_RETRIES) {
    return axios.post(url, body)
  }

  return regularAxiosRequest("POST", url, body, _timeout)
}

export const axiosGetRetry = (url, _timeout = defaultTimeout) => {
  if (!ENABLE_RETRIES) {
    return axios.get(url)
  }

  return regularAxiosRequest("GET", url, null, _timeout)
}

export const axiosPutRetry = (url, body, _timeout = defaultTimeout) => {
  if (!ENABLE_RETRIES) {
    return axios.put(url, body)
  }

  return regularAxiosRequest("PUT", url, body, _timeout)
}

const sleep = (ms) => {
  return new Promise((resolve) => setTimeout(resolve, ms))
}
