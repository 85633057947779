import React from 'react';

import Header from './Header';
import ItemRow from './ItemRow';

import './style.css';

const OrderDetailsList = ({ HeaderItems, Items, onItemClick, onDeleteItemClick }) => {

    let totalAmount;
    if (!Items || Items.length < 1)
        totalAmount = 0;
    else
        totalAmount = Items.map(itm => itm.amount).reduce((total = 0, num) => total + num);
    console.log(Items);

    return (
        <div className='rol-itls-wrapper'>
            <Header>{HeaderItems}</Header>
            {orderHeader(Items)}
            {/* {renderItems(Items, onItemClick, onDeleteItemClick)} */}
        </div>
    );
}
const orderHeader = (Items) => {
    return Items.map((ord, idx) => {
        return (
            <div key={idx}>
                <div className='rol-odl-order-wrapper'>
                    {ord.orderNumber}
                    <span className='rol-odl-order-status'>{ord.orderStatus}</span>
                </div>
                {renderItems(ord.orderItems)}
            </div>
        );
    });
}

const renderItems = (items, onItemClick, onDeleteItemClick) => {
    return items.map((itm, index) => {
        return (
            <ItemRow
                item={itm}
                key={index}
                onItemClick={onItemClick}
                onDeleteClick={onDeleteItemClick}
                roundBottomCorners={index === items.length - 1}
            />
        );
    })

}

export { OrderDetailsList };