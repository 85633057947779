import React, { useState } from "react"
import Modal from "react-modal"
import { DisableEnableButton, OrangeButton } from "../../common"
import VirtualKeyboard from "../../common/VirtualKeyboard"
import { validation } from "../../../utils/Validations"

import "./style.scss"

const ShowButtonInListModal = ({
  show,
  onModalCloseClick,
  title,
  onShowClick,
  btnText,
  dirRtl,
  closeBtnText,
  validationText,
}) => {
  const [mobile, setMobile] = useState("")
  const [mobileError, setMobileError] = useState(false)
  return (
    <Modal
      isOpen={show}
      onRequestClose={() => {
        setMobile("")
        setMobileError(false)
        onModalCloseClick()
      }}
      className="react-modal-content show-button-in-list "
      overlayClassName="modal-overlay"
    >
      <div className="dms-title" dir={dirRtl ? "rtl" : "ltr"}>
        {title}
      </div>
      <div className="mobile-row">
        <OrangeButton
          onClick={() => {
            setMobile("")
            setMobileError(false)
            onModalCloseClick()
          }}
        >
          {closeBtnText}
        </OrangeButton>
        <DisableEnableButton
          onClick={() => {
            const isMobileNumValid = validation.Mobile05(mobile)
            if (isMobileNumValid) {
              onShowClick(mobile)
              setMobile("")
              onModalCloseClick()
            } else {
              setMobileError(true)
            }
          }}
          isDisabled={!mobile || mobileError}
        >
          {btnText}
        </DisableEnableButton>
        <input
          className="collect-input-mobile"
          name="mobile"
          value={mobile}
          onChange={(e) => {
            setMobile(e.target.value)
            setMobileError(false)
          }}
          maxLength={10}
          autoFocus
        />
      </div>
      {mobileError && <div className="mobile-error-row">{validationText}</div>}
      <div className="key-board-wrapper"></div>
      <VirtualKeyboard
        currentValue={mobile}
        onKeyboardInputChange={(txt) => {
          setMobile(txt)
          setMobileError(false)
        }}
        show={true}
        lang="mobile"
      />
    </Modal>
  )
}

export default ShowButtonInListModal
