import {
  ON_STATION_TASKS_LOADING,
  ON_GET_STATION_TASKS_SUCCESS,
  ON_GET_STATION_TASKS_FAIL,
  ON_TASK_COMPLETED_LOADING,
  ON_TASK_COMPLETED_SUCCESS,
  ON_TASK_COMPLETED_FAIL,
  CLEAR_TASK_COMPLETED_STATE,
} from "../actions/types"

const INIT_STATE = {
  isLoading: false,
  getStationTasksSuccess: false,
  getStationTasksFail: false,
  stationTasksList: [],
  taskCompletedSuccess: false,
  taskCompletedFail: false,
}

const stationTasksReducer = (state = INIT_STATE, action) => {
  switch (action.type) {
    case ON_STATION_TASKS_LOADING:
      return {
        ...state,
        isLoading: true,
        stationTasksList: [],
        getStationTasksSuccess: false,
        getStationTasksFail: false,
      }
    case ON_GET_STATION_TASKS_SUCCESS:
      return {
        ...state,
        isLoading: false,
        getStationTasksSuccess: true,
        getStationTasksFail: false,
        stationTasksList: action.payload,
      }
    case ON_GET_STATION_TASKS_FAIL:
      return {
        ...state,
        isLoading: false,
        getStationTasksSuccess: false,
        getStationTasksFail: true,
        stationTasksList: [],
      }
    case ON_TASK_COMPLETED_LOADING:
      return {
        ...state,
        isLoading: true,
        taskCompletedSuccess: false,
        taskCompletedFail: false,
      }
    case ON_TASK_COMPLETED_SUCCESS:
      return {
        ...state,
        isLoading: false,
        taskCompletedSuccess: true,
        taskCompletedFail: false,
      }
    case ON_TASK_COMPLETED_FAIL:
      return {
        ...state,
        isLoading: false,
        taskCompletedSuccess: false,
        taskCompletedFail: true,
      }
    case CLEAR_TASK_COMPLETED_STATE:
      return {
        ...state,
        isLoading: false,
        taskCompletedSuccess: false,
        taskCompletedFail: false,
      }
    default:
      return state
  }
}

export default stationTasksReducer
