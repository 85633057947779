import React, { Component } from "react"
import "./style.css"

import menuCardPayment from "../../../assets/svg/new_credit_card.svg"
import menuManualPayment from "../../../assets/svg/enter_credit_card.svg"
import menuSavedCardPayment from "../../../assets/svg/saved_credit_card.svg"

class SvgMenuButton extends Component {
  renderImage(buttonName) {
    switch (buttonName) {
      case "menuCardPayment":
        return <img src={menuCardPayment} alt="card-payment" />
      case "menuManualPayment":
        return <img src={menuManualPayment} alt="manual-payment" />
      case "menuSavedCardPayment":
        return <img src={menuSavedCardPayment} alt="saved-card-payment" />
      default:
        return <></>
    }
  }

  render() {
    const { show, onClick, imageName } = this.props
    let { text } = this.props
    // text = text != null ? text : ""
    if (show == undefined || !show) {
      return <noscript></noscript>
    }

    return (
      <button className="payment-method-btn" onClick={(e) => onClick(e)}>
        {this.renderImage(imageName)}
        {text}
      </button>
    )
  }
}

export default SvgMenuButton
