import React from "react"
import "./style.scss"

import eye from "../../../assets/svg/eye.svg"
import eye_closed from "../../../assets/svg/eye_closed.svg"

const InputFiled = ({
  show = true,
  value,
  icon,
  displayErr,
  onFocusChanged,
  onRefReady,
  isLoading,
  mandatory,
  onChange,
  dirRtl,
  step1,
  showEye,
  onViewPasswordClick,
  smsCode,
  ...props
}) => {
  if (!show) {
    return <></>
  }

  let showValidation =
    displayErr && props.validationtext && props.validationtext.length > 0
  const isHidedPassword =
    showEye !== undefined && showEye && props.type === "password"

  document.addEventListener("keypress", function (e) {
    if (e.keyCode === 13 || e.which === 13) {
      e.preventDefault()
      return false
    }
  })
  return (
    <div
      className={`${step1 ? "step1" : "input-wrapper"} ${
        props.cardNumber && "cardNumber"
      } ${showValidation ? "error" : ""}`}
    >
      {renderPasswordView(showEye, isHidedPassword, onViewPasswordClick)}
      <form>
        <input
          className={props.smsCode ? "sms-code-input" : ""}
          value={value}
          onFocus={(e) => onFocusChanged(true)}
          ref={(ref) => onRefReady(ref)}
          onChange={(e) => onChange(e)}
          autoComplete="on"
          type={props.type}
          {...props}
          placeholder={props.placeholder}
        />
      </form>
      {renderValidation(showValidation, props.validationtext, dirRtl)}
    </div>
  )
}

const renderPasswordView = (showEye, isHidedPassword, onViewPasswordClick) => {
  if (showEye === undefined || !showEye) {
    return <></>
  }
  if (isHidedPassword) {
    return (
      <div className={"password-eye "} onClick={() => onViewPasswordClick()}>
        <img src={eye} alt="eye_img" />
      </div>
    )
  }

  return (
    <div className={"password-eye "} onClick={() => onViewPasswordClick()}>
      <img src={eye_closed} alt="closed-eye" />
    </div>
  )
}

const renderValidation = (show, text, dirRtl) => {
  if (!show) {
    return <></>
  }
  return (
    <div className="validation-wrapper">
      <div className="validation-text" dir={dirRtl ? "rtl" : "ltr"}>
        {text.split("\n").map((str) => (
          <p key={str}>{str}</p>
        ))}
      </div>
    </div>
  )
}

export { InputFiled }
