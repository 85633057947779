export const getOrderTypeTextFromOrderType = (orderTypeArr, orderType) => {
  if (
    !orderTypeArr ||
    orderTypeArr === undefined ||
    isNaN(orderType) ||
    orderTypeArr.length <= orderType
  ) {
    return orderType
  }
  return orderTypeArr[orderType]
}
