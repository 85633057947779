import {
  ON_LOCKER_LISTENER_START_WITH_OPEN_ATTEMPT,
  ON_LOCKER_LOCK_LISTENER_RESPONSE,
  RESET_ALL_REDUCERS,
  SET_LOCKER_OCCUPIED,
} from "../actions/types"

const INIT_STATE = {
  openedLocker: null,
  orderWasCollected: false,
  closedLocker: {},
}

const LockersReducer = (state = INIT_STATE, action) => {
  switch (action.type) {
    case ON_LOCKER_LISTENER_START_WITH_OPEN_ATTEMPT:
      return {
        ...state,
        openedLocker: parseInt(action.payload),
        orderWasCollected: false,
      }
    case ON_LOCKER_LOCK_LISTENER_RESPONSE:
      return {
        ...state,
        openedLocker:
          action.payload === state.openedLocker ? null : state.openedLocker,
        orderWasCollected: action.payload === state.openedLocker,
      }
    case SET_LOCKER_OCCUPIED:
      return {
        ...state,
        closedLocker: action.payload,
      }
    case RESET_ALL_REDUCERS:
      return {
        ...INIT_STATE,
      }
    default:
      return state
  }
}

export default LockersReducer
