import api from "../api"
import {
  SET_LOADING_SHIPING_PACKAGE,
  SET_SHIPING_PACKAGE_USER,
  SET_SHIPING_PACKAGE_ORDER,
  CREATE_SHIPING_PACKAGE_ORDER_SUCCESS,
  CREATE_SHIPING_PACKAGE_ORDER_FAIL,
  VALIDATE_SHIPING_PACKAGE_NUMBER_SUCCESS,
  VALIDATE_SHIPING_PACKAGE_NUMBER_FAIL,
  CLEAR_SHIPING_PACKAGE,
  CLEAR_VALIDATION_FIELDS,
} from "./types"
import { axiosPostRetry, axiosGetRetry } from "../utils"
import { reportFBEvent } from "./FBEventsActions"

export const setShipingPackageLoading = () => {
  return (dispatch) => {
    dispatch({
      type: SET_LOADING_SHIPING_PACKAGE,
    })
  }
}

export const clearShipingPackage = () => {
  return (dispatch) => {
    dispatch({
      type: CLEAR_SHIPING_PACKAGE,
    })
  }
}

export const clearValidationFields = () => {
  return (dispatch) => {
    dispatch({
      type: CLEAR_VALIDATION_FIELDS,
    })
  }
}

export const setShipingPackageUser = (data) => {
  return (dispatch) => {
    dispatch({
      type: SET_SHIPING_PACKAGE_USER,
      payload: data,
    })
  }
}

export const setShipingPackageOrder = (data) => {
  return (dispatch) => {
    dispatch({
      type: SET_SHIPING_PACKAGE_ORDER,
      payload: data,
    })
  }
}

export const validateShipingPackageNumber = (
  packageNumber,
  orderType,
  stationNumber
) => {
  return (dispatch) => {
    axiosGetRetry(
      api.validateShipingPackageNumber(packageNumber, orderType, stationNumber),
      7000
    )
      .then((res) => {
        dispatch({
          type: VALIDATE_SHIPING_PACKAGE_NUMBER_SUCCESS,
          payload: {
            isValid: res.data.IsValid,
            isExist: res.data.IsExist,
            isStationAllowed: res.data.IsStationAllowed,
            isError: false,
          },
        })

        dispatch(
          reportFBEvent(
            "Validate Shiping PackageNumber for orderType:" + orderType,
            "PackageNumber is valid"
          )
        )
      })
      .catch((err) => {
        if (err && err.response && err.response.data) {
          dispatch({
            type: VALIDATE_SHIPING_PACKAGE_NUMBER_FAIL,
            payload: {
              isValid: err.response.data.IsValid,
              isExist: err.response.data.IsExist,
              isStationAllowed: err.response.data.IsStationAllowed,
              isError: false,
            },
          })
        } else {
          dispatch({
            type: VALIDATE_SHIPING_PACKAGE_NUMBER_FAIL,
            payload: {
              isValid: false,
              isExist: false,
              isStationAllowed: true,
              isError: true,
            },
          })
        }

        dispatch(
          reportFBEvent(
            "Validate Shiping PackageNumber for orderType:" + orderType,
            "PackageNumber is NOT valid"
          )
        )
      })
  }
}

export const createShipingPackageOrder = (data) => {
  return (dispatch) => {
    axiosPostRetry(api.createShipingPackageOrder, data)
      .then((res) => {
        dispatch({
          type: CREATE_SHIPING_PACKAGE_ORDER_SUCCESS,
          payload: { ...res.data, lockerNum: data.lockerNum },
        })
        dispatch(
          reportFBEvent(
            "New_Order",
            "New Order shipingPackage Has Been Created! "
          )
        )
      })
      .catch((err) => {
        dispatch({
          type: CREATE_SHIPING_PACKAGE_ORDER_FAIL,
          payload: err,
        })
        console.log(err)
      })
  }
}
