import React, { Component } from "react"
import { connect } from "react-redux"
import {
  confirmCreateOrder,
  createNewOrder,
  findNextAvailableCell,
  LogoutUser,
  logScreenEnterance,
  openAndListenToSpecificLockerLock,
  reportSuspiciousBrokenLockers,
  setThankYouSubTitle,
  clearCreateNewOrder,
} from "../../../../actions"
import { getOrderItems } from "../../../../utils/OrderConvert"
import ErrorInfoModal from "../../../Modals/ErrorInfoModal"
import LoadingInfoModal from "../../../Modals/LoadingInfoModal"
import WaitingForLockerLock from "../../../Modals/WaitingForLockerLock"
import { FixedHeader, LockerCells } from "../../../reduxified"
import "./style.css"

class OrderItemsDepositScreen extends Component {
  constructor(props) {
    super(props)

    this.state = {
      showWaitingForLockModal: false,
      showDoorOpenErrorModal: false,
      showFailCreateNewOrder: false,
      suspiciousLockersArr: [],
    }
  }

  componentDidMount() {
    const { stationId, lockerNum } = this.props
    this.openAndListenToLocker(lockerNum, undefined, stationId)

    this.props.logScreenEnterance("USER_ORDER_ITEM_DEPOSIT_SCREEN")
  }

  componentWillUnmount() {
    this.props.clearCreateNewOrder()
  }

  async componentWillReceiveProps(newProps) {
    const {
      openingErrorOccurred,
      openAttemptsNum,
      newOrderItems,
      nextLockerId,
      lockerNum,
      openingDoorSuccess,
      nextAvailableCellIndex,
      doorWasClosed,
      userId,
      stationId,
      couponId,
      orderForConfirmCreate,
      order_was_received,
      orderRequestSent,
      orderCreatedSuccessfully,
      lockersWithOpenStatusFromHw,
      isLoading,
    } = newProps

    const rentLocker = this.props.history.location.state === "rentLocker"
    const laundryOrder = this.props.history.location.state === "laundry"
    if (!lockerNum) {
      this.setState({ showDoorOpenErrorModal: true })
      await this.reportAboutSuspiciousBrokenLockers()
      return
    }

    if (laundryOrder) console.log("laundryOrder")
    if (rentLocker) console.log("rentLocker")
    if (doorWasClosed) console.log("doorWasClosed")
    if (openingDoorSuccess) console.log("openingDoorSuccess")
    if (orderRequestSent) console.log("orderRequestSent")

    if (openingDoorSuccess && rentLocker && !orderRequestSent) {
      await this.props.createNewOrder(
        this.props.lockersData[lockerNum - 1].lockerId,
        userId,
        stationId,
        [
          {
            amount: 1,
            icon: "Locker rental",
          },
        ],
        "",
        4,
        undefined, //orderId
        lockersWithOpenStatusFromHw.toString()
      )
      await this.reportAboutSuspiciousBrokenLockers()
      return
    }

    if (doorWasClosed && rentLocker) {
      if (!orderRequestSent) {
        await this.props.createNewOrder(
          this.props.lockersData[lockerNum - 1].lockerId,
          userId,
          stationId,
          [
            {
              amount: 1,
              icon: "Locker rental",
            },
          ],
          "",
          4,
          undefined, //orderId
          lockersWithOpenStatusFromHw.toString()
        )
        return
      } else if (orderRequestSent && !isLoading && !orderCreatedSuccessfully) {
        console.log("Error Create new order")
        this.setState({ showFailCreateNewOrder: true })
      } else if (orderRequestSent && !isLoading && orderCreatedSuccessfully) {
        this.props.setThankYouSubTitle(order_was_received)
        this.props.history.push("ThankYouScreen")
        return
      }
    }

    if (openingDoorSuccess && orderForConfirmCreate) {
      orderForConfirmCreate.locationId = nextLockerId
      orderForConfirmCreate.couponId = couponId
      orderForConfirmCreate.orderItems = getOrderItems(newOrderItems)
      await this.props.confirmCreateOrder({
        ...orderForConfirmCreate,
        optionalWrongDoor: lockersWithOpenStatusFromHw.toString(),
      })
      await this.reportAboutSuspiciousBrokenLockers()
      return
    }

    if (doorWasClosed && !rentLocker && !laundryOrder) {
      this.props.setThankYouSubTitle(order_was_received)
      this.props.history.push("ThankYouScreen")
      return
    }

    if (openingDoorSuccess && laundryOrder && !orderRequestSent) {
      await this.props.createNewOrder(
        nextLockerId,
        userId,
        stationId,
        newOrderItems,
        couponId,
        undefined, //orderType
        undefined, //orderId
        lockersWithOpenStatusFromHw.toString()
      )
      await this.reportAboutSuspiciousBrokenLockers()
      return
    }

    if (doorWasClosed && laundryOrder) {
      if (!orderRequestSent) {
        await this.props.createNewOrder(
          nextLockerId,
          userId,
          stationId,
          newOrderItems,
          couponId,
          undefined, //orderType
          undefined, //orderId
          lockersWithOpenStatusFromHw.toString()
        )
        return
      } else if (orderRequestSent && !isLoading && !orderCreatedSuccessfully) {
        console.log("Error Create new order")
        this.setState({ showFailCreateNewOrder: true })
      } else if (orderRequestSent && !isLoading && orderCreatedSuccessfully) {
        console.log("ThankYouScreen")
        this.props.setThankYouSubTitle(order_was_received)
        this.props.history.push("ThankYouScreen")
        await this.reportAboutSuspiciousBrokenLockers()
        return
      }
    }

    if (
      openingErrorOccurred ||
      nextAvailableCellIndex !== this.props.nextAvailableCellIndex
    ) {
      if (openAttemptsNum < 4) {
        this.openAndListenToLocker(lockerNum, 5, stationId)
      } else {
        await this.props.findNextAvailableCell()
        this.addSuspiciousBrokenLocker(lockerNum)
      }
    }
  }

  openAndListenToLocker(lockerNum, attempts, stationId) {
    const { stationPaymentVersion } = this.props
    this.props.openAndListenToSpecificLockerLock(
      lockerNum,
      attempts,
      stationId,
      undefined, //packageNumber
      2, //it's lockerStatus (2 means 'full', 3 means 'empty')
      stationPaymentVersion
    )
  }

  openWaitingForLockModal() {
    this.setState({ showWaitingForLockModal: true })
  }

  closeWaitingForLockModal() {
    this.setState({ showWaitingForLockModal: false })
  }

  onDoorOpenErrModalClose() {
    const { see_you_again } = this.props

    this.props.setThankYouSubTitle(see_you_again)
    this.props.history.push("ThankYouScreen")
  }

  closeFailCreateNewOrderModal() {
    this.setState({ showFailCreateNewOrder: false })

    const { see_you_again } = this.props
    this.props.setThankYouSubTitle(see_you_again)
    this.props.history.push("ThankYouScreen")
  }

  async reportAboutSuspiciousBrokenLockers() {
    const { stationId } = this.props
    const { suspiciousLockersArr } = this.state

    if (suspiciousLockersArr.length > 0) {
      await this.props.reportSuspiciousBrokenLockers(
        stationId,
        suspiciousLockersArr
      )
    }
  }

  addSuspiciousBrokenLocker(lockerNum) {
    const { suspiciousLockersArr } = this.state

    this.setState({
      suspiciousLockersArr: [...suspiciousLockersArr, lockerNum],
    })
  }

  render() {
    const {
      openAttemptsNum,
      openingDoorSuccess,
      dirRtl,
      opening_free_locker_title,
      please_wait,
      close_text,
      deposit_order_insturct_1,
      deposit_order_insturct_2,
      deposit_order_insturct_3,
      error_while_opening_door_modal_title,
      error_while_opening_door_modal_body,
      waiting_for_locker_lock_modal_title,
      waiting_for_locker_lock_modal_body,
      order_creation_failed,
      contact_customer_service,
    } = this.props
    let { lockerNum } = this.props
    const {
      showWaitingForLockModal,
      showDoorOpenErrorModal,
      showFailCreateNewOrder,
    } = this.state

    return (
      <div className="ls-wrapper">
        <FixedHeader buttonsFlag="orderDepositScreen" />

        <div
          className="oids-inner-wrapper"
          onClick={this.openWaitingForLockModal.bind(this)}
        >
          <LockerCells hideInfo blinkers={{ [lockerNum]: lockerNum }} />

          <div className="oids-text-instructions-wrapper">
            {deposit_order_insturct_1}
            <br />
            {deposit_order_insturct_2.replace("{lockerNum}", lockerNum)}
            <br />
            {deposit_order_insturct_3}
          </div>
        </div>

        <LoadingInfoModal
          dirRtl={dirRtl}
          show={
            openAttemptsNum > 0 &&
            !showDoorOpenErrorModal &&
            !openingDoorSuccess
          }
          title={opening_free_locker_title}
          text={please_wait}
        />

        <ErrorInfoModal
          dirRtl={dirRtl}
          show={showDoorOpenErrorModal}
          title={error_while_opening_door_modal_title}
          text={error_while_opening_door_modal_body}
          onModalCloseClick={() => this.onDoorOpenErrModalClose()}
          closeTrnsl={close_text}
        />

        <WaitingForLockerLock
          dirRtl={dirRtl}
          title={waiting_for_locker_lock_modal_title}
          subTitle={waiting_for_locker_lock_modal_body}
          show={showWaitingForLockModal}
          onCloseClicked={() => this.closeWaitingForLockModal()}
        />

        <ErrorInfoModal
          dirRtl={dirRtl}
          show={showFailCreateNewOrder}
          title={order_creation_failed}
          text={contact_customer_service}
          onModalCloseClick={() => this.closeFailCreateNewOrderModal()}
          closeTrnsl={close_text}
        />
      </div>
    )
  }
}

const mapStateToProps = ({
  lockersCells,
  readyOrders,
  userLogin,
  bgService,
  translate,
  stationServiceTypes,
}) => {
  const {
    lockersData,
    nextAllAvailableCells,
    openedLockersArr,
    errorLockersArr,
    openingAttempsMap,
    nextAvailableCellNumber,
    nextAvailableCellIndex,
    recentClosedLockersArr,
    chosenCell,
    lockersWithOpenStatusFromHw,
    brokenLockersNumArr,
  } = lockersCells
  const { stationPaymentVersion } = stationServiceTypes

  const { userId } = userLogin
  const { stationId } = bgService

  const {
    newCreatedOrderItems: newOrderItems,
    newCreatedOrderCoupon: couponId,
    orderForConfirmCreate,
    orderRequestSent,
    orderCreatedSuccessfully,
    isLoading,
  } = readyOrders

  const lockerNum = chosenCell || nextAvailableCellNumber

  const openingDoorSuccess = openedLockersArr.includes(lockerNum)
  const openingErrorOccurred = errorLockersArr.includes(lockerNum)
  const doorWasClosed = recentClosedLockersArr.includes(lockerNum)

  const openAttemptsNum = openingAttempsMap[lockerNum] || 0

  const nextLocker = nextAllAvailableCells
    ? nextAllAvailableCells[nextAvailableCellIndex] || undefined
    : undefined
  const nextLockerId = nextLocker ? nextLocker.lockerId : undefined

  const {
    dirRtl,
    opening_free_locker_title,
    please_wait,
    order_was_received,
    see_you_again,
    error_while_opening_door_modal_title,
    error_while_opening_door_modal_body,
    deposit_order_insturct_1,
    deposit_order_insturct_2,
    deposit_order_insturct_3,
    waiting_for_locker_lock_modal_title,
    waiting_for_locker_lock_modal_body,
    close_text,
    order_creation_failed,
    contact_customer_service,
  } = translate

  return {
    stationPaymentVersion,
    lockersData,
    userId,
    stationId,
    couponId,
    nextLockerId,
    openingDoorSuccess,
    openingErrorOccurred,
    doorWasClosed,
    newOrderItems,
    openAttemptsNum,
    lockerNum,
    nextAvailableCellIndex,
    lockersWithOpenStatusFromHw,
    brokenLockersNumArr,
    dirRtl,
    opening_free_locker_title,
    please_wait,
    order_was_received,
    close_text,
    see_you_again,
    error_while_opening_door_modal_title,
    error_while_opening_door_modal_body,
    deposit_order_insturct_1,
    deposit_order_insturct_2,
    deposit_order_insturct_3,
    waiting_for_locker_lock_modal_title,
    waiting_for_locker_lock_modal_body,
    order_creation_failed,
    contact_customer_service,
    orderForConfirmCreate,
    orderRequestSent,
    orderCreatedSuccessfully,
    isLoading,
  }
}

export default connect(mapStateToProps, {
  openAndListenToSpecificLockerLock,
  findNextAvailableCell,
  createNewOrder,
  LogoutUser,
  setThankYouSubTitle,
  reportSuspiciousBrokenLockers,
  logScreenEnterance,
  confirmCreateOrder,
  clearCreateNewOrder,
})(OrderItemsDepositScreen)
