import axios from "axios"
import api from "../api"

import { ENABLE_BG_STORE_FAILED_REQS } from "./Env"

export const initAxiosLogger = () => {
  axios.interceptors.request.use(
    (config) => {
      handleRequest(config)

      return config
    },
    (error) => {
      // Do something with request error
      return Promise.reject(error)
    }
  )

  axios.interceptors.response.use(
    (response) => {
      handleResponse(response)
      return response
    },
    (error) => {
      handleError(error)
      return Promise.reject(error)
    }
  )
}

const handleRequest = (request) => {
  const { url, method, data } = request

  if (url === api.logToStationLogger || url === api.storeFailedRequest) {
    return
  }

  if (url === api.payWithPelecard) {
    axios.post(api.logToStationLogger, {
      csvLine: `request, ${url}, ${method}, PELECARD SECURED DATA..,`,
    })
    return
  }
  if (url === api.getPelecardToken) {
    axios.post(api.logToStationLogger, {
      csvLine: `request, ${url}, ${method}, PELECARD SECURED DATA..,`,
    })
    return
  }
  // if (url === api.signIn) {
  //   axios.post(api.logToStationLogger, {
  //     csvLine: `request, ${url}, ${method}, ${JSON.stringify({
  //       ...data,
  //       password: "*********",
  //     })}`,
  //   })
  //   return
  // }

  if (
    url.includes("update_password") ||
    url.includes("register") ||
    url.includes("authenticate")
  ) {
    axios.post(api.logToStationLogger, {
      csvLine: `request, ${url}, ${method}, ${JSON.stringify({
        ...data,
        password: "*********",
      })}`,
    })
    return
  }

  const csvLine = `request, ${url}, ${method}, ${
    data ? JSON.stringify(data) : data
  },`

  const reqData = { csvLine }
  axios.post(api.logToStationLogger, reqData)
}

const handleResponse = (response) => {
  const { config, data } = response
  const { url, method } = config
  let cardData
  let csvLine
  const logsData = data.token
    ? JSON.stringify({ ...data, token: true })
    : JSON.stringify(data)

  if (url === api.logToStationLogger || url === api.storeFailedRequest) {
    return
  }

  if (url.includes("order=read")) {
    cardData = `**********${JSON.stringify(data).slice(
      data.length - 8,
      data.length - 4
    )}`
  }

  csvLine = cardData
    ? `response, ${url}, ${method}, ${cardData}`
    : `response, ${url}, ${method}, ${logsData}`

  const respData = { csvLine }
  axios.post(api.logToStationLogger, respData)
}

const handleError = (error) => {
  const { config, message } = error
  const { url, headers, method, data } = config

  if (url === api.logToStationLogger || url === api.storeFailedRequest) {
    return
  }

  const csvLine = `error, ${url}, ${method}, ${message},`

  const reqData = { csvLine }
  axios.post(api.logToStationLogger, reqData) // LOGGING THE ERROR TO THE LOG

  if (ENABLE_BG_STORE_FAILED_REQS) {
    if (url.includes("null")) {
      // AVOID SAVING URLS WITH ERRORS
      return
    }

    axios.post(api.storeFailedRequest, {
      url,
      headers,
      method,
      data: data ? JSON.parse(data) : data,
    }) // SAVING THE FAILED REQ TO THE BG SERVICE!
  }
}
