import {
    SET_LOADING_RESTORE_DELIVERY_CODE,
    FETCH_RESTORE_DELIVERY_CODE_SUCCESS,
    FETCH_RESTORE_DELIVERY_CODE_FAIL,
    RESET_ALL_REDUCERS,
  
} from '../actions/types';

const INIT_STATE = {
    mobile: '',
    isLoadingRequest: false,
    data: null,
    isExists: false,
    deliveryCodeWasResended: null,
    showResetDeliveryCodeModal: false,
    showMobileNotExistsModal:false,
    showErrorModal:false
};

const RestoreDeliveryCodeReducer = (state = INIT_STATE, action) => {
    switch (action.type) {
    
        case SET_LOADING_RESTORE_DELIVERY_CODE:
                return handleLoadingRestoreDeliveryCode(state);
        case FETCH_RESTORE_DELIVERY_CODE_SUCCESS:
                return handleRestoreDeliveryCodeResponse(state, action.payload);
        case FETCH_RESTORE_DELIVERY_CODE_FAIL:
                return handleRestoreDeliveryCodeErrorResponse(state, action.payload);
        case RESET_ALL_REDUCERS:
            return {
                ...INIT_STATE
            };
        default:
            return state;
    }
}

const handleLoadingRestoreDeliveryCode = (state) => {
    return { 
        ...state,
        isLoadingRequest: true,
        showResetDeliveryCodeModal: false,
        showMobileNotExistsModal:false,
        showErrorModal:false,
        data: null
    }
}

const handleRestoreDeliveryCodeResponse = (state, payload) => {
    
    return { 
        ...state,
        isLoadingRequest: false,
        showMobileNotExistsModal:false,
        showResetDeliveryCodeModal:true,
        showErrorModal:false,
        data: payload
    }
}

const handleRestoreDeliveryCodeErrorResponse = (state, payload) => {
    
    let mobileNotExists, error =  false;
    if(payload && payload.response && payload.response.status == 404){
        mobileNotExists = true;
    }else{
        error=true;
    }
    
    return { 
        ...state,
        isLoadingRequest: false,
        showMobileNotExistsModal:mobileNotExists,
        showResetDeliveryCodeModal:false,
        showErrorModal:error,
        data: payload
    }
}

export default RestoreDeliveryCodeReducer;
