import React, { useEffect, useState } from "react"
import Modal from "react-modal"
import { connect } from "react-redux"
import Card_in from "../../../assets/images/Payment/card-in.svg"
import Card_on from "../../../assets/images/Payment/card-on.svg"
import store from "../../../store"
import { OrangeButton } from "../../common"
import { CardManuallyInputs } from "../../reduxified"
import "./style.css"

const CardCheckNAYAXModal = ({
  show,
  checkCreditCard,
  onCloseModal,
  checkNAYAXCreditCard,
  cancelPaymentNayax,
}) => {
  const [showManual, setShowManual] = useState(false)

  useEffect(() => {
    checkNAYAXCreditCard()
  }, [])

  const renderManualInputs = (show) => {
    if (!show) return
    return (
      <div className="card-check-manual-wrapper">
        <CardManuallyInputs
          show={showManual}
          justCheck={true}
          startPayingProcess={checkCreditCard}
          closeEnterCardDataManually={() => onCloseModal()}
        />
      </div>
    )
  }
  const {
    attach_to_the_reader,
    insert_into_bottom,
    manual_input,
    card_check_sub_title,
  } = store.getState().translate

  return (
    <>
      <Modal
        isOpen={show}
        className="react-modal-content medium-react-modal"
        overlayClassName="modal-overlay"
        onRequestClose={() => onCloseModal()}
      >
        <button className="check-card-close-btn" onClick={() => onCloseModal()}>
          X
        </button>
        {showManual && renderManualInputs(show)}
        {!showManual && (
          <>
            <div className="card-check-NAYAX-wrapper">
              <div className="card-reader-3-wrapper">
                <div className="reader-with-title">
                  <p>{attach_to_the_reader}</p>
                  <img
                    className="card-reader-3-2"
                    src={Card_on}
                    alt="Card3_2"
                  />
                </div>
                <div className="reader-with-title">
                  <p>{insert_into_bottom}</p>
                  <img
                    className="card-reader-3-1"
                    src={Card_in}
                    alt="Card3_1"
                  />
                </div>
              </div>
            </div>
            <OrangeButton
              extraClass="manual-input-btn-nayax"
              onClick={() => {
                cancelPaymentNayax()
                setShowManual(true)
              }}
            >
              הזנה ידנית{" "}
            </OrangeButton>
            <div className="check-card-NAYAX-text" dir="rtl">
              {card_check_sub_title}
            </div>
          </>
        )}
      </Modal>
    </>
  )
}

export default CardCheckNAYAXModal
