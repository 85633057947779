import React from "react"

import "./style.scss"
import { OrangeButton } from "../OrangeButton"

const DisableEnableButton = ({
  children,
  isDisabled,
  onClick,
  extraClass,
  ...props
}) => {
  const finalExtraClass = `${isDisabled ? "dis-enb-btn" : ""} ${extraClass}`

  return (
    <OrangeButton
      extraClass={finalExtraClass}
      onClick={(e) => onBtnClick(isDisabled, onClick)}
      {...props}
    >
      {children}
    </OrangeButton>
  )
}

const onBtnClick = (isDisabled, onClick) => {
  if (isDisabled) return

  onClick()
}

export { DisableEnableButton }
