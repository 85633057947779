import { ON_TRANSLATION_CHANGE } from '../actions/types';
import i18n from '../i18n';

const INIT_STATE = {
    ...i18n(),
    langName: 'he'
};

const TranslateReducer = (state = INIT_STATE, action) => {
    switch (action.type) {
        case ON_TRANSLATION_CHANGE:
            return {
                ...state,
                ...i18n(action.payload),
                langName: action.payload
            };

        default:
            return state;
    }
}

export default TranslateReducer;
