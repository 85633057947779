import React, { Component } from "react"
import { connect } from "react-redux"
import {
  fetchStationServiceTypes,
  logScreenEnterance,
  readStationIdFromBgService,
  readBGServiceAlive,
  LogoutUser,
  goAliveAPI,
} from "../../../actions"
import Loader from "../../common/Loader"
import "./style.scss"

class MaintenanceScreen extends Component {
  constructor(props) {
    super(props)

    this.state = {
      reqInterval: 0,
      reqInterval2: 0,
    }
  }

  componentDidMount() {
    //console.log("network: MaintenanceScreen: start reqInterval: 10000")
    this.checkNetworkConnection()
    const reqInterval = setInterval(() => {
      this.checkNetworkConnection()
      //console.log("network: MaintenanceScreen: call reqInterval: 10000")
    }, 10000)
    this.setState({ reqInterval: reqInterval })

    this.props.logScreenEnterance("MAINTENANCE_SCREEN")
  }

  componentWillUnmount() {
    const { reqInterval } = this.state
    if (reqInterval) {
      clearInterval(reqInterval)
      //console.log("network:MaintenanceScreen: clear reqInterval")
      this.setState({ reqInterval: 0 })
    }
  }

  checkNetworkConnection() {
    const {
      stationId,
      isDeliveryActive,
      isLaundryActive,
      isLockerRentActive,
      isLockerToLockerDeliveryActive,
      serviceInternetError,
      reactInternetError,
    } = this.props

    if (serviceInternetError) {
      //console.log("network:  serviceInternetError: true")
      this.props.readBGServiceAlive()
      return
    }

    if (reactInternetError) {
      //console.log("network:  reactInternetError: true")
      this.props.goAliveAPI()
      return
    }
    console.log(`network: 1 stationId: ${stationId}`)
    if (!stationId || stationId === -1) {
      //console.log(`network: 2 stationId: ${stationId}`)
      this.props.readStationIdFromBgService()
      return
    }

    if (
      !this.isStationHaveAnyType(
        isDeliveryActive,
        isLaundryActive,
        isLockerRentActive,
        isLockerToLockerDeliveryActive
      )
    ) {
      //console.log("network: Station have no types.")

      this.props.fetchStationServiceTypes(stationId)
      return
    }
    //console.log("network: ALL OK!!!")
    this.props.LogoutUser()
    this.props.history.push("")
  }

  isStationHaveAnyType(
    isDeliveryActive,
    isLaundryActive,
    isLockerRentActive,
    isLockerToLockerDeliveryActive
  ) {
    const isAnyType =
      isDeliveryActive ||
      isLaundryActive ||
      isLockerRentActive ||
      isLockerToLockerDeliveryActive

    //console.log("network: Station have types: " + isAnyType)

    return isAnyType
  }

  render() {
    const {
      network_please_wait,
      network_will_return,
      network_try_use_app,
      serviceInternetError,
      isBLE,
    } = this.props
    return (
      <div className="maint-wrapper">
        <div className="maint-msg">
          {network_please_wait}
          <br />
          {network_will_return}
          <br />
          {!serviceInternetError && isBLE && (
            <div className="maint-app-way">{network_try_use_app}</div>
          )}
          <div className="loader-wrapper">
            <Loader />
          </div>
        </div>
      </div>
    )
  }
}

const mapStateToProps = ({
  bgService,
  stationServiceTypes,
  translate,
  errorMessages,
}) => {
  const { stationId } = bgService
  const { serviceInternetError, reactInternetError } = errorMessages
  const { network_please_wait, network_will_return, network_try_use_app } =
    translate
  const {
    isDeliveryActive,
    isLaundryActive,
    isLockerRentActive,
    isLockerToLockerDeliveryActive,
    isBLE,
  } = stationServiceTypes
  return {
    stationId,
    isDeliveryActive,
    isLaundryActive,
    isLockerRentActive,
    isLockerToLockerDeliveryActive,
    network_please_wait,
    network_will_return,
    network_try_use_app,
    serviceInternetError,
    reactInternetError,
    isBLE,
  }
}

export default connect(mapStateToProps, {
  readStationIdFromBgService,
  logScreenEnterance,
  fetchStationServiceTypes,
  LogoutUser,
  readBGServiceAlive,
  goAliveAPI,
})(MaintenanceScreen)
