import React from "react"
import slot from "../../../assets/images/Payment/slot.png"
import arrowOut from "../../../assets/svg/arrow_out.svg"
import card from "../../../assets/images/Payment/card.png"
import "./style.css"

const PaymentTakeCard = ({ show }) => {
  if (!show) {
    return <></>
  }
  return (
    <div className="payment-take-card">
      <div className="slot">
        <img src={slot} />
      </div>
      <div className="card">
        <div className="image-wrapper">
          <img src={card} />
        </div>
      </div>
      <div className="arrowOut">
        <img src={arrowOut} />
      </div>
    </div>
  )
}

export { PaymentTakeCard }
