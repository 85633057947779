import React, { Component } from "react"
import locationPointerActive from "../../../../../assets/svg/location_pointer_orange.svg"
import locationPointer from "../../../../../assets/svg/location_pointer_black.svg"
import "./style.css"

class StationListItem extends Component {
    imageSrc(isActive){
        if(isActive) {
            return locationPointerActive
        }
        return locationPointer
    }

    render() {
        const {  item, onClick, isActive,  } = this.props

        const {cityName, name, address} = item
        
        return (
          <div 
            className={ isActive ? "station-item-wrapper active" : "station-item-wrapper"}
            role="button"
            alt="select station"
            onClick={() => onClick(item)}  
            >  
              <span className="icon">
                <img 
                    src={this.imageSrc(isActive)}
                    alt="station map pointer"
                />
              </span>
              <span className="station-item-text-wrapper">
                  <span className="city-name">{cityName}, </span>
                    <span className="station-name">{name}</span>
                    <div className="station-address">{address}</div>
              </span>
              
          </div>
        )
    }
}
export default StationListItem