import React from "react"
import { OrangeButton } from "../../../../../common"
import {
  getDateOutOfTimeStamp,
  getTimeOutOfTimeStamp,
} from "../../../../../../utils/DateAndTime"

import "./style.css"

const ItemRow = ({ item, onClick, btnText, dir }) => {
  const {
    orderId,
    orderNumber,
    orderDate,
    customerMobile,
    firstName,
    lastName,
    stationTargetName,
  } = item

  const dateTime =
    getDateOutOfTimeStamp(orderDate) + " | " + getTimeOutOfTimeStamp(orderDate)
  const address = stationTargetName ? ", " + stationTargetName : ""
  let info = firstName + " " + lastName + address
  info = info.length > 42 ? info.slice(0, 42) + "..." : info
  return (
    <div className="request-order-item" key={orderId}>
      <div className="column order-number">{orderNumber}</div>
      <div className="column">{dateTime}</div>
      <div className={"column info " + dir}>{info}</div>
      <div className="column">{customerMobile}</div>
      <div className="column continue">
        <OrangeButton extraClass="continue-btn" onClick={() => onClick(item)}>
          {btnText}
        </OrangeButton>
      </div>
    </div>
  )
}

export default ItemRow
