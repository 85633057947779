import axios from "axios"
import React, { Component, createRef } from "react"
import { connect } from "react-redux"
import api from "../../../api/LocalServiceApi"
import { OrangeButton } from "../../common"
import SuccessFailBtn from "../../common/PendingSuccessFailBtn/SuccessFailBtn"
import { PaymentInputCard } from "../PaymentInputCard"
import { PaymentTakeCard } from "../PaymentTakeCard"
import { PaymentWaitCard } from "../PaymentWaitCard"
import "./style.css"

class PaymentInput2 extends Component {
  constructor(props) {
    super(props)
    this.INIT_STATE = {
      changTimeOut: null,
      inputVal: "",
      status: this.props.payment_card_reader_insert,
      showInputCard: true,
      showWaitCard: false,
      showTakeCard: false,
      showTryPayCard: false,
    }
    this.stopRead = true
    this.state = this.INIT_STATE
    this.timeToLeave = 5
    this.cardInput = createRef()
  }

  readCardCall() {
    const { payment_card_reader_error } = this.props

    if (!this.stopRead && this.timeToLeave > 0)
      axios.get(api.readFromCard("read")).then((read) => {
        //READ CARD
        if (
          read === undefined ||
          read.data === undefined ||
          read.data === "error"
        ) {
          this.setState({ status: payment_card_reader_error })
          this.stopRead = false
          this.timeToLeave -= 1
          this.startReadFromCard()
          this.resetSelfStateIn(2)
        } else {
          this.onInputChange(read.data)
          this.timeToLeave = 5
          this.stopRead = true
          //RESET CARD
          axios.get(api.readFromCard("reset"))
        }
      })
  }
  startReadFromCard() {
    if (this.timeToLeave <= 0) {
      //Pay with fake after timeToLeave
      this.onInputChange("4580000000000000=data")
      this.stopRead = true
      this.timeToLeave = 5
    }
    const {
      payment_card_reader_insert,
      payment_card_reader_remove,
      payment_card_reader_error,
    } = this.props

    this.setState({
      status: payment_card_reader_insert,
      showWaitCard: false,
      showTakeCard: false,
      showInputCard: true,
    })

    //INSERT CARD
    !this.stopRead &&
      axios
        .get(api.readFromCard("insert"))
        .then((res_insert) => {
          console.log("RES_INSERT", res_insert)
          //call API to remove card
          if (
            res_insert !== undefined &&
            res_insert.data !== undefined &&
            res_insert.data !== "error"
          ) {
            if (!this.stopRead && this.timeToLeave > 0)
              // todo if time to leave  =0?
              //REMOVE CARD
              axios.get(api.readFromCard("remove")).then((res_remove) => {
                if (
                  res_remove !== undefined &&
                  res_remove.data !== undefined &&
                  res_remove.data !== "error"
                ) {
                  this.setState({
                    status: payment_card_reader_remove,
                    showWaitCard: false,
                    showTakeCard: true,
                    showInputCard: false,
                  })

                  //call API to read number and put it on 'inputVal'
                  this.readCardCall()
                } else {
                  this.timeToLeave -= 1
                  this.setState({ status: payment_card_reader_error })
                  this.stopRead = false
                  this.startReadFromCard()
                  this.resetSelfStateIn(3)
                }
              })
          } else {
            this.timeToLeave -= 1
            this.setState({ status: payment_card_reader_error })
            this.stopRead = false
            this.startReadFromCard()
            this.resetSelfStateIn(3)
          }
        })
        .catch((err) => {
          console.log(err)
          this.stopReadCard()
        })
  }

  stopReadCard() {
    const { changTimeOut } = this.state
    this.stopRead = true
    clearTimeout(changTimeOut)
    //RESET CARD
    axios.get(api.readFromCard("reset"))
  }

  componentDidMount() {
    // this.catchFocus()
    //call API to insert card
    this.cardInput.current.focus()
    this.stopRead = false
    this.startReadFromCard()
  }

  componentWillUnmount() {
    this.stopReadCard()
    this.setState(this.INIT_STATE)
  }

  // catchFocus() {
  //   const { paymentInput } = this.refs
  //   paymentInput.focus()
  // }

  onInputChange(val) {
    const { payment_card_reader_wait } = this.props

    this.setState({
      status: payment_card_reader_wait,
      showWaitCard: true,
      showTakeCard: false,
      showInputCard: false,
    })
    if (!isNaN(val)) {
      val = `${val}`
    }
    const { inputVal, changTimeOut } = this.state

    if (inputVal.length < val.length) {
      if (changTimeOut) {
        clearTimeout(changTimeOut)
      }

      const timeout = setTimeout(() => this.onInputStreadEnded(), 700)
      this.setState({ inputVal: val, changTimeOut: timeout })
    }
  }

  onInputStreadEnded() {
    let { inputVal } = this.state
    const { payment_card_reader_error } = this.props

    try {
      if (inputVal.includes(";")) {
        inputVal = inputVal.split(";")[1]
      }

      inputVal = inputVal.split("=")

      let cCard
      let mmyy

      if (inputVal[0].length === 16 || inputVal[0].length === 15) {
        cCard = inputVal[0]
        mmyy = `${inputVal[1].substr(2, 2)}${inputVal[1].substr(0, 2)}`
      } else {
        if (inputVal[1]) {
          // THIS IS NOT A REGULAR CREDIT CARD - 9 DIGITS - OMRI'S CODE :)
          cCard = inputVal[1].substr(0, 9)
          mmyy = `${inputVal[1].substr(16, 2)}${inputVal[1].substr(14, 2)}`
        }
      }

      this.props.onPaymentDataReady(cCard, mmyy)
      this.resetSelfStateIn(3)
    } catch (e) {
      console.log(e)
      this.setState({
        inputVal: "",
        status: payment_card_reader_error,
        showWaitCard: false,
        showTakeCard: false,
        showInputCard: false,
        showTryPayCard: true,
      })
      return
    }
  }

  tryPayCard() {
    this.stopRead = false
    this.timeToLeave = 2
    this.setState({
      showWaitCard: false,
      showTakeCard: false,
      showInputCard: false,
      showTryPayCard: false,
    })

    this.resetSelfStateIn(1)
    this.startReadFromCard()
  }

  resetSelfStateIn(secs) {
    setTimeout(() => this.setState({ ...this.INIT_STATE }), secs * 1000)
  }

  renderTryAgainBtn(show) {
    if (!show) {
      return <></>
    }
    const { payment_swipe_a_card } = this.props
    return (
      <>
        <SuccessFailBtn success={false} />
        <OrangeButton
          onClick={() => this.tryPayCard()}
          extraClass="paymdl2-enter-carddate-manually-btn"
        >
          {" "}
          {payment_swipe_a_card}{" "}
        </OrangeButton>
      </>
    )
  }

  render() {
    const { extraClass } = this.props
    const {
      inputVal,
      showInputCard,
      showWaitCard,
      showTakeCard,
      showTryPayCard,
    } = this.state

    return (
      <div className={"payment2-wrapper " + extraClass}>
        {!this.props.justCheck && (
          <div className="whlb-wrapper paymdl2-title">{this.state.status}</div>
        )}
        <PaymentInputCard show={showInputCard} />
        <PaymentWaitCard show={showWaitCard} />
        <PaymentTakeCard show={showTakeCard} />
        {this.renderTryAgainBtn(showTryPayCard)}
        <form>
          <input
            type="password"
            value={inputVal}
            className="payment-input"
            onChange={(e) => this.onInputChange(e.target.value)}
            onBlur={() => this.cardInput.current.focus()}
            ref={this.cardInput}
          />
        </form>
      </div>
    )
  }
}

const mapStateToProps = ({ userLogin, translate }) => {
  const { authenticated } = userLogin

  const {
    payment_card_reader_insert,
    payment_card_reader_remove,
    payment_card_reader_wait,
    payment_card_reader_error,
    payment_swipe_a_card,
  } = translate

  return {
    authenticated,
    payment_card_reader_insert,
    payment_card_reader_remove,
    payment_card_reader_wait,
    payment_card_reader_error,
    payment_swipe_a_card,
  }
}

export default connect(mapStateToProps)(PaymentInput2)
