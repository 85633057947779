import React from "react"
import { FixedFooter, FixedHeader } from "../../../reduxified"
import LockerImg from "../../../../assets/svg/rent_locker.svg"
import "./style.css"
import { withRouter } from "react-router-dom"
import { connect } from "react-redux"
import OrangeDot from "../../../common/OrangeDot"
import { OrangeButton } from "../../../common"

const AboutRental = (props) => {
  const {
    about_rental_title,
    about_rental_text_1,
    about_rental_text_2,
    about_rental_text_3,
    about_rental_text_4,
    next_text,
    thanks,
    dirRtl,
  } = props

  return (
    <>
      <FixedHeader buttonsFlag="aboutRental" />

      <div className="rental-info-wrapper">
        <div className="about-rental-title" dir={dirRtl ? "rtl" : "ltr"}>
          {about_rental_title}
        </div>

        <div className="text-and-img-wrapper">
          <div
            className="about-rental-text-wrapper"
            dir={dirRtl ? "rtl" : "ltr"}
          >
            <div className="about-rental-string">
              <span>
                <OrangeDot className="rent-info-dot" />
              </span>
              {about_rental_text_1}
            </div>
            <div className="about-rental-string">
              <span>
                <OrangeDot className="rent-info-dot" />
              </span>
              {about_rental_text_2}
            </div>
            <div className="about-rental-string">
              <span>
                <OrangeDot className="rent-info-dot" />
              </span>
              {about_rental_text_3}
            </div>
            <div className="about-rental-string">
              <span>
                <OrangeDot className="rent-info-dot" />
              </span>
              {about_rental_text_4}
            </div>
            <div className="about-rental-string">
              <p>&emsp;&emsp;{thanks}</p>
            </div>
          </div>
          <div className="img-locker-wrapper">
            <img src={LockerImg} alt="" />
          </div>
        </div>
        {props.history.location.state === "rent" ? (
          <div className="rent-locker-info-button">
            <OrangeButton
              onClick={() =>
                props.history.push("UserLoginWithDoneLoginScreen", "rent")
              }
            >
              {next_text}
            </OrangeButton>
          </div>
        ) : (
          ""
        )}
      </div>

      <FixedFooter history={props.history} className="about-rental-footer" />
    </>
  )
}
const mapStateToProps = ({ translate }) => {
  const {
    about_rental_title,
    about_rental_text_1,
    about_rental_text_2,
    about_rental_text_3,
    about_rental_text_4,
    next_text,
    thanks,
    dirRtl,
  } = translate
  return {
    about_rental_title,
    about_rental_text_1,
    about_rental_text_2,
    about_rental_text_3,
    about_rental_text_4,
    next_text,
    thanks,
    dirRtl,
  }
}
export default connect(mapStateToProps)(withRouter(AboutRental))
