import axios from "axios"
import api from "../api"

import {
  READ_STATION_ID_FROM_BG_SERVICE_SUCCESS,
  READ_STATION_ID_FROM_BG_SERVICE_FAIL,
  GET_STATION_NUMBER,
  BG_SERVICE_ALIVE_SUCCESS,
  BG_SERVICE_ALIVE_FAIL,
} from "./types"
import { setBGErrorIfExist } from "./ErrorMessagesActions"

export const readStationIdFromBgService = () => {
  return (dispatch) => {
    axios
      .get(api.getStationId)
      .then((res) => {
        dispatch({
          type: READ_STATION_ID_FROM_BG_SERVICE_SUCCESS,
          payload: res.data,
        })
      })
      .catch((err) => {
        dispatch({
          type: READ_STATION_ID_FROM_BG_SERVICE_FAIL,
        })
        setBGErrorIfExist(err)
      })
  }
}

export const readBgServiceConfiguration = () => {
  return (dispatch) => {
    axios
      .get(api.getStationConfiguration)
      .then((res) => {
        const { stationNumber, env, version } = res.data
        dispatch({
          type: GET_STATION_NUMBER,
          payload: stationNumber,
        })
        console.log(
          "%c%s %c %s %c %s %c %s",
          "font-weight:800; color:blue;",
          "Kiosk Station Configuration =>",
          "font-weight:800; color:magenta;",
          `Kioks Env - ${env} ->`,
          "font-weight:800; color:lime;",
          `Kioks version - ${version} ->`,
          "font-weight:800; color:violet;",
          `Kioks stationNumber - ${stationNumber}`
        )

        api.fbSetStationNumber(stationNumber)
      })
      .catch((err) => {
        console.log("Error reading Station Configuration", err)
        dispatch({
          type: BG_SERVICE_ALIVE_FAIL,
          payload: err,
        })
      })
  }
}

export const logScreenEnterance = (screenName) => {
  return (dispatch) => {
    const csvLine = `screen_log, ${screenName}, null, null,`

    const reqData = { csvLine }
    axios
      .post(api.logToStationLogger, reqData)
      .then((res) => {
        dispatch({
          type: BG_SERVICE_ALIVE_SUCCESS,
          payload: res,
        })
      })
      .catch((err) => {
        dispatch({
          type: BG_SERVICE_ALIVE_FAIL,
          payload: err,
        })
      })
  }
}

export const logLockerOpenCloseFailToStationLogger = (isOpen, lockerNum) => {
  return (dispatch) => {
    const csvLine = `locker_${
      isOpen ? "open" : "close"
    }, ${lockerNum}, FAIL, null,`

    const reqData = { csvLine }
    axios.post(api.logToStationLogger, reqData)
  }
}

export const logScreenEvent = (screenName, event, data) => {
  return (dispatch) => {
    const csvLine = `screen_event_log, ${screenName}, ${event}, ${data},`

    const reqData = { csvLine }
    axios
      .post(api.logToStationLogger, reqData)
      .then((res) => {
        dispatch({
          type: BG_SERVICE_ALIVE_SUCCESS,
          payload: res,
        })
      })
      .catch((err) => {
        console.log("logToStationLogger fail:")
        console.log(err)
        dispatch({
          type: BG_SERVICE_ALIVE_FAIL,
          payload: err,
        })
      })
  }
}

export const readBGServiceAlive = () => {
  return (dispatch) => {
    axios
      .get(api.getBGServiceAlive)
      .then((res) => {
        dispatch({
          type: BG_SERVICE_ALIVE_SUCCESS,
          payload: res,
        })
      })
      .catch((err) => {
        console.log("readBGServiceAlive fail")
        console.log(err)

        dispatch({
          type: BG_SERVICE_ALIVE_FAIL,
          payload: err,
        })
      })
  }
}
