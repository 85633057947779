import React, { Component } from "react"

import { connect } from "react-redux"

import { OrangeButton } from "../../../common"
import { FixedHeader } from "../../../reduxified"
import {
  logScreenEnterance,
  LogoutUser,
  fetchAllLockersStatuses,
  getTasksByStation,
} from "../../../../actions"

import "./style.css"
import ErrorInfoModal from "../../../Modals/ErrorInfoModal"

class DriverMenuScreen extends Component {
  constructor(props) {
    super(props)

    this.state = {
      showError: false,
      isMustCompleteTasks: false,
    }
  }

  componentDidMount() {
    const {
      authenticated,
      role,
      stationId,
      isNeedAccessibility,
      isMustCompleteTasksFirstRole,
      isAnyOrderToCollect,
      isMustCollectFirstRole,
      isCollectOrdersScreenVisited,
      isDeliveryRole,
      isAnyOrderToReturn,
    } = this.props

    if (!authenticated) {
      this.props.LogoutUser()
      this.props.history.push("")
    }

    this.props.fetchAllLockersStatuses(stationId, isNeedAccessibility)
    if (authenticated && role === "Customer") {
      this.props.LogoutUser()
      this.props.history.push("")
      this.setState({ showError: true })
    }

    //if (isMustCompleteTasksFirstRole) {
    this.props.getTasksByStation(stationId)
    //}

    if (
      isAnyOrderToCollect &&
      (isMustCollectFirstRole || !isCollectOrdersScreenVisited)
    ) {
      //console.log("###### isMustCollectFirstRole")
      //console.log("isAnyOrderToCollect: " + isAnyOrderToCollect)
      //console.log("isMustCollectFirstRole: " + isMustCollectFirstRole)
      //console.log(
      //  "isCollectOrdersScreenVisited: " + isCollectOrdersScreenVisited
      //)

      this.props.history.push("CollectOrdersScreen")
    }

    if (isDeliveryRole && isAnyOrderToReturn && !isCollectOrdersScreenVisited) {
      //console.log("###### isDeliveryRole")
      //console.log(
      //  "isCollectOrdersScreenVisited: " + isCollectOrdersScreenVisited
      //)
      //console.log("isDeliveryRole: " + isDeliveryRole)
      //console.log("isAnyOrderToReturn: " + isAnyOrderToReturn)

      this.props.history.push("CollectOrdersScreen")
    }

    this.props.logScreenEnterance("DRIVER_MENU_SCREEN")
  }

  componentDidUpdate() {
    const {
      authenticated,
      isDeliveryRole,
      isAnyOrderToReturn,
      isCollectOrdersScreenVisited,
      isLoadCleanOrdersScreenVisited,
      getStationTasksSuccess,
      stationTasksList,
      role,
      isAnyOrderToCollect,
      isMustCollectFirstRole,
      isMustCompleteTasksFirstRole,
    } = this.props

    if (!authenticated) {
      this.props.LogoutUser()
      this.props.history.push("")
    }

    if (
      isAnyOrderToCollect &&
      (isMustCollectFirstRole || !isCollectOrdersScreenVisited)
    ) {
      this.props.history.push("CollectOrdersScreen")
    }

    if (isDeliveryRole && isAnyOrderToReturn && !isCollectOrdersScreenVisited) {
      this.props.history.push("CollectOrdersScreen")
    }

    if (
      isMustCompleteTasksFirstRole &&
      stationTasksList?.length > 0 &&
      !this.state.isMustCompleteTasks
    ) {
      this.setState({ isMustCompleteTasks: true })
    }
  }

  onDriverLoadingACellClick() {
    this.props.history.push("LoadCleanOrdersScreen")
  }

  onDriverCollectFromACellClick() {
    this.props.history.push("CollectOrdersScreen")
  }

  onDriverTasksCellClick() {
    this.props.history.push("StatonTasksScreen")
  }

  onDriverDeliberatelyOpenCellsClick() {
    this.props.history.push("UnlockLockersScreen")
  }

  onGoBackClick() {
    this.props.history.goBack()
  }

  render() {
    const {
      dirRtl,
      user_menu_what_sub_title_text,
      driver_menu_ready_orders_loading,
      driver_menu_orders_collecting,
      driver_menu_lockers_cells_opening,
      driver_menu_tasks,
      back_btn_text,
      hello_title_text,
      firstName,
      isShowUnlockScreen,
      login_just_for_drivers,
      close_text,
      task_priority_text,
      stationTasksList,
    } = this.props
    const { showError } = this.state

    return (
      <div className="ls-wrapper">
        <FixedHeader buttonsFlag="driverMenuScreen" />
        <div className="dms-title" dir={dirRtl ? "rtl" : "ltr"}>
          {`${hello_title_text}, ${firstName}`}
        </div>
        <div className="dms-subtitle" dir={dirRtl ? "rtl" : "ltr"}>
          {user_menu_what_sub_title_text}
        </div>
        {this.state.isMustCompleteTasks && (
          <div className="task-priority-text">{task_priority_text}</div>
        )}
        <div className="dms-wrapper">
          <OrangeButton
            isDisabled={this.state.isMustCompleteTasks}
            extraClass="user-menu-btn"
            onClick={(e) => this.onDriverLoadingACellClick()}
          >
            {driver_menu_ready_orders_loading}
          </OrangeButton>
          <OrangeButton
            extraClass="user-menu-btn"
            onClick={(e) => this.onDriverCollectFromACellClick()}
          >
            {driver_menu_orders_collecting}
          </OrangeButton>
          <OrangeButton
            extraClass="user-menu-btn"
            onClick={(e) => this.onDriverDeliberatelyOpenCellsClick()}
            show={isShowUnlockScreen}
          >
            {driver_menu_lockers_cells_opening}
          </OrangeButton>
          {stationTasksList && stationTasksList?.length > 0 && (
            <OrangeButton
              extraClass="user-menu-btn"
              onClick={(e) => this.onDriverTasksCellClick()}
            >
              {driver_menu_tasks}
            </OrangeButton>
          )}
          <OrangeButton
            extraClass="user-menu-btn"
            onClick={(e) => this.onGoBackClick()}
          >
            {back_btn_text}
          </OrangeButton>
          <ErrorInfoModal
            dirRtl={dirRtl}
            show={showError}
            text={login_just_for_drivers}
            onModalCloseClick={() => this.setState({ showError: false })}
            closeTrnsl={close_text}
          />
        </div>
      </div>
    )
  }
}

const mapStateToProps = ({
  translate,
  userLogin,
  lockersCells,
  visitingScreens,
  bgService,
  stationTasks,
}) => {
  const {
    authenticated,
    isDeliveryRole,
    isMustCollectFirstRole,
    isMustCompleteTasksFirstRole,
    firstName,
    isShowUnlockScreen,
    role,
    isNeedAccessibility,
  } = userLogin
  const { isAnyOrderToReturn, isAnyOrderToCollect } = lockersCells
  const { isCollectOrdersScreenVisited, isLoadCleanOrdersScreenVisited } =
    visitingScreens
  const { stationId } = bgService
  const {
    dirRtl,
    user_menu_what_sub_title_text,
    driver_menu_ready_orders_loading,
    driver_menu_orders_collecting,
    driver_menu_lockers_cells_opening,
    driver_menu_tasks,
    back_btn_text,
    hello_title_text,
    login_just_for_drivers,
    close_text,
    task_priority_text,
  } = translate
  const { stationTasksList, getStationTasksSuccess } = stationTasks
  return {
    authenticated,
    role,
    isDeliveryRole,
    isShowUnlockScreen,
    isAnyOrderToReturn,
    isAnyOrderToCollect,
    isCollectOrdersScreenVisited,
    isLoadCleanOrdersScreenVisited,
    dirRtl,
    hello_title_text,
    user_menu_what_sub_title_text,
    driver_menu_ready_orders_loading,
    driver_menu_orders_collecting,
    driver_menu_lockers_cells_opening,
    driver_menu_tasks,
    back_btn_text,
    login_just_for_drivers,
    firstName,
    close_text,
    stationId,
    isNeedAccessibility,
    stationTasksList,
    getStationTasksSuccess,
    task_priority_text,
    isMustCollectFirstRole,
    isMustCompleteTasksFirstRole,
  }
}

export default connect(mapStateToProps, {
  logScreenEnterance,
  LogoutUser,
  fetchAllLockersStatuses,
  getTasksByStation,
})(DriverMenuScreen)
