import React from "react"
import Modal from "react-modal"
import { OrangeButton } from "../../common"
import ServiceMenuButton from "../../common/ServiceMenuButton"
import dhlBarcode from "../../../assets/svg/dhl_barcode_on_btn.svg"
import dhlQrcode from "../../../assets/svg/dhl_qrcode_on_btn.svg"
import "./style.scss"

const SelectIfRetunIsLabelLessModal = ({
  show,
  dirRtl,
  onLabelLessClick,
  onPrintedClick,
  onModalCloseClick,
  texts,
}) => {
  if (!show) {
    return <noscript />
  }

  return (
    <Modal
      isOpen={show}
      onRequestClose={onModalCloseClick}
      className="react-modal-content printed-labelless"
      overlayClassName="modal-overlay"
    >
      <div className="printed-labelless-modal" dir={dirRtl ? "rtl" : "ltr"}>
        <div className="title-wrapper" dir={dirRtl ? "rtl" : "ltr"}>
          {texts.title}
        </div>

        <div className="services-wrapper">
          <ServiceMenuButton
            show={true}
            serviceType="dhl"
            text={texts.labellessTitle}
            onClick={() => onLabelLessClick()}
            infoText={texts.labellessInfo}
            dirRtl={dirRtl}
            middleImg={dhlQrcode}
          />
          <ServiceMenuButton
            show={true}
            serviceType="dhl"
            text={texts.printedTitle}
            onClick={() => onPrintedClick()}
            infoText={texts.printedInfo}
            dirRtl={dirRtl}
            middleImg={dhlBarcode}
          />
        </div>
        <div className="title-wrapper" dir={dirRtl ? "rtl" : "ltr"}>
          <OrangeButton onClick={() => onModalCloseClick()}>
            {texts.btnCloseText}
          </OrangeButton>
        </div>
      </div>
    </Modal>
  )
}

export default SelectIfRetunIsLabelLessModal
