import api from '../api';
import {
    SET_LOADING_RESTORE_DELIVERY_CODE,
    FETCH_RESTORE_DELIVERY_CODE_SUCCESS,
    FETCH_RESTORE_DELIVERY_CODE_FAIL,
    RESET_ALL_REDUCERS  } from './types';
import { axiosPostRetry } from '../utils';
import { reportFBEvent } from './FBEventsActions';



export const restoreDeliveryCode = (stationId, mobilePhone) => {
    return (dispatch) => {
        dispatch(
            {
              type: SET_LOADING_RESTORE_DELIVERY_CODE
            }
        );
        dispatch(reportFBEvent('restoreDeliveryCode', 'START: mobilePhone:'+mobilePhone+', stationId:'+{stationId}+'.'));
        
        axiosPostRetry(api.restoreDeliveryCode, api.restoreDeliveryCodeBody(stationId, mobilePhone))
        
            .then(res => {
                console.log("restoreDeliveryCode response :" + res.data);
                
                dispatch(
                    {
                      type: FETCH_RESTORE_DELIVERY_CODE_SUCCESS,
                      payload: res.data  
                    }
                );
                dispatch(reportFBEvent('restoreDeliveryCode', 'SUCCESS: mobilePhone:'+mobilePhone+', stationId:'+{stationId}+'.'));
            })
            .catch(err => {
                console.log(err);
                
                dispatch(
                    {
                        type: FETCH_RESTORE_DELIVERY_CODE_FAIL,
                        payload: err
                    }
                );
                dispatch(reportFBEvent('restoreDeliveryCode', 'ERROR: mobilePhone:'+mobilePhone+', stationId:'+{stationId}+', error:'+err));
            });
    };
};


