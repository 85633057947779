const format = (arg) => `${arg < 10 ? "0" : ""}${arg}`

export const getDateOutOfTimeStamp = (timestamp) => {
  let date = new Date(`${timestamp}Z`) // + "+00:00"

  const d = format(date.getDate())
  const m = format(date.getMonth() + 1)
  const y = format(date.getFullYear()).substring(2)

  return `${d}.${m}.${y}`
}

export const getTimeOutOfTimeStamp = (timestamp) => {
  let date = new Date(`${timestamp}Z`) // + "+00:00"

  const h = format(date.getHours())
  const m = format(date.getMinutes())

  return `${h}:${m}`
}
