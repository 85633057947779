export const privacyPolicy = `חברת CleanBox בע"מ ("CleanBox") מקשרת בין ספקים סיטונאים של שירותי ניקוי יבש, כביסה וגיהוץ ("שירותי המכבסה") לבין צרכני קצה, וכן מאפשרת מסירה ואיסוף של חבילות ("שירותי המשלוחים" וביחד עם שירותי המכבסה: "השירותים"), והכל באמצעות מערכת הלוקרים של החברה ("הלוקרים" וביחד עם השירותים: "המערכת").

מדיניות הפרטיות המובאת להלן ("מדיניות הפרטיות") חלה על השימוש שלך באתר האינטרנט של CleanBox בכתובת האינטרנט www.cleanbox.co.il  ("אתר האינטרנט") ועל השימוש שלך בשירותים המסופקים על ידי CleanBox באמצעות המערכת. 

בכל מקום שבו יש שימוש בלשון זכר, הכוונה היא גם לנקבה.  

אנא קרא את תנאי השימוש בעיון. בעצם הרישום או השימוש במערכת, או הכניסה לאתר האינטרנט, אתה נותן את הסכמתך ואישורך כי קראת והבנת תנאי שימוש אלה, ואתה מסכים כי תנאי שימוש אלה יחייבו אותך, וכי תציית לכל הדינים והתקנות החלים בקשר לשימוש במערכת ובאתר האינטרנט, ואתה מאשר כי תנאי שימוש אלה מהווים הסכם משפטי מחייב וניתן לאכיפה בין CleanBox לבינך. אם אינך מסכים לתנאים אלה, אנא הימנע משימוש או גישה למערכת או לאתר האינטרנט ומכל שימוש בשירותים. 

כאשר אתה מבקר באתר שלנו, מנהלי האתר שלנו מעבדים נתונים טכניים כגון כתובת ה- IP שלך, ביקורים היסטוריים בדפי אינטרנט, דפדפן האינטרנט ומשך הזמן של ביקוריך כדי לאפשר לנו לספק את הפונקציונליות של האתרים שלנו ולהתאימם אליך. בנוסף, במקרים מסוימים ייתכן שהדפדפן יבקש ממך המיקום הגיאוגרפי שלך כדי לאפשר לנו לספק לך חוויה משופרת. עם נתונים טכניים אלה מנהלי האתר שלנו יכולים לנהל את האתר, למשל על-ידי פתרון בעיות טכניות או שיפור הנגישות של חלקים מסוימים באתר. בדרך זו, אנו מבטיחים כי אתה יכול לקבל את המידע באתר באופן מהיר ופשוט. לשם כך אנחנו גם משתמשים בעוגיות. ראה בהמשך את מדיניות העוגיות שלנו.

כאשר אתה נרשם כמשתמש, אנו נאסוף חלק או את כל הפרטים הבאים: שמך, כתובת דואר אלקטרוני, סיסמא, מספר טלפון נייד, תאריך הלידה, מקום העבודה שלך, כתובת IP, ומספר כרטיס אשראי שלך, תאריך תפוגה ואת קוד האבטחה של כרטיס האשראי.

אנו נשתמש בפרטי הקשר שלך כדי לשלוח לך הודעות טקסט ודוא"ל באמצעותן נוודא את שם המשתמש והסיסמה שלך, נעדכן אותך על מצבן של הזמנות שמסרת לטיפולנו, נתקשר איתך בתגובה לפניות שלך, ונשלח לך הודעות אחרות הקשורות לשירות, לדוגמא, אם השירות שלנו מושעה זמנית לצורך תחזוקה. אנו נשתמש במידע הרישום שלך כדי ליצור ולנהל את החשבון שלך בCleanBox. אנו עשויים לבטל את החשבון שלך אם אנו חושדים שאתה משתמש באתר, באפליקציה או בשירותים שלנו למעשי הונאה או לפעילות בלתי חוקית או אם אתה מפר את תנאי שימוש שלנו.

CleanBox רשאית להשתמש בפרטי הקשר שלך כדי לשלוח לך עדכונים כלליים בנוגע לחדשות מ-CleanBox, הצעות ומבצעים מיוחדים. בכל זמן אתה יכול לבחור שלא לקבל עדכונים אלה.

CleanBox משתמשת בנתונים האישיים שלך אופן אנונימי כדי לבחון את מאפייני השירות שלה, לנתח דפוסי שימוש ולשפר את השירות בכל עת. 

 אנו רשאים להעסיק חברות חיצוניות כדי להקל או לספק שירות בשמנו לדוגמא עבור עיבוד תשלומים או ניהול הלקוחות. לצדדים שלישיים אלה גישה למידע האישי שלך רק כדי לבצע משימות אלה בשמנו והם מחויבים חוזית לא לחשוף או להשתמש במידע שלך לכל מטרה אחרת.

CleanBox תחשוף את הנתונים האישיים שלך רק במקרים קיצוניים המוגדרים על פי חוק ולרשויות החוק במקרה של אירוע משפטי או במקרי חירום הנוגעים לבטיחותך האישית.

כמשתמש, יש לך את הזכות למידע בנוגע לחשבון האישי שלך, לרבות מידע שסיפקת לנו ומידע לגבי עסקאות היסטוריות שביצעת בCleanBox. אתה מוזמן לכתוב אלינו ולבקש מידע כזה או אחר בנוגע לחשבונך ואנחנו נעשה את המיטב כדי לספק לך מידע זה במסגרת לוחות הזמנים הנוחים לנו.

אלא אם צוין אחרת במדיניות הפרטיות, אנו שומרים לעצמנו את הזכות לשמור את המידע שלך ברשותנו עד שתבטל את החשבון שלך בCleanBox.

CleanBox נוקטת בצעדי אבטחה טכנית וארגונית מתאימים מפני אובדן או עיבוד בלתי חוקי של המידע האישי שלך. למטרה זו, הנתונים האישיים שלך מאוחסנים באופן מאובטח בתוך בסיס הנתונים שלנו, ואנו משתמשים באמצעים סטנדרטיים בתעשייה כגון הצפנה, Firewall  ותעודת SSL (Secure Socket Layers).

יחד עם זאת ולמרות המאמצים שאנחנו משקיעים, כל מערכת ניתנת לחדירה ולכן כל העברת מידע שלך אלינו היא באחריותך בלבד. אנו ממליצים שלא לחשוף את הסיסמה שלך לאף אחד.

אתר האינטרנט של CleanBox עשוי להשתמש ב"עוגיות". באפשרותך לשנות את הגדרות העוגיות שלך כדי לקבל או לא לקבל עוגיות בהגדרות הדפדפן שלך.

מדיניות הפרטיות עשויה להשתנות או להתעדכן מעת לעת ומבלי שתינתן לך על כך כל הודעה. באחריותך להתעדכן בשינויים שיחולו, אם יחולו, מעת לעת במדיניות הפרטיות. המשך השימוש שלך באתר האינטרנט לאחר כל שינוי כאמור לעיל, יהווה אות להסכמתך לשינוי זה.`
