import React from "react"

import "./style.css"

const TableBody = ({ productName, price, background }) => {
  return (
    <div className={`prls-tabl-bdy-wrapper ${background}`}>
      <div className="prls-tabl-bdy-prd-name">{productName}</div>
      <div className="prls-tabl-bdy-price">{price}</div>
    </div>
  )
}

export default TableBody
