import React from "react"
import Modal from "react-modal"
import { OrangeButton } from "../../common"

import CountdownTimer from "../../common/CountdownTimer"
import Loader from "../../common/Loader"
import "./style.css"

const CircularProgressBarModal = ({
  show,
  title,
  text,
  dirRtl,
  maxVal,
  onModalCloseClick,
  close_text,
}) => {
  if (!show) {
    return <noscript />
  }

  return (
    <Modal
      isOpen={show}
      onRequestClose={onModalCloseClick}
      className="react-modal-content"
      overlayClassName="modal-overlay"
    >
      <div className="timer-modal-title" dir={dirRtl ? "rtl" : "ltr"}>
        {title}
      </div>

      <div className="timer-modal-sub-title" dir={dirRtl ? "rtl" : "ltr"}>
        {text}
      </div>
      <CountdownTimer maxVal={maxVal} />
      <div className="loader-mb">
        <Loader />
      </div>
      <OrangeButton onClick={onModalCloseClick} extraClass="timer-modal-btn">
        {close_text}
      </OrangeButton>
    </Modal>
  )
}

export default CircularProgressBarModal
