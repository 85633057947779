import React, { Component } from "react"
import { connect } from "react-redux"
import {
  checkIfMobileAlreadyExists,
  LogoutUser,
  logScreenEnterance,
  recreateUserPassword,
} from "../../../../actions"
import { DisableEnableButton, InputFiled, OrangeButton } from "../../../common"
import VirtalKeyboard from "../../../common/VirtualKeyboard"
import ErrorInfoModal from "../../../Modals/ErrorInfoModal"
import WarningInfoModal from "../../../Modals/WarningInfoModal"
import { FixedFooter, FixedHeader } from "../../../reduxified"
import "./style.css"

class RecreatePasswordScreen extends Component {
  constructor(props) {
    super(props)
    this.state = {
      mobile: "",
      mobileRef: null,

      activeInput: null,
      activeInputName: null,

      isSubmitBtnDisabled: true,
      mobileErr: false,

      showErrorModal: false,
      showMobileAlreadyExistsModal: false,
      showRecreactPassworsModal: false,

      errorsTimeout: null,

      runValidationsTimeOut: null,
    }
  }

  componentDidMount() {
    this.props.logScreenEnterance("USER_RECREATE_PWD_SCREEN")
  }

  componentWillReceiveProps(newProps) {
    const { isExists, isLoadingRequest } = newProps

    if (!isLoadingRequest && !isExists) {
      this.setState({
        showMobileAlreadyExistsModal: true,
        isSubmitBtnDisabled: true,
      })
    } else if (!isLoadingRequest && isExists) {
      this.setState({ isSubmitBtnDisabled: false })
    }
  }

  onBackClick() {
    //this.props.history.goBack();
    this.closeRecreactPassworsModal()
  }

  onRefReady(refName, ref) {
    if (!this.state[refName]) this.setState({ [refName]: ref })
  }

  onKeyboardTextChange(text) {
    const { activeInputName, errorsTimeout } = this.state

    if (errorsTimeout) {
      clearTimeout(errorsTimeout)
    }

    this.setState({
      [activeInputName]: text,
      mobileErr: false,
      errorsTimeout: null,
    })

    if (activeInputName === "mobile" && this.isMobileNumValid(text)) {
      this.props.checkIfMobileAlreadyExists(text)
    }

    this.runValidationsWithTimoutDebounce()
  }

  isMobileNumValid(mobile) {
    if (!mobile || mobile.length === 0) {
      return false
    }

    return mobile.length === 10
  }

  runValidations() {
    const { mobile } = this.state

    const isMobileNumValid = this.isMobileNumValid(mobile)

    const isEmptyMobile = mobile.length < 1

    const mobileErr = !isMobileNumValid && !isEmptyMobile

    if (!isMobileNumValid) {
      this.setState({
        isSubmitBtnDisabled: true,
        mobileErr,
        runValidationsTimeOut: null,
      })
    } else {
      const errorsTimeout = setTimeout(
        () =>
          this.setState({
            mobileErr,
            runValidationsTimeOut: null,
          }),
        800
      )

      this.setState({ errorsTimeout })
    }
  }

  onInputFocusChange(refName, isFocused) {
    if (isFocused) {
      this.setState({
        activeInput: this.state[refName],
        activeInputName: refName.replace("Ref", ""),
      })
    } else {
      if (this.state.activeInput === this.state[refName])
        this.setState({ activeInput: null })
    }
  }

  onSendMobileToRestorePasswordClick(mobile) {
    this.setState({ showRecreactPassworsModal: true })
    this.props.recreateUserPassword(mobile)
  }

  closeRecreactPassworsModal() {
    this.setState({ showRecreactPassworsModal: false })
    this.props.LogoutUser()
    this.props.history.push("")
  }

  onInputFieldChange(inputName, val) {
    this.setState({ [inputName]: val })

    if (inputName === "mobile" && this.isMobileNumValid(val)) {
      this.props.checkIfMobileAlreadyExists(val)
    }

    this.runValidationsWithTimoutDebounce()
  }

  runValidationsWithTimoutDebounce() {
    let { runValidationsTimeOut } = this.state

    if (runValidationsTimeOut) {
      clearTimeout(runValidationsTimeOut)
    }

    runValidationsTimeOut = setTimeout(() => this.runValidations(), 500)

    this.setState({ runValidationsTimeOut })
  }

  render() {
    const {
      mobile,
      activeInputName,
      mobileErr,
      isSubmitBtnDisabled,
      showMobileAlreadyExistsModal,
      showRecreactPassworsModal,
    } = this.state

    const {
      dirRtl,
      welcome_title_text,
      reset_pwd_screen_sub_title,
      err_title_text,
      ok_text,
      cancel_text,
      reset_pwd_err_modal_body,
      reset_pwd_modal_title,
      pwdModalBody,
      mobile_input_hint,
      close_text,
    } = this.props

    const keyboardLang = "mobile"

    return (
      <div className="rps-wrapper">
        <FixedHeader
          title={welcome_title_text}
          subTitle={reset_pwd_screen_sub_title}
        />

        <div className="rps-frame-wrapper margin-top-25">
          <div className="rps-title" dir={dirRtl ? "rtl" : "ltr"}>
            {welcome_title_text}
          </div>
          <div className="rps-sub-title" dir={dirRtl ? "rtl" : "ltr"}>
            {reset_pwd_screen_sub_title}
          </div>
          <div className="rps-frame-inner-wrapper">
            <InputFiled
              value={mobile}
              onChange={(e) =>
                this.onInputFieldChange("mobile", e.target.value)
              }
              placeholder={mobile_input_hint}
              icon="mini_phone"
              onRefReady={(ref) => this.onRefReady("mobileRef", ref)}
              onFocusChanged={(isFocused) =>
                this.onInputFocusChange("mobileRef", isFocused)
              }
              displayErr={mobileErr}
              maxLength={10}
              autoFocus
            />
          </div>
          <div className="rps-details-btns" dir={dirRtl ? "rtl" : "ltr"}>
            <DisableEnableButton
              extraClass="rps-details-single-btn"
              onClick={(e) => this.onSendMobileToRestorePasswordClick(mobile)}
              isDisabled={isSubmitBtnDisabled}
            >
              {ok_text}
            </DisableEnableButton>
            <OrangeButton
              extraClass="rps-details-single-btn"
              onClick={(e) => this.onBackClick()}
            >
              {cancel_text}
            </OrangeButton>
          </div>
        </div>

        <VirtalKeyboard
          currentValue={this.state[activeInputName]}
          onKeyboardInputChange={(txt) => this.onKeyboardTextChange(txt)}
          show={true}
          lang={keyboardLang}
          closeOnEnter
        />
        <ErrorInfoModal
          dirRtl={dirRtl}
          show={showMobileAlreadyExistsModal}
          title={err_title_text}
          text={reset_pwd_err_modal_body}
          onModalCloseClick={() =>
            this.setState({ showMobileAlreadyExistsModal: false })
          }
          closeTrnsl={close_text}
        />
        <WarningInfoModal
          dirRtl={dirRtl}
          show={showRecreactPassworsModal}
          title={reset_pwd_modal_title}
          text={pwdModalBody}
          onModalCloseClick={() => this.closeRecreactPassworsModal()}
          btnText={close_text}
        />
        {/* <FixedFooter history={this.props.history} /> */}
      </div>
    )
  }
}

const mapStateToProp = ({ recreatePassword, translate }) => {
  const { mobile, isExists, isLoadingRequest, newTmpPwd } = recreatePassword

  const {
    dirRtl,
    welcome_title_text,
    reset_pwd_screen_sub_title,
    ok_text,
    cancel_text,
    err_title_text,
    reset_pwd_err_modal_body,
    close_text,
    reset_pwd_modal_title,
    reset_pwd_modal_body,
    mobile_input_hint,
    reset_pwd_modal_body_with_pwd,
  } = translate

  // const pwdModalBody = newTmpPwd ?
  //     reset_pwd_modal_body_with_pwd.replace('{XYZ}', newTmpPwd) :
  //     reset_pwd_modal_body;

  const pwdModalBody = reset_pwd_modal_body

  return {
    mobile,
    isExists,
    isLoadingRequest,
    pwdModalBody,

    dirRtl,
    welcome_title_text,
    ok_text,
    cancel_text,
    err_title_text,
    reset_pwd_err_modal_body,
    reset_pwd_screen_sub_title,
    reset_pwd_modal_title,
    mobile_input_hint,
    close_text,
  }
}

export default connect(mapStateToProp, {
  checkIfMobileAlreadyExists,
  LogoutUser,
  recreateUserPassword,
  logScreenEnterance,
})(RecreatePasswordScreen)
