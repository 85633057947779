import axios from "axios"
import api from "../api"

import { ALIVE_API_SUCCESS, ALIVE_API_FAIL } from "./types"

export const goAliveAPI = () => {
  return (dispatch) => {
    axios
      .get(api.getAliveAPI)
      .then((res) => {
        console.log(res)
        dispatch({
          type: ALIVE_API_SUCCESS,
          payload: res,
        })
      })
      .catch((err) => {
        console.log(err)
        dispatch({
          type: ALIVE_API_FAIL,
          payload: err,
        })
      })
  }
}
