import React from 'react';

import './style.css';

const ItemRow = ({ item, roundBottomCorners }) => {
    const { title, amount } = item;

    const className = `rol-ordl-itm-wrap ${roundBottomCorners ? 'rol-ordl-itm-round-btm-crnrs' : ''}`;

    return (
        <div className={className}>
            <div className='rol-ordl-itm-row-f rol-itls-itm-row-flex2'>
                {title}
            </div>
            <div className='rol-ordl-itm-row-f'>
                {amount}
            </div>
        </div>
    );
}

export default ItemRow;
