const api = {}

const HOST_PORT = "3001"
const NAYAX_PORT = "5005"

const BASE_URL = `http://localhost:${HOST_PORT}`
const BASE_NAYAX_URL = `http://localhost:${NAYAX_PORT}`

api.getStationId = `${BASE_URL}/getStationId`

api.getStationConfiguration = `${BASE_URL}/getStationConfiguration`

api.getBGServiceAlive = `${BASE_URL}/getBGServiceAlive`

// api.payWithPelecard = `${BASE_URL}/payWithPelecard_PSEUDO`;
api.payWithPelecard = `${BASE_URL}/payWithPelecard`

api.logToStationLogger = `${BASE_URL}/logToStationLogger`

api.storeFailedRequest = `${BASE_URL}/storeFailedRequest`

api.createOrUpdateUser = `${BASE_URL}/createOrUpdateUser`

api.authenticateWithBgService = `${BASE_URL}/authenticateUser`

api.createOrUpdateKeyValuePair = `${BASE_URL}/createOrUpdateKeyValuePair`

api.getKeyValuePairByKey = `${BASE_URL}/getKeyValuePairByKey` // TODO - CURRENTLY UNUSED.. USE!

api.getPelecardToken = `${BASE_URL}/getPelecardToken`

api.payUsingPelecardToken = `${BASE_URL}/payUsingPelecardToken`

api.readFromCard = (order) => `${BASE_URL}/readFromCard?order=${order}`

api.payWithNayax = (price, orderNumber) =>
  `${BASE_NAYAX_URL}/api/NayaxMultiSession/ReceivePayment/${price}/${orderNumber}`

api.validationNAYAX = `${BASE_NAYAX_URL}/api/NayaxMultiSession/InfoSession`

api.cancelNayaxMultiSession = `${BASE_NAYAX_URL}/api/NayaxMultiSession/SessionCancel`

export default api
