import React from "react"
import slot from "../../../assets/images/Payment/slot.png"
import wait_circle from "../../../assets/svg/loader.svg"
import card from "../../../assets/images/Payment/card.png"
import "./style.css"
import Loader from "../Loader"

const PaymentWaitCard = ({ show }) => {
  if (!show) {
    return <></>
  }
  return (
    <div className="payment-wait-card">
      <div className="slot">
        <img src={slot} />
      </div>
      <div className="card">
        <div className="image-wrapper">
          <img src={card} />
        </div>
      </div>
      <Loader />
    </div>
  )
}

export { PaymentWaitCard }
