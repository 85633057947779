import React from "react"
import Modal from "react-modal"
import { OrangeButton } from "../../common"
import wheelchair from "../../../assets/svg/wheelchair.svg"
import "./style.css"

const AccessibilityModal = ({
  show,
  title,
  text,
  onModalCloseClick,
  dirRtl,
  btnText,
  className,
}) => {
  if (!show) {
    return <noscript />
  }

  return (
    <Modal
      isOpen={show}
      onRequestClose={onModalCloseClick}
      className={"react-modal-content " + className}
      overlayClassName="modal-overlay"
    >
      <div className="wheelchair-wrapper">
        <img width={100} src={wheelchair} alt="wheelchair" />
      </div>
      <div className="errinm-label-extra-class" dir={dirRtl ? "rtl" : "ltr"}>
        {title}
      </div>
      <div
        className="errinm-label-extra-class errinm-label-extra-class2"
        dir={dirRtl ? "rtl" : "ltr"}
      >
        {text && text.split("\n").map((str) => <p>{str}</p>)}
      </div>
      <OrangeButton onClick={(e) => onModalCloseClick()}>
        {btnText}
      </OrangeButton>
    </Modal>
  )
}

export default AccessibilityModal
