import React from "react"
import slot from "../../../assets/images/Payment/slot.png"
import arrowIn from "../../../assets/svg/arrow_in.svg"
import card from "../../../assets/images/Payment/card.png"
import "./style.css"

const PaymentInputCard = ({ show }) => {
  if (!show) {
    return <></>
  }

  return (
    <div className="payment-input-card">
      <div className="slot">
        <img src={slot} />
      </div>
      <div className="card">
        <img src={card} />
      </div>
      <div className="arrowIn">
        <img src={arrowIn} />
      </div>
    </div>
  )
}

export { PaymentInputCard }
