export const getOrderItems = (items) => {
  const orderItems = items.map(
    ({ icon: processName, amount: quantity, comment: notes }) => {
      return {
        processName,
        quantity,
        notes,
      };
    }
  );
  return orderItems;
};

export const ORDER_LockerToLocker_STUB = () => {
  return {
    stationId: "3C30E804-F594-4CF6-25E6-08D987C7DDCD",
    locationId: "C96DCC19-7A6F-402D-4C2D-08D987C7DDCD",
    customerId: "",
    firstName: "Naama",
    lastName: "Resling",
    mobilePhone: "0520000000",
    couponId: "",
    orderType: 5,
    orderItems: [
      {
        quantity: 1,
        processName: "Shipment",
      },
    ],
    sourceCustomerId: "d597450c-03ac-452e-8830-08d92f2dec16",
    sourceStationId: "3C30E804-F594-4CF6-25E6-08D987C7DDCD",
    targetStationId: "7c910fd4-5c38-4d02-8c1b-8382ec1145b4",
  };
};

export const ORDER_LockerRent_STUB = {
  stationId: "7c910fd4-5c38-4d02-8c1b-8382ec1145b4",
  locationId: "79B3A53C-2EF2-4151-E71C-08D71A42B1F8",
  customerId: "d597450c-03ac-452e-8830-08d92f2dec16",
  couponId: "",
  orderType: 4,
  orderItems: [
    {
      quantity: 1,
      processName: "Locker rental",
    },
  ],
};
