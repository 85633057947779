import React, { useEffect, useState } from "react"

import "./style.css"

const CountdownTimer = ({ maxVal }) => {
  const [time, setTime] = useState(new Date().setHours(0, 0, maxVal))
  useEffect(() => {
    setTime(new Date().setHours(0, 0, maxVal))
  }, [maxVal])

  return (
    <div className="timer">{new Date(time).toLocaleTimeString("it-IT")}</div>
  )
}

export default CountdownTimer
