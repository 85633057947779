import axios from "axios"
import api from "../api"

export const reportFBEvent = (eventName, eventMsg) => {
  return (dispatch) => {
    axios.post(
      api.reportFBEventUrl,
      api.reportFBEventBody(eventName, eventMsg),
      { headers: { Authorization: null } }
    )
  }
}
