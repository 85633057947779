import { SET_ACTIVE_FLOW,
    SET_FLOW_ACTIVE_PAGE,
} from './types';

export const setFlowActivePage = (page) => {

    return (dispatch) => { 
        dispatch({
            type: SET_FLOW_ACTIVE_PAGE,
            payload: page,
        })
    };
};

/*export const setActiveFlow = (flow) => {

    return (dispatch) => { 
        dispatch({
            type: SET_ACTIVE_FLOW,
            payload: flow,
        })
    };
}; */

