import React, { useEffect, useState } from "react"
import { connect } from "react-redux"
import { FixedFooter, FixedHeader, LockerCells } from "../../../reduxified"
import {
  getDateOutOfTimeStamp,
  getTimeOutOfTimeStamp,
} from "../../../../utils/DateAndTime"
import { OrangeButton } from "../../../common"
import {
  LogoutUser,
  logScreenEnterance,
  setPageAsVisited,
  getTasksByStation,
  taskAccomplishedReport,
  clearTaskCompletedState,
} from "../../../../actions"
import CompleteTask from "./completeTask/completeTask"
import "./style.scss"

const StatonTasksScreen = (props) => {
  const [selectedTask, setSelectedTask] = useState(false)
  const [showCompleteTaskConfirmPopup, setShowCompleteTaskConfirmPopup] =
    useState(false)
  useEffect(() => {
    if (!props.authenticated) {
      props.LogoutUser()
      props.history.push("")
    }
    props.logScreenEnterance("DRIVER_TASKS_SCREEN")
    if (!props.stationTasksList || props.stationTasksList?.length == 0) {
      props.getTasksByStation(props.stationId)
    }
  }, [])

  const onCloseTaskConfirmPopup = () => {
    setSelectedTask(false)
    props.clearTaskCompletedState()
    setShowCompleteTaskConfirmPopup(false)
    props.getTasksByStation(props.stationId)
  }

  return (
    <div className="driver-tasks-wrapper">
      <FixedHeader buttonsFlag="collectOrdersScreen" />
      <div className="driver-tasks">
        <h1>{props.driver_menu_tasks}</h1>
        <div className="tasks-list">
          {props.stationTasksList?.map((task) => (
            <div className="task-item" key={task.taskId}>
              <div>משימה: {task.taskNumber}</div>
              <div> {task.taskDetails}</div>
              <div>
                {getDateOutOfTimeStamp(task.createdDate) +
                  " " +
                  getTimeOutOfTimeStamp(task.createdDate)}
              </div>
              <div>
                <OrangeButton
                  extraClass={"btn-task-completed"}
                  onClick={() => {
                    setSelectedTask(task)
                    setShowCompleteTaskConfirmPopup(true)
                  }}
                >
                  {props.btn_text_completed}
                </OrangeButton>
              </div>
            </div>
          ))}
        </div>
        {props.stationTasksList?.length == 0 ? <div>אין משימות</div> : null}
      </div>
      <FixedFooter history={props.history} />
      <CompleteTask
        selectedTaskId={selectedTask.id}
        show={showCompleteTaskConfirmPopup}
        close={onCloseTaskConfirmPopup}
      ></CompleteTask>
    </div>
  )
}

const mapStateToProps = ({ userLogin, translate, stationTasks, bgService }) => {
  const { authenticated } = userLogin
  const {
    dirRtl,
    driver_menu_tasks,
    btn_text_completed,
    close_text,
    task_completed_confirmation,
    yes_text,
    no_text,
    task_completed_report,
    add_notes,
  } = translate
  const { stationId } = bgService
  const { stationTasksList, getStationTasksSuccess, getStationTasksFail } =
    stationTasks
  return {
    authenticated,
    dirRtl,
    driver_menu_tasks,
    getStationTasksSuccess,
    getStationTasksFail,
    stationTasksList,
    btn_text_completed,
    stationId,
    close_text,
    task_completed_confirmation,
    yes_text,
    no_text,
    task_completed_report,
    add_notes,
  }
}

export default connect(mapStateToProps, {
  LogoutUser,
  logScreenEnterance,
  setPageAsVisited,
  getTasksByStation,
  taskAccomplishedReport,
  clearTaskCompletedState,
})(StatonTasksScreen)
