import React, { Component } from "react"
import { connect } from "react-redux"
import BarcodeReader from "react-barcode-reader"
import {
  logScreenEnterance,
  reportFBEvent,
  setShipingPackageLoading,
  setShipingPackageOrder,
  validateShipingPackageNumber,
  readBgServiceConfiguration,
  readStationIdFromBgService,
  reportSuspiciousBrokenLockers,
  clearShipingPackage,
  clearValidationFields,
} from "../../../../../actions"
import { providerManager } from "../../../../../providers/providerManager"
import ErrorInfoModal from "../../../../Modals/ErrorInfoModal"
import { FixedFooter, FixedHeader } from "../../../../reduxified"
import OrangeLocker from "../../../../../assets/images/locker.png"
import BarcodeRectangle from "../../../../../assets/images/barcode_rectangle.png"
import BarcodeRounded from "../../../../../assets/images/barcode_rounded.png"
import ExelotBarcodeFail from "../../../../../assets/images/exelot_barcode_fail.png"
import KeyboardIcon from "../../../../../assets/svg/keyboard_icon.svg"

import ExelotLocker from "../../../../../assets/images/exelot_locker.png"
import ExelotBarcode from "../../../../../assets/images/exelot_barcode.png"
import ExelotBarcodeIcon from "../../../../../assets/svg/exelot_barcode_icon.svg"

import "./style.scss"
import { WhiteButton } from "../../../../common"
import ErrorInfoModalWithIconText from "../../../../Modals/ErrorInfoModalWithIconText"
import LoaderModal from "../../../../Modals/LoaderModal"

class ScanBarcodeOnWaybill extends Component {
  constructor(props) {
    super(props)

    this.INIT_STATE = {
      showScanError: false,
      showErrorModal: false,
      showBaseErrorModal: false,
      serviceName: "shipingPackage",
      showNotValidBarcodeErrorModal: false,
      showNotAllowedStationErrorModal: false,
      packageNumber: null,
    }

    this.state = this.INIT_STATE
    this.onGetBarcode = this.onGetBarcode.bind(this)
    this.handleScanError = this.handleScanError.bind(this)
  }

  componentDidMount() {
    this.props.clearValidationFields()
    this.setState(this.INIT_STATE)
    this.props.logScreenEnterance(
      "SCAN_BARCODE_ON_WAYBILL_SCREEN",
      this.props.history.location.state
    )
    const { stationId } = this.props
    const from = this.props.history.location.state
    this.setState({ serviceName: this.props.history.location.state })

    if (
      stationId === null ||
      stationId < 0 ||
      stationId === undefined ||
      from === ""
    ) {
      this.props.reportFBEvent(
        "ScanBarcodeOnWaybillScreen",
        "No stationId. Redirect to homepage."
      )
      this.props.history.push("/")
    }
    this.props.clearShipingPackage()
  }

  componentWillReceiveProps(newProps) {
    const {
      errTitle,
      errText,
      stationId,
      isError,
      order,
      isPackageNumberValid,
      isPackageNumberExist,
      isStationAllowed,
      isLoading,
      history,
    } = newProps
    this.setState({ showBaseErrorModal: isError })
    const { serviceName } = this.state

    //if (!order || !order.mobilePhone || order.mobilePhone.length < 10) {
    //TODO MODAL NO USER DETAILS ????????????????????????????????????
    // history.push(
    //  "/ShipingPackageScreen/SenderDetails",
    //  this.props.history.location.state
    //)
    //}

    if (
      isPackageNumberExist ||
      (!isPackageNumberValid && !isLoading && this.state.packageNumber)
    ) {
      this.setState({ showNotValidBarcodeErrorModal: true })
    }

    if (
      !isPackageNumberExist &&
      isPackageNumberValid &&
      !isLoading &&
      this.state.packageNumber &&
      !isStationAllowed
    ) {
      this.setState({ showNotAllowedStationErrorModal: true })
    }

    if (
      isPackageNumberValid &&
      !isPackageNumberExist &&
      !isLoading &&
      this.state.packageNumber &&
      isStationAllowed
    ) {
      order.packageNumber = this.state.packageNumber
      this.props.setShipingPackageOrder(order)
      //history.push("/ShipingPackageScreen/SelectLocker", serviceName)
      history.push("/ShipingPackageScreen/SenderDetails", serviceName)
    }

    const { errTitle: oldErrTitle, errText: oldErrText } = this.props

    if (oldErrTitle !== errTitle || oldErrText !== errText) {
      this.setState({
        showErrorModal: true,
      })
    }

    if (!stationId || stationId === null || stationId < 0) {
      this.props.reportFBEvent(
        "ScanBarcodeOnWaybillScreen",
        "No stationId. Redirect to homepage."
      )
      this.props.history.push("/")
    }
  }

  onGetBarcode(barcode) {
    if (this.props.isLoading) {
      return
    }
    const { serviceName } = this.state
    this.setState({ packageNumber: null })
    const isPackageBarcode = providerManager.validatePackageBarcode(
      serviceName,
      barcode
    )

    if (!isPackageBarcode) {
      this.handleScanError(barcode)
      return
    }

    this.setState({
      showScanError: false,
      showErrorModal: false,
      packageNumber: barcode,
    })
    const orderType = providerManager.orderType(serviceName)

    this.props.setShipingPackageLoading()

    this.props.validateShipingPackageNumber(
      barcode,
      orderType,
      this.props.stationNumber
    )
  }

  handleScanError(barcode) {
    const { serviceName } = this.state
    this.props.reportFBEvent(
      "ScanBarcodeOnWaybillScreen",
      "Error scan for " + serviceName + " barcode:" + barcode
    )

    this.setState({ showScanError: true })
  }

  render() {
    const {
      showScanError,
      showErrorModal,
      showBaseErrorModal,
      serviceName,
      showNotValidBarcodeErrorModal,
      showNotAllowedStationErrorModal,
    } = this.state

    const {
      errTitle,
      errText,
      dirRtl,
      close_text,
      login_laundry,
      base_error_title,
      base_error_text,
      history,
      scan_barcode_error_title,
      scan_barcode_error_text1,
      scan_barcode_error_text2,
      scan_barcode_error_info_text,
      scan_barcode_error_info_text_exelot,
      additional_scan_try,
      to_manual_input,
      isLoading,
      please_wait,
      shiping_package_barcode_scan_title,
      shiping_package_barcode_scan_title_hfd,
      shiping_package_barcode_scan_text,
      exelot_shiping_package_barcode_scan_text,
      not_valid_barcode_error_title,
      not_valid_barcode_error_text,
      isPackageNumberExist,
      barcode_exist_error_title,
      barcode_exist_error_text,
      not_allowed_station_title,
      not_allowed_station_error_text,
      exit_btn_text,
    } = this.props
    console.log("serviceName: " + serviceName)

    const scan_title_text =
      serviceName === "hfd"
        ? shiping_package_barcode_scan_title_hfd
        : shiping_package_barcode_scan_title
    console.log(scan_title_text)
    const barcodeMinLength = providerManager.barcodeMinLength(serviceName)
    return (
      <div className={"ls-wrapper shiping-barcode-scan"}>
        <FixedHeader
          title={login_laundry}
          subTitle=""
          buttonsFlag="scan_waybill"
        />

        <div className="shiping-barcode-scan-container ">
          <div className="title-text">
            <h1>{scan_title_text}</h1>
          </div>

          {providerManager.renderLogo(serviceName)}

          {serviceName === "dhl" && (
            <div className="barcode-reader-info dhl" dir="rtl">
              <img
                className="locker-icon"
                src={OrangeLocker}
                alt="DONE Locker"
              />
              <img
                className="rounded-barcode-icon"
                src={BarcodeRounded}
                alt="DHL Barcode"
              />
              <img
                className="rectangle-barcode-icon"
                src={BarcodeRectangle}
                alt="Rectangle Barcode"
              />
              <div className="info-text">
                {shiping_package_barcode_scan_text}
              </div>
            </div>
          )}
          {serviceName === "exelot" && (
            <div className="barcode-reader-info exelot" dir="rtl">
              <img
                className="exelot-barcode"
                src={ExelotBarcode}
                alt="Exelot Barcode"
              />{" "}
              <img
                className="exelot-barcode-icon"
                src={ExelotBarcodeIcon}
                alt="Exelot Barcode Icon"
              />
              <img
                className="exelot-locker-icon"
                src={ExelotLocker}
                alt="DONE Locker"
              />
              <div className="info-text">
                {exelot_shiping_package_barcode_scan_text}
              </div>
            </div>
          )}
          {serviceName === "hfd" && (
            <div className="barcode-reader-info exelot" dir="rtl">
              <img
                className="exelot-barcode"
                src={ExelotBarcode}
                alt="Exelot Barcode"
              />{" "}
              <img
                className="exelot-barcode-icon"
                src={ExelotBarcodeIcon}
                alt="Exelot Barcode Icon"
              />
              <img
                className="exelot-locker-icon"
                src={ExelotLocker}
                alt="DONE Locker"
              />
              <div className="info-text">
                {exelot_shiping_package_barcode_scan_text}
              </div>
            </div>
          )}
          <div>
            <WhiteButton
              extraClass={
                "to-manual-barcode-input-button " + (dirRtl ? "rtl" : "ltr")
              }
              onClick={() => {
                this.props.history.push(
                  "/ShipingPackageScreen/TypeBarcodeManual",
                  serviceName
                )
              }}
            >
              <div>
                {to_manual_input.split("\n").map((str) => (
                  <p key={str}>{str}</p>
                ))}
              </div>
              <div className="manual-input-icon">
                <img src={KeyboardIcon} alt="Keyboard Icon" />
              </div>
            </WhiteButton>
          </div>
          <BarcodeReader
            minLength={barcodeMinLength}
            onError={(e) => this.handleScanError(e)}
            onScan={(e) => this.onGetBarcode(e)}
          />
        </div>

        <LoaderModal show={isLoading} title={please_wait} />
        {serviceName === "dhl" && (
          <ErrorInfoModalWithIconText
            dirRtl={dirRtl}
            show={(showErrorModal && errTitle && errText) || showScanError}
            title={scan_barcode_error_title}
            text1={scan_barcode_error_text1}
            text2={scan_barcode_error_text2}
            icon={BarcodeRounded}
            iconInfoText={scan_barcode_error_info_text}
            closeTrnsl={additional_scan_try}
            onModalCloseClick={() => {
              this.setState({ showErrorModal: false, showScanError: false })
              this.props.clearValidationFields()
            }}
          />
        )}
        {serviceName === "exelot" && (
          <ErrorInfoModalWithIconText
            dirRtl={dirRtl}
            show={(showErrorModal && errTitle && errText) || showScanError}
            title={scan_barcode_error_title}
            text1={scan_barcode_error_text1}
            text2={scan_barcode_error_text2}
            icon={ExelotBarcodeFail}
            iconInfoText={scan_barcode_error_info_text_exelot}
            closeTrnsl={additional_scan_try}
            onModalCloseClick={() => {
              this.setState({ showErrorModal: false, showScanError: false })
              this.props.clearValidationFields()
            }}
          />
        )}

        <ErrorInfoModal
          dirRtl={dirRtl}
          show={showNotValidBarcodeErrorModal}
          title={
            isPackageNumberExist
              ? barcode_exist_error_title
              : not_valid_barcode_error_title
          }
          text={
            isPackageNumberExist
              ? barcode_exist_error_text
              : not_valid_barcode_error_text
          }
          onModalCloseClick={() => {
            this.setState({ showNotValidBarcodeErrorModal: false })
            //this.props.history.push(
            //  "/ShipingPackageScreen/SenderDetails",
            //  this.props.history.location.state
            //)
          }}
          closeTrnsl={additional_scan_try}
          className="shiping-barcode-scan"
        />

        <ErrorInfoModal
          dirRtl={dirRtl}
          show={showNotAllowedStationErrorModal}
          title={not_allowed_station_title}
          text={not_allowed_station_error_text}
          onModalCloseClick={() => {
            this.setState({ showNotAllowedStationErrorModal: false })
            this.props.history.push("/")
          }}
          closeTrnsl={exit_btn_text}
          className="shiping-barcode-scan"
        />

        <ErrorInfoModal
          dirRtl={dirRtl}
          show={showBaseErrorModal}
          title={base_error_title}
          text={base_error_text}
          onModalCloseClick={() => {
            this.setState({ showBaseErrorModal: false })
            //this.props.history.push(
            //  "/ShipingPackageScreen/SenderDetails",
            //  this.props.history.location.state
            //)
          }}
          closeTrnsl={close_text}
          className="shiping-barcode-scan"
        />

        <FixedFooter history={history} />
      </div>
    )
  }
}

const mapStateToProps = ({
  userLogin,
  bgService,
  translate,
  shipingPackage,
}) => {
  const { stationId, stationNumber } = bgService
  const {
    order,
    isPackageNumberValid,
    isPackageNumberExist,
    isLoading,
    isStationAllowed,
  } = shipingPackage
  const {
    authenticated,
    errTitle,
    errText,
    isLoadingRequest,
    userId,
    isError,
  } = userLogin
  const {
    dirRtl,
    langName,
    close_text,
    login_laundry,
    enter,
    base_error_title,
    base_error_text,
    scan_barcode_error_title,
    scan_barcode_error_text1,
    scan_barcode_error_text2,
    scan_barcode_error_info_text,
    scan_barcode_error_info_text_exelot,
    additional_scan_try,
    to_manual_input,
    please_wait,
    shiping_package_barcode_scan_title,
    shiping_package_barcode_scan_title_hfd,
    shiping_package_barcode_scan_text,
    exelot_shiping_package_barcode_scan_text,
    not_valid_barcode_error_title,
    not_valid_barcode_error_text,
    barcode_exist_error_title,
    barcode_exist_error_text,
    not_allowed_station_title,
    not_allowed_station_error_text,
    exit_btn_text,
  } = translate

  return {
    authenticated,
    errTitle: translate[errTitle],
    errText: translate[errText],
    isLoadingRequest,
    userId,
    stationId,
    stationNumber,
    isError,
    enter,
    dirRtl,
    langName,
    close_text,
    login_laundry,
    base_error_title,
    base_error_text,
    scan_barcode_error_title,
    scan_barcode_error_text1,
    scan_barcode_error_text2,
    scan_barcode_error_info_text,
    scan_barcode_error_info_text_exelot,
    additional_scan_try,
    to_manual_input,
    please_wait,
    shiping_package_barcode_scan_title,
    shiping_package_barcode_scan_title_hfd,
    shiping_package_barcode_scan_text,
    exelot_shiping_package_barcode_scan_text,
    not_valid_barcode_error_title,
    not_valid_barcode_error_text,
    barcode_exist_error_title,
    barcode_exist_error_text,
    order,
    isPackageNumberValid,
    isLoading,
    isPackageNumberExist,
    isStationAllowed,
    not_allowed_station_title,
    not_allowed_station_error_text,
    exit_btn_text,
  }
}

export default connect(mapStateToProps, {
  validateShipingPackageNumber,
  setShipingPackageLoading,
  setShipingPackageOrder,
  readStationIdFromBgService,
  readBgServiceConfiguration,
  reportSuspiciousBrokenLockers,
  logScreenEnterance,
  reportFBEvent,
  clearShipingPackage,
  clearValidationFields,
})(ScanBarcodeOnWaybill)
