import DhlLogo from "../assets/svg/dhl_logo_label.svg"

let dhl = {}

dhl.orderType = 6
dhl.accountType = 2

dhl.renderLogo = () => {
  return (
    <img className="logo-service-icon dhl" src={DhlLogo} alt={"logo dhl"} />
  )
}

dhl.formatWaybill = (value) => {
  let v = value.replace(/\s+/g, "").replace(/[^0-9]/gi, "")

  const matches = v.match(/\d{3,10}/g)
  let match = (matches && matches[0]) || ""

  let parts = []
  let i = 0
  let len
  for (i = 0, len = match.length; i < len; i += i === 0 ? 2 : 4) {
    parts.push(match.substring(i, i + (i === 0 ? 2 : 4)))
  }

  if (parts.length) {
    return parts.join(" ")
  } else {
    return value
  }
}
dhl.barcodeMinLength = 10
dhl.validatePackageBarcode = (barcode) => {
  if (!barcode || barcode.length < 10 || barcode.length > 10) {
    return false
  }
  const barcodeRegex = /^([0-z]{10}$)/
  const res = barcodeRegex.test(barcode)
  return res
}

export const dhlProvider = dhl
