import {
  RESET_ALL_REDUCERS,
  SET_FLOW_ACTIVE_PAGE,
  SET_ACTIVE_FLOW,
} from "../actions/types"

const INIT_STATE = {
  //activeFlow: "laundry",
  flowActivePage: null,
  /*flow:{
    landry: {},
    lockerToLocker:{
      step1_check_user: true,
      step2_select_station: false,
      step3_fill_addressee: false,
      step4_lockers_cells: false,
      step5_LockerDeliveryMessage: false,
    }
  },*/
}

const FlowReducer = (state = INIT_STATE, action) => {
  switch (action.type) {
    //case SET_ACTIVE_FLOW:
    //  return {
    //    ...state,
    //    activeFlow: action.payload,
    //  }
    case SET_FLOW_ACTIVE_PAGE:
      return {
        ...state,
        flowActivePage: action.payload,
      }
    case RESET_ALL_REDUCERS:
      return {
        ...INIT_STATE,
        //activeFlow: null,
        flowActivePage: null,
      }
    default:
      return state
  }
}

export default FlowReducer
