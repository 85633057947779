import {
  ON_ERROR_NETWORK,
  ON_ERROR_TIMEOUT,
  ON_CLEAR_ERROR_NETWORK,
  ON_USER_SIGN_OUT,
  RESET_ALL_REDUCERS,
  BG_SERVICE_ALIVE_SUCCESS,
  BG_SERVICE_ALIVE_FAIL,
  ALIVE_API_SUCCESS,
  ALIVE_API_FAIL,
  READ_STATION_ID_FROM_BG_SERVICE_FAIL,
  FETCH_STATION_SERVICE_TYPES_FAIL,
  ON_GET_ORDER_TYPES_FAILURE,
  FETCH_STATION_SERVICE_TYPES_SUCCESS,
  READ_STATION_ID_FROM_BG_SERVICE_SUCCESS,
  ON_GET_ORDER_TYPES_SUCCESS,
  GET_STATION_NUMBER,
  FETCH_ALL_LOCKERS_STATUSES_SUCCESS,
  FETCH_ALL_LOCKERS_LAYOUT_SUCCESS,
} from "../actions/types"

const INIT_STATE = {
  showNetworkErrorMessage: false,
  showTimeOutErrorMessage: false,
  serviceInternetError: false,
  reactInternetError: false,
}

const ErrorMessageReducer = (state = INIT_STATE, action) => {
  switch (action.type) {
    case ON_ERROR_NETWORK:
      //console.log("network: reactInternet  Error!!!!" + action.payload?.status)
      return {
        ...state,
        showNetworkErrorMessage: true,
        reactInternetError: true,
      }
    case ON_ERROR_TIMEOUT:
      return {
        ...state,
        showTimeOutErrorMessage: true,
        showNetworkErrorMessage: true,
      }
    case ON_CLEAR_ERROR_NETWORK:
      return {
        ...state,
        showNetworkErrorMessage: false,
        showTimeOutErrorMessage: false,
        reactInternetError: false,
        serviceInternetError: false,
      }
    case READ_STATION_ID_FROM_BG_SERVICE_SUCCESS:
    case BG_SERVICE_ALIVE_SUCCESS:
      //console.log("network: serviceInternet OK!!!!" + action.payload?.status)
      return {
        ...state,
        serviceInternetError: false, //action.payload?.status == 200 ? false : true,
      }
    case READ_STATION_ID_FROM_BG_SERVICE_FAIL:
    case BG_SERVICE_ALIVE_FAIL:
      //console.log("network: serviceInternet Error!!!")
      return {
        ...state,
        serviceInternetError: true,
      }
    case FETCH_ALL_LOCKERS_LAYOUT_SUCCESS:
    case FETCH_ALL_LOCKERS_STATUSES_SUCCESS:
    case ON_GET_ORDER_TYPES_SUCCESS:
    case GET_STATION_NUMBER:
    case FETCH_STATION_SERVICE_TYPES_SUCCESS:
    case ALIVE_API_SUCCESS:
      //console.log("network: reactInternet OK!!!!" + action.payload?.status)
      return {
        ...state,
        reactInternetError: false,
      }
    case ON_GET_ORDER_TYPES_FAILURE:
    case FETCH_STATION_SERVICE_TYPES_FAIL:
    case ALIVE_API_FAIL:
      console.log("network: reactInternet error!!!!" + action.payload?.status)
      return {
        ...state,
        reactInternetError: true,
      }

    case ON_USER_SIGN_OUT:
    case RESET_ALL_REDUCERS:
      return {
        ...INIT_STATE,
      }

    default:
      return state
  }
}

export default ErrorMessageReducer
