import React, { Component } from "react"
import { connect } from "react-redux"

import DelayImg from "../../../assets/svg/noun-delay.svg"

import "./style.scss"

class DueSituationMessage extends Component {
  render() {
    const { due_situation_text, due_situation_days_text, dirRtl, langName } =
      this.props

    const dir = dirRtl ? "rtl" : "ltr"

    return (
      <div
        className={"due-situation-message " + dir}
        dir={dir}
        onClick={(e) => this.props.onClick(e)}
      >
        <div className="white-part">
          <div className="app-icons">
            <img
              className="delay-icon"
              src={DelayImg}
              alt="delay due situation"
            />
          </div>
          <div className={"app-info " + langName}>
            <div className="app-title">
              {due_situation_text}{" "}
              <span className="days-text">{due_situation_days_text}</span>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

const mapStateToProps = ({ translate, bgService }) => {
  const { stationNumber } = bgService
  const {
    dirRtl,
    langName,
    due_situation_text,
    due_situation_days_text_5,
    due_situation_days_text_8,
  } = translate

  const stations8 = [
    1180, 1196, 1144, 1242, 1170, 2072, 2073, 2069, 1233, 2070, 1160, 1163,
    1241, 1117, 1125, 2043, 1190,
  ]
  let due_situation_days_text = due_situation_days_text_5

  if (stations8.indexOf(stationNumber - 0) > -1) {
    due_situation_days_text = due_situation_days_text_8
  }

  return {
    dirRtl,
    langName,
    due_situation_text,
    due_situation_days_text,
  }
}

export default connect(mapStateToProps, {})(DueSituationMessage)
