import axios from "axios"
import { isProdEnv } from "../utils"
import { getOrderItems } from "../utils/OrderConvert"
// import { AppVersion } from "../utils/Env"

const api = {}
const AppVersion = require("../../package.json").version
let BASE_URL

if (isProdEnv()) {
  BASE_URL = "https://k-api.cleanbox.co.il"
} else {
  BASE_URL = "https://k-api-test.cleanbox.co.il"
  //BASE_URL = "https://localhost:5002" //Please, leave for developer
}

api.signIn = `${BASE_URL}/api/Registration/authenticate`
api.driverSignIn = (stationId) =>
  `${BASE_URL}/api/Registration/drivers/authenticate/${stationId}`

api.signInBody = (mobile, pwd) => {
  return {
    mobilePhone: mobile,
    password: pwd,
  }
}
api.isOldUser = (userId) => {
  return `${BASE_URL}/api/Users/is_had_order_before/${userId}`
}

api.checkVerifyCode = `${BASE_URL}/api/registration/verify_code`
api.sendVerifyCode = (mobileNumber) => {
  return `${BASE_URL}/api/registration/send_verify_code/${mobileNumber}`
}
api.isUsedBarcode = (barcode, orderType) => {
  return `${BASE_URL}/api/orders/is_order_barcode_exist/?orderBarcode=${barcode}&orderType=${orderType}`
}

api.recreateUserPassword = (mobile) => {
  return `${BASE_URL}/api/users/restore_password?mobilePhone=${mobile}`
}

api.updateUserPasswordPath = `${BASE_URL}/api/users/update_password/`
api.updateUserPassword = (userId) =>
  `${BASE_URL}/api/users/update_password/${userId}`

api.updateUserPasswordBody = (pwd) => {
  return {
    password: pwd,
  }
}

api.isUserMobileAlreadyExists = (mobile) => {
  return `${BASE_URL}/api/registration/is_exist/${mobile}`
}

api.completeUserDetails = (userId) =>
  `${BASE_URL}/api/registration/complete_details/${userId}`

api.registerNewUser = `${BASE_URL}/api/users/register`
api.registerNewUserBody = (mobile, fName, lName, email, pwd, stationId) => {
  return {
    mobilePhone: mobile,
    firstName: fName,
    lastName: lName,
    email: email,
    password: pwd,
    stationId,
  }
}

api.getAllStationsData = `${BASE_URL}/api/branches/get_branches_map`

api.createNewOrder = `${BASE_URL}/api/orders/create_order`

api.createNewOrderBody = (
  lockerId,
  customerId,
  stationId,
  items,
  couponId,
  orderType,
  orderId,
  optionalWrongDoor
) => {
  const orderItems = getOrderItems(items)

  return {
    locationId: lockerId,
    customerId,
    stationId,
    orderItems,
    couponId,
    orderType,
    id: orderId,
    optionalWrongDoor,
  }
}

api.createNewDynamicProductOrderBody = (customerId, stationId, items) => {
  const orderItems = items.map(
    ({
      icon: processName,
      amount: quantity,
      comment: notes,
      productName: itemName,
      id,
      price,
    }) => {
      return {
        processName,
        quantity,
        notes,
        itemName,
        priceItemId: id,
        itemPrice: price,
        // PRODUCT ID , PRODUCT NAME, QUANTITY, PRODUCT PRICE
      }
    }
  )

  return {
    locationId: "00000000-0000-0000-0000-000000000000",
    customerId,
    stationId,
    orderType: 3,
    orderItems,
  }
}

api.readyOrders = (stationId) => {
  return `${BASE_URL}/api/orders/get_ready/${stationId}`
}

api.getAllUserActiveOrders = (userId, stationId) =>
  `${BASE_URL}/api/Orders/get_active_user_koisk_orders?stationId=${stationId}&userId=${userId}`

//TODO REMOVE api.getAllUserActiveOrders = (userId, stationId) => `${BASE_URL}/api/Orders/get_active_user_orders?stationId=${stationId}&userId=${userId}`

/*TODO REMOVE api.getAllUserActiveOrdersResponse = (response) => {
  const outputArr = response.map(
    ({
      id,
      orderNumber,
      orderDate,
      orderTotalPrice,
      orderStatus,
      locationId,
      orderItems: itms,
      lockerNumber: lockerNum,
      isPayed,
    }) => {
      const orderItems = itms.map(
        ({ itemNumber, quantity: amount, processName, notes }) => {
          return { amount, title: processName }
        }
      )
      return {
        orderId: id,
        orderNumber,
        orderDate,
        orderTotalPrice,
        orderStatus,
        locationId,
        orderItems,
        lockerNum,
        isOrderPayed: isPayed,
      }
    }
  )

  return outputArr
}*/

api.setLockerOccupied = (stationId, lockerNum, isOccupied, lockerStatus) =>
  `${BASE_URL}/api/Locker/set_locker_occupied/${stationId}?isOccupied=${isOccupied}&lockerNum=${lockerNum}&lockerStatus=${lockerStatus}`

api.setLockerOccupiedDHL = (
  stationId,
  lockerNum,
  isOccupied,
  packageNumber,
  lockerStatus
) =>
  `${BASE_URL}/api/Locker/set_locker_occupied_order/${stationId}?lockerNum=${lockerNum}&isOccupied=${isOccupied}&packageNumber=${packageNumber}&lockerStatus=${lockerStatus}`

api.getLockersStatusesByStationId = (stationId) =>
  `${BASE_URL}/api/stations/lockers_status/${stationId}`

api.getLockersLayoutByStationId = (stationId) =>
  `${BASE_URL}/api/stations/get_layout/${stationId}`

api.setLockersLayoutByStationId = (stationId) =>
  `${BASE_URL}/api/stations/set_layout/${stationId}`

// CALLED WHEN THE DRIVER TAKES THE DIRTY LAUNDRY OUT OF THE LOCKER..
api.onDriverCollectsOrder = (orderId, lockerId, orderBarcode) =>
  `${BASE_URL}/api/orders/take_from_locker/${orderId}?lockerId=${lockerId}&orderBarcode=${orderBarcode}`

// CALLED WHEN THE DRIVER PUTS BACK THE CLEAN LAUNDRY TO THE LOCKER..
api.onDriverReturnesCleanOrder = (orderId, lockerId) =>
  `${BASE_URL}/api/orders/put_to_locker/${orderId}?lockerId=${lockerId}`

api.onDriverReturnesCleanOrderCancel = (orderId, lockerId) =>
  `${BASE_URL}/api/orders/put_to_locker_cancel/${orderId}?lockerId=${lockerId}`

api.returnPackageOrder = (orderId, lockerId) =>
  `${BASE_URL}/api/orders/put_to_locker_return/${orderId}?lockerId=${lockerId}`

api.markOrderAsPayed = (orderId) => `${BASE_URL}/api/orders/pay/${orderId}`

api.markOrderAsPayedByNAYAX = (orderId) =>
  `${BASE_URL}/api/orders/payByNayax/${orderId}`

api.saveUserCreditTokenInServerUrl = (userId) =>
  `${BASE_URL}/api/users/update_card/${userId}`

api.saveUserCreditTokenInServerBody = (token) => {
  return {
    creditCardToken: token,
  }
}

// CALLED WHEN THE USER HAS PAYED AND TOOK HIS CLEAN LAUNDRY FROM THE LOCKER..
api.onCloseOrder = (orderId, lockerId) =>
  `${BASE_URL}/api/orders/close_order/${orderId}?lockerId=${lockerId}`

api.getStationPriceList = (stationId, lang) =>
  `${BASE_URL}/api/prices/get_price?stationId=${stationId}&lang=${lang}`

api.getAllDynamicProducts = (stationId, lang) =>
  `${BASE_URL}/api/prices/get_price?stationId=${stationId}&lang=${lang}&productType=2`

api.getDynamicProductsResponse = (resp) => {
  const result = resp.map((itm) => {
    return { ...itm, imgUrl: api.getImageFromImgId(itm.imageId) }
  })

  return result
}
api.getAliveAPI = `${BASE_URL}/alive`
api.pingAPI = (stationId) =>
  `${BASE_URL}/api/Stations/alive/${stationId}?version=${AppVersion}`
api.getImageFromImgId = (imgId) => `${BASE_URL}/api/images/${imgId}`

api.reportSuspiciousBrokenLockers = (stationId) =>
  `${BASE_URL}/api/stations/lockers_report/${stationId}`

api.getServiceTypesByStationId = (stationId) =>
  `${BASE_URL}/api/stations/service_types/${stationId}`

api.getOrderByDeliveryCode = `${BASE_URL}/api/delivery/delivery_order`
api.getReturnOrderByDeliveryCode = `${BASE_URL}/api/delivery/delivery_return_order`

api.getOrderByDeliveryCodeBody = (stationId, deliveryCode) => {
  return {
    stationId,
    deliveryCode,
  }
}

api.getReturnOrderByDeliveryCodeBody = (
  stationId,
  deliveryCode,
  orderType,
  orderStatus
) => {
  return {
    stationId,
    deliveryCode,
    orderType,
    orderStatus,
  }
}

api.getOrderByDeliveryCodeResponse = (response) => {
  const order = (order) => {
    const {
      id,
      orderNumber,
      orderDate,
      orderTotalPrice,
      orderStatus,
      locationId,
      lockerNumber,
      isPayed,
      isMonthlyPayment,
      orderType,
      discount,
      discountType,
      couponId,
    } = order
    const orderItems = order.orderItems.map(
      ({ itemNumber, quantity: amount, processName, itemPrice, notes }) => {
        return { amount, title: processName, price: itemPrice }
      }
    )
    return {
      orderId: id,
      orderNumber,
      orderDate,
      orderTotalPrice,
      orderStatus,
      locationId,
      orderItems: orderItems,
      lockerNum: lockerNumber,
      isOrderPayed: isPayed,
      isMonthlyPayment,
      orderType,
      discount,
      discountType,
      couponId,
    }
  }

  response.order = order(response.order)
  axios.post("http://localhost:3001/logToStationLogger", {
    csvLine: `RESPONSE getOrderByDeliveryCodeResponse, ${response}`, // clear after testing!!!
  })

  return response
}

api.getOrderByDeliveryCodeError = (err) => {
  const { response } = err
  let status = ""
  if (response !== undefined) {
    status = response.status
  }

  return {
    status: status,
    response: response,
    showErrorModal: true,
  }
}

api.restoreDeliveryCode = `${BASE_URL}/api/delivery/restore_delivery_code`
api.restoreDeliveryCodeBody = (stationId, mobilePhone) => {
  return {
    stationId: stationId,
    mobilePhone: mobilePhone,
  }
}

api.fetchCouponByName = (couponName, orderType) =>
  `${BASE_URL}/api/Coupon/GetCouponByName/${couponName}?orderType=${orderType}`

api.getOrderTypes = `${BASE_URL}/api/orders/get_types`

api.getLockersForRent = (stationId) =>
  `${BASE_URL}/api/Stations/rent_lockers_status/${stationId}`

api.getLockersForShiping = (stationId) =>
  `${BASE_URL}/api/Stations/shipping_lockers_status/${stationId}`

api.isHadOrderBefore = (userId) =>
  `${BASE_URL}/api/users/is_had_order_before/${userId}`

api.getAvailableLockersStatus = (stationId) =>
  `${BASE_URL}/api/Stations/available_lockers_status/${stationId}`

api.getStationsList = (lang) =>
  `${BASE_URL}/api/Stations/get_locker_delivery_stations/${lang}`

api.getOrderById = (orderId) =>
  `${BASE_URL}/api/orders/get_order_by_id/${orderId}`

api.confirmCreateOrder = (orderId) =>
  `${BASE_URL}/api/orders/confirm_create_order/${orderId}`

api.getOrdersRequests = (stationId, userId, orderType) =>
  `${BASE_URL}/api/Orders/get_orders_requests/${stationId}?userId=${userId}&orderType=${orderType}`

api.addCouponByNameToOrder = (orderId, couponName) =>
  `${BASE_URL}/api/Orders/add_coupon_by_name_to_order/${orderId}?couponName=${couponName}`

api.validateShipingPackageNumber = (packageNumber, orderType, stationNumber) =>
  `${BASE_URL}/api/orders/validate_return_order/${packageNumber}/${orderType}/${stationNumber}`

api.createShipingPackageOrder = `${BASE_URL}/api/orders/returns`

api.reportEmptyLocker = (orderId, lockerId) =>
  `${BASE_URL}/api/orders/report_empty_locker/${orderId}/${lockerId}`

api.requestRegisterVerifyCode = `${BASE_URL}/api/registration/send_register_verify_code`
api.registerWithVerifyCode = `${BASE_URL}/api/registration/register_with_verify_code`

api.getTasksByStation = (stationId) =>
  `${BASE_URL}/api/stationTask/getStationTasksByStation/${stationId}`
api.TaskAccomplishedReport = (stationTaskId) =>
  `${BASE_URL}/api/stationTask/taskAccomplishedReport/${stationTaskId}`

export default api
