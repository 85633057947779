import {
  ON_READY_ORDERS_SUCCESS,
  ON_READY_ORDERS_FAIL,
  SET_NEW_CREATED_ORDER_ITEMS,
  SET_NEW_CREATED_ORDER,
  ON_GET_ORDER_FOR_PAY_SUCCESS,
  ON_GET_ORDER_FOR_PAY_FAIL,
  ON_CONFIRM_CREATE_ORDER_SUCCESS,
  ON_CLEAR_ORDER_FOR_CONFIRM_CREATE,
  RESET_ALL_REDUCERS,
  ON_REQUEST_CREATE_NEW_ORDER,
  ON_CREATE_NEW_ORDER_SUCCESS,
  ON_CREATE_NEW_ORDER_FAIL,
  ON_CREATE_NEW_ORDER_CLEAR,
} from "../actions/types"

const INIT_STATE = {
  readyOrdersArr: [],
  newCreatedOrderItems: null,
  newCreatedOrderCoupon: null,
  newCreatedOrder: {
    orderId: null,
    locationId: null,
    items: [],
    coupon: null,
    firstName: "",
    lastName: "",
    mobilePhone: "",
    sourceCustomerId: null,
    stationIdSource: null,
    stationIdTarget: null,
    // orderType: null  //LockerRent:4, LockerToLockerDelivery:5, Laundry:1,
  },
  orderRequestSent: false,
  orderCreatedSuccessfully: false,
  orderForConfirmCreate: null,
  isLoading: false,
}

const ReadyOrdersReducer = (state = INIT_STATE, action) => {
  switch (action.type) {
    case ON_READY_ORDERS_SUCCESS:
      return {
        ...state,
        readyOrdersArr: action.payload,
      }
    case ON_READY_ORDERS_FAIL:
      return {
        ...state,
      }
    case SET_NEW_CREATED_ORDER_ITEMS:
      const { items, couponId } = action.payload
      return {
        ...state,
        newCreatedOrderItems: items,
        newCreatedOrderCoupon: couponId,
        newCreatedOrder: {
          ...state.newCreatedOrder,
          items,
          couponId,
        },
      }
    case SET_NEW_CREATED_ORDER:
      return {
        ...state,
        newCreatedOrder: {
          ...state.newCreatedOrder,
          ...action.payload,
        },
      }
    case ON_GET_ORDER_FOR_PAY_SUCCESS:
      return {
        ...state,
        orderForConfirmCreate: action.payload,
      }
    case ON_REQUEST_CREATE_NEW_ORDER:
      return {
        ...state,
        isLoading: true,
        orderRequestSent: action.payload,
      }

    case ON_CREATE_NEW_ORDER_SUCCESS:
      return {
        ...state,
        isLoading: false,
        orderCreatedSuccessfully: true,
      }
    case ON_CREATE_NEW_ORDER_FAIL:
      return {
        ...state,
        isLoading: false,
        orderCreatedSuccessfully: false,
      }
    case ON_CREATE_NEW_ORDER_CLEAR:
      return {
        ...state,
        orderRequestSent: false,
        orderCreatedSuccessfully: false,
        isLoading: false,
      }

    case ON_CLEAR_ORDER_FOR_CONFIRM_CREATE:
    case ON_GET_ORDER_FOR_PAY_FAIL:
    case ON_CONFIRM_CREATE_ORDER_SUCCESS:
      return {
        ...state,
        orderForConfirmCreate: null,
      }
    case RESET_ALL_REDUCERS:
      return {
        ...INIT_STATE,
      }
    default:
      return state
  }
}

export default ReadyOrdersReducer
