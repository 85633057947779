import {
  READ_STATION_ID_FROM_BG_SERVICE_SUCCESS,
  RESET_ALL_REDUCERS,
  READ_STATION_ID_FROM_BG_SERVICE_FAIL,
  GET_STATION_NUMBER,
} from "../actions/types"

const INIT_STATE = {
  stationId: null,
  stationNumber: null,
}

const BgServiceReducer = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_STATION_NUMBER:
      return {
        ...state,
        stationNumber: action.payload,
      }
    case READ_STATION_ID_FROM_BG_SERVICE_SUCCESS:
      return {
        ...state,
        stationId: action.payload,
      }
    case READ_STATION_ID_FROM_BG_SERVICE_FAIL:
      return {
        ...state,
        stationId: -1,
      }
    case RESET_ALL_REDUCERS:
      return {
        ...INIT_STATE,
        stationId: state.stationId,
      }
    default:
      return state
  }
}

export default BgServiceReducer
