import React from "react"
import Modal from "react-modal"
import { OrangeButton } from "../../common"
import wardrobe from "../../../assets/images/wardrobe.svg"
import "./style.css"

const WarningInfoModal = ({
  show,
  title,
  text,
  onModalCloseClick,
  dirRtl,
  btnText,
  icon,
  moreThanOneOrder,
}) => {
  if (!show) {
    return <noscript />
  }

  let iconSrc = ""
  if (icon) {
    iconSrc = require(`../../../assets/images/${icon}.png`)
  }

  const wrapperClassName = `error-modal ${dirRtl ? "dirrtl" : "dirltr"}`
  //TODO (waiting for design)
  return (
    <Modal
      isOpen={show}
      onRequestClose={onModalCloseClick}
      className="react-modal-content"
      overlayClassName="modal-overlay"
    >
      {moreThanOneOrder ? (
        <img src={wardrobe} alt="aron" className="wardrobe" />
      ) : (
        <div className="orange-circle">
          <div className="orange-stick"></div>
          <div className="orange-dot"></div>
        </div>
      )}
      <div className="ooalo-label-extra-class" dir={dirRtl ? "rtl" : "ltr"}>
        {newlineText(title)}
      </div>
      <div
        className="ooalo-label-extra-class ooalo-label-extra-class2"
        dir={dirRtl ? "rtl" : "ltr"}
      >
        {text}
      </div>
      <OrangeButton onClick={() => onModalCloseClick()}>{btnText}</OrangeButton>
    </Modal>
  )
}

const newlineText = (text) => {
  if (text === undefined || text === null || text.length <= 0) {
    return text
  }
  return text.split("\n").map((str) => <p>{str}</p>)
}
export default WarningInfoModal
