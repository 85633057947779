import { RESET_ALL_REDUCERS } from "./types"

export const resetAllReducers = () => {
  console.log("reseting all reducres data.. :)")

  return (dispatch) => {
    dispatch({
      type: RESET_ALL_REDUCERS,
    })
  }
}
