import api from "../api"
import { axiosGetRetry, axiosPostRetry } from "../utils"
import { reportFBEvent } from "./FBEventsActions"
import { setNetworkErrorIfExist } from "./ErrorMessagesActions"

import {
  ON_REQUEST_CREATE_NEW_ORDER,
  ON_CREATE_NEW_ORDER_SUCCESS,
  ON_CREATE_NEW_ORDER_FAIL,
  ON_CREATE_NEW_ORDER_CLEAR,
  DYNAMIC_ITEMS_FETCH_SUCCESS,
} from "./types"

export const createNewOrder = (
  lockerId,
  customerId,
  stationId,
  items,
  couponId,
  orderType,
  orderId,
  optionalWrongDoor
) => {
  return (dispatch) => {
    dispatch({ type: ON_REQUEST_CREATE_NEW_ORDER, payload: true })

    axiosPostRetry(
      api.createNewOrder,
      api.createNewOrderBody(
        lockerId,
        customerId,
        stationId,
        items,
        couponId,
        orderType,
        orderId,
        optionalWrongDoor
      )
    )
      .then((res) => {
        res.data.orderId &&
          dispatch({ type: ON_CREATE_NEW_ORDER_SUCCESS, payload: true })
      })
      .then((res) => {
        dispatch(
          reportFBEvent(
            `New_Order", "New Order with ID: ${
              res && res.data.orderId
            } Has Been Created!`
          )
        )
      })
      .catch((err) => {
        dispatch({ type: ON_CREATE_NEW_ORDER_FAIL, payload: false })
        console.log(err)
        setNetworkErrorIfExist(err)
      })
  }
}

export const clearCreateNewOrder = () => {
  return (dispatch) =>
    dispatch({ type: ON_CREATE_NEW_ORDER_CLEAR, payload: false })
}

export const createNewDynamicProductOrder = (customerId, stationId, items) => {
  return (dispatch) => {
    console.log(
      api.createNewDynamicProductOrderBody(customerId, stationId, items)
    )

    axiosPostRetry(
      api.createNewOrder,
      api.createNewDynamicProductOrderBody(customerId, stationId, items)
    )
      .then((res) => {
        console.log(res.data)
        dispatch(
          reportFBEvent(
            "New_Dynamic_Order",
            "New Dynamic Item Order Has Been Created!"
          )
        )
      })
      .catch((err) => console.log(err))
  }
}

export const getAllDynamicProducts = (stationId, lang) => {
  return (dispatch) => {
    axiosGetRetry(api.getAllDynamicProducts(stationId, lang))
      .then((res) => {
        dispatch({
          type: DYNAMIC_ITEMS_FETCH_SUCCESS,
          payload: api.getDynamicProductsResponse(res.data),
        })
      })
      .catch((err) => console.log(err))
  }
}
