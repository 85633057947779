import axios from "axios"
import api from "../api"

import { ENABLE_BG_SYNC, axiosPostRetry, axiosGetRetry } from "../utils"

import {
  ON_USER_SIGN_IN_SUCCESS,
  ON_USER_SIGN_IN_FAIL,
  ON_USER_SIGN_OUT,
  ON_USER_SIGN_IN_REQUEST,
  USER_MOBILE_ALREADY_TAKEN_REQUEST,
  USER_MOBILE_ALREADY_TAKEN_TRUE,
  USER_MOBILE_ALREADY_TAKEN_FALSE,
  USER_OTP_MOBILE_ALREADY_EXIST,
  GET_IS_USER_HAD_ORDER_BEFORE_SUCCESS,
  GET_IS_USER_HAD_ORDER_BEFORE_FAIL,
  SET_CC_SHURED,
  RESET_CC_SHURED,
  ON_BASE_ERROR,
  SIGN_IN_WITH_OTP,
  ON_DRIVER_SIGN_IN_SUCCESS,
  ON_DRIVER_SIGN_IN_FAIL,
  ON_DRIVER_SIGN_IN_REQUEST,
  SIGN_IN_WITH_OTP_FAIL,
  CLEAR_ERR_STATUS,
  ON_DRIVER_SIGN_IN_NOT_ALLOWED,
  ON_ERROR_NETWORK,
} from "./types"

import { reportFBEvent } from "./FBEventsActions"
import {
  setBGErrorIfExist,
  setNetworkErrorIfExist,
} from "./ErrorMessagesActions"

export const onUserSignInClick = (mobile, pwd) => {
  return (dispatch) => {
    dispatch({
      type: ON_USER_SIGN_IN_REQUEST,
    })

    axiosPostRetry(api.signIn, api.signInBody(mobile, pwd), 7000)
      .then((res) => {
        const {
          id: userId,
          role,
          firstName,
          lastName,
          token,
          isPasswordTemporary,
          isRefillDetails,
          email,
          creditCardToken,
          isDeliveryRole,
          isShowUnlockScreen,
          isNeedAccessibility,
          isHideDriverReadyItemButton,
        } = res.data

        axios.defaults.headers.common["Authorization"] = `Bearer ${token}`
        dispatch({
          type: ON_USER_SIGN_IN_SUCCESS,
          payload: {
            userId,
            role: "Customer",
            firstName,
            lastName,
            email,
            token,
            isPasswordTemporary,
            isRefillDetails,
            creditCardToken,
            isDeliveryRole,
            isShowUnlockScreen,
            mobile,
            isNeedAccessibility,
            isHideDriverReadyItemButton,
          },
        })

        dispatch(reportFBEvent("Sign_In", "User Sign In Success"))

        if (ENABLE_BG_SYNC) {
          sendUserValidDataToLocalBgService(
            userId,
            mobile,
            firstName,
            lastName,
            email,
            pwd,
            role
          )
        }
      })
      .catch((err) => {
        if (err.message === "Network Error") {
          console.log("ASK BG SERVICE HERE!!!")

          if (ENABLE_BG_SYNC) {
            authenticateUserWithTheLocalBgService(dispatch, mobile, pwd)
          } else {
            dispatch({
              type: ON_USER_SIGN_IN_FAIL,
              payload: {
                errTitle: "err_title_text", //'שגיאה',
                errText: "network_error_text", //'תקלת תקשורת זמנית'
              },
            })
          }
          setNetworkErrorIfExist(err)
          return
        }
        dispatch({
          type: ON_USER_SIGN_IN_FAIL,
          payload: {
            errTitle: "err_title_text", //'שגיאה',
            errText: "wrong_login_err_body_text", //'אחד או יותר מהפרטים שהוזנו שגויים. ניתן לאפס סיסמא באמצעות לחיצה על \'שכחת סיסמא?\''
          },
        })
        setNetworkErrorIfExist(err)
      })
  }
}

export const onDriverSignInClick = (mobile, pwd, stationId) => {
  return (dispatch) => {
    dispatch({
      type: ON_DRIVER_SIGN_IN_REQUEST,
    })

    axiosPostRetry(
      api.driverSignIn(stationId),
      api.signInBody(mobile, pwd),
      7000
    )
      .then((res) => {
        const {
          id: userId,
          role,
          firstName,
          lastName,
          token,
          isPasswordTemporary,
          isRefillDetails,
          email,
          creditCardToken,
          isDeliveryRole,
          isShowUnlockScreen,
          isNeedAccessibility,
          isMustCollectFirstRole,
          isMustCompleteTasksFirstRole,
        } = res.data

        axios.defaults.headers.common["Authorization"] = `Bearer ${token}`
        dispatch({
          type: ON_DRIVER_SIGN_IN_SUCCESS,
          payload: {
            userId,
            role,
            firstName,
            lastName,
            email,
            token,
            isPasswordTemporary,
            isRefillDetails,
            creditCardToken,
            isDeliveryRole,
            isShowUnlockScreen,
            mobile,
            isNeedAccessibility,
            isMustCollectFirstRole,
            isMustCompleteTasksFirstRole,
          },
        })

        dispatch(reportFBEvent("Sign_In", "User Sign In Success"))

        if (ENABLE_BG_SYNC) {
          sendUserValidDataToLocalBgService(
            userId,
            mobile,
            firstName,
            lastName,
            email,
            pwd,
            role //, isMustCollectFirstRole
          )
        }
      })
      .catch((err) => {
        const errStatus = err.response.status

        if (errStatus === 409 || err?.code === 409) {
          dispatch({
            type: ON_DRIVER_SIGN_IN_NOT_ALLOWED,
          })
          return
        }
        if (err.message === "Network Error") {
          if (ENABLE_BG_SYNC) {
            authenticateUserWithTheLocalBgService(dispatch, mobile, pwd)
          } else {
            dispatch({
              type: ON_DRIVER_SIGN_IN_FAIL,
              payload: {
                errTitle: "err_title_text", //'שגיאה',
                errText: "network_error_text", //'תקלת תקשורת זמנית'
                errStatus: errStatus,
              },
            })
          }
          setNetworkErrorIfExist(err)
          return
        }
        dispatch({
          type: ON_DRIVER_SIGN_IN_FAIL,
          payload: {
            errTitle: "err_title_text", //'שגיאה',
            errText: "wrong_login_err_body_text", //'אחד או יותר מהפרטים שהוזנו שגויים. ניתן לאפס סיסמא באמצעות לחיצה על \'שכחת סיסמא?\''
            errStatus: errStatus,
          },
        })
        setNetworkErrorIfExist(err)
      })
  }
}

export const onUserSignInWithDoneId = (doneId) => {
  return (dispatch) => {
    dispatch({
      type: ON_USER_SIGN_IN_REQUEST,
    })
    const data = {
      doneId: doneId,
    }
    axiosPostRetry(api.signIn, data, 7000)
      .then((res) => {
        const {
          id: userId,
          role,
          firstName,
          lastName,
          token,
          isPasswordTemporary,
          isRefillDetails,
          email,
          creditCardToken,
          isDeliveryRole,
          isShowUnlockScreen,
          mobilePhone: mobile,
          isHideDriverReadyItemButton,
          isMustCollectFirstRole,
          isMustCompleteTasksFirstRole,
        } = res.data

        axios.defaults.headers.common["Authorization"] = `Bearer ${token}`
        dispatch({
          type: ON_USER_SIGN_IN_SUCCESS,
          payload: {
            userId,
            role: "Customer",
            firstName,
            lastName,
            email,
            token,
            isPasswordTemporary,
            isRefillDetails,
            creditCardToken,
            isDeliveryRole,
            isShowUnlockScreen,
            mobile,
            isHideDriverReadyItemButton,
            isMustCollectFirstRole,
            isMustCompleteTasksFirstRole,
          },
        })

        dispatch(reportFBEvent("Sign_In", "User Sign In Success"))
      })
      .catch((err) => {
        axios
          .post("http://localhost:3001/logToStationLogger", {
            csvLine: `ERROR IN REQUEST, ${err}`, // clear after testing!!!
          })
          .catch((errr) => {
            setBGErrorIfExist(errr)
          })
        if (err.message === "Network Error") {
          dispatch({
            type: ON_USER_SIGN_IN_FAIL,
            payload: {
              errTitle: "err_title_text", //'שגיאה',
              errText: "network_error_text", //'תקלת תקשורת זמנית'
            },
          })
          setNetworkErrorIfExist(err)
          return
        }

        dispatch({
          type: ON_USER_SIGN_IN_FAIL,
          payload: {
            errTitle: "err_title_text", //'שגיאה',
            errText: "wrong_login_err_body_text", //'אחד או יותר מהפרטים שהוזנו שגויים. ניתן לאפס סיסמא באמצעות לחיצה על \'שכחת סיסמא?\''
          },
        })
        setNetworkErrorIfExist(err)
      })
  }
}

export const onDriverSignInWithDoneId = (doneId, stationId) => {
  return (dispatch) => {
    dispatch({
      type: ON_DRIVER_SIGN_IN_REQUEST,
    })
    const data = {
      doneId: doneId,
    }
    axiosPostRetry(api.driverSignIn(stationId), data, 7000)
      .then((res) => {
        const {
          id: userId,
          role,
          firstName,
          lastName,
          token,
          isPasswordTemporary,
          isRefillDetails,
          email,
          creditCardToken,
          isDeliveryRole,
          isShowUnlockScreen,
          mobilePhone: mobile,
          isHideDriverReadyItemButton,
          isMustCollectFirstRole,
          isMustCompleteTasksFirstRole,
        } = res.data

        axios.defaults.headers.common["Authorization"] = `Bearer ${token}`
        dispatch({
          type: ON_DRIVER_SIGN_IN_SUCCESS,
          payload: {
            userId,
            role,
            firstName,
            lastName,
            email,
            token,
            isPasswordTemporary,
            isRefillDetails,
            creditCardToken,
            isDeliveryRole,
            isShowUnlockScreen,
            mobile,
            isHideDriverReadyItemButton,
            isMustCollectFirstRole,
            isMustCompleteTasksFirstRole,
          },
        })

        dispatch(reportFBEvent("Sign_In", "User Sign In Success"))
      })
      .catch((err) => {
        const errStatus = err.response.status
        axios.post("http://localhost:3001/logToStationLogger", {
          csvLine: `ERROR IN REQUEST, ${err}`,
        })
        if (err.message === "Network Error") {
          dispatch({
            type: ON_DRIVER_SIGN_IN_FAIL,
            payload: {
              errTitle: "err_title_text", //'שגיאה',
              errText: "network_error_text", //'תקלת תקשורת זמנית'
              errStatus: errStatus,
            },
          })
          setNetworkErrorIfExist(err)
          return
        }

        dispatch({
          type: ON_DRIVER_SIGN_IN_FAIL,
          payload: {
            errTitle: "err_title_text", //'שגיאה',
            errText: "wrong_login_err_body_text", //'אחד או יותר מהפרטים שהוזנו שגויים. ניתן לאפס סיסמא באמצעות לחיצה על \'שכחת סיסמא?\''
            errStatus: errStatus,
          },
        })
      })
  }
}

export const sendUserValidDataToLocalBgService = (
  userId,
  mobile,
  firstName,
  lastName,
  email,
  password,
  role
) => {
  axios
    .post(api.createOrUpdateUser, {
      userId,
      mobile,
      firstName,
      lastName,
      email,
      password,
      role,
    })
    .then((res) => {
      console.log("create or update user", res)
    })
    .catch((err) => {
      console.log(err)
    })
}

const authenticateUserWithTheLocalBgService = (dispatch, mobile, password) => {
  axios
    .post(api.authenticateWithBgService, { mobile, password })
    .then((res) => {
      const { userId, role, firstName, lastName } = res.data.user

      dispatch({
        type: ON_USER_SIGN_IN_SUCCESS,
        payload: { userId, role, firstName, lastName, mobile },
      })
    })
    .catch((err) => {
      console.log(err)

      dispatch({
        type: ON_USER_SIGN_IN_FAIL,
        payload: {
          errTitle: "err_title_text",
          errText: "wrong_login_err_body_text",
        },
      })
      setNetworkErrorIfExist(err)
    })
}

export const LogoutUser = () => {
  return (dispatch) => {
    dispatch({
      type: ON_USER_SIGN_OUT,
    })

    axios.defaults.headers.common["Authorization"] = ""
  }
}
export const clearLoginErrStatus = () => {
  return (dispatch) => {
    dispatch({
      type: CLEAR_ERR_STATUS,
    })
  }
}

export const checkIfMobileAlreadyExists = (mobile) => {
  return (dispatch) => {
    dispatch({
      type: USER_MOBILE_ALREADY_TAKEN_REQUEST,
    })
    axiosGetRetry(api.isUserMobileAlreadyExists(mobile))
      .then((res) => {
        if (!res) {
          dispatch({
            type: USER_MOBILE_ALREADY_TAKEN_FALSE,
            payload: mobile,
          })
          console.log("MOBILE", mobile, "NOT EXISTS!")
          return
        }

        dispatch({
          type: USER_MOBILE_ALREADY_TAKEN_TRUE,
          payload: res.data,
        })

        console.log("MOBILE", mobile, "EXISTS!")
      })
      .catch((err) => {
        if (err && err.response && err.response.status === 404) {
          dispatch({
            type: USER_MOBILE_ALREADY_TAKEN_FALSE,
            payload: mobile,
          })
          console.log("MOBILE", mobile, "NOT EXISTS!")
        } else {
          dispatch({
            type: ON_BASE_ERROR,
          })
        }
        setNetworkErrorIfExist(err)
      })
  }
}

export const sendVerifyCode = (mobileNumber) => {
  return (dispatch) => {
    axiosGetRetry(api.sendVerifyCode(mobileNumber))
      .then((res) => {
        dispatch({
          type: USER_OTP_MOBILE_ALREADY_EXIST,
          payload: res.data,
        })

        console.log("MOBILE", mobileNumber, "EXISTS!")
      })
      .catch((err) => {
        if (err && err.response && err.response.status === 404) {
          dispatch({
            type: USER_MOBILE_ALREADY_TAKEN_FALSE,
            payload: mobileNumber,
          })
          console.log("MOBILE", mobileNumber, "NOT EXISTS!")
        }
        console.log(err)
        setNetworkErrorIfExist(err)
      })
  }
}

export const signInWithOTP = (mobilePhone, verifyCode) => {
  return (dispatch) => {
    axiosPostRetry(api.checkVerifyCode, {
      mobilePhone,
      verifyCode,
    })
      .then((res) => {
        const {
          id: userId,
          role,
          firstName,
          lastName,
          token,
          isPasswordTemporary,
          isRefillDetails,
          email,
          creditCardToken,
          isDeliveryRole,
          mobilePhone: mobile,
          isShowUnlockScreen,
          isHideDriverReadyItemButton,
          isMustCollectFirstRole,
          isMustCompleteTasksFirstRole,
        } = res.data

        axios.defaults.headers.common["Authorization"] = `Bearer ${token}`
        dispatch({
          type: SIGN_IN_WITH_OTP,
          payload: {
            userId,
            role,
            firstName,
            lastName,
            email,
            token,
            isPasswordTemporary,
            isRefillDetails,
            creditCardToken,
            isDeliveryRole,
            mobile,
            isShowUnlockScreen,
            isHideDriverReadyItemButton,
            isMustCollectFirstRole,
            isMustCompleteTasksFirstRole,
          },
        })
      })
      .catch((err) => {
        dispatch({
          type: SIGN_IN_WITH_OTP_FAIL,
          payload: { errStatus: err.response.status },
        })
        setNetworkErrorIfExist(err)
      })
  }
}

export const isUserHadOrderBefore = (userId) => {
  return (dispatch) => {
    axiosGetRetry(api.isHadOrderBefore(userId))
      .then((res) => {
        dispatch({
          type: GET_IS_USER_HAD_ORDER_BEFORE_SUCCESS,
          payload: res.data,
        })
      })
      .catch((err) => {
        dispatch({
          type: GET_IS_USER_HAD_ORDER_BEFORE_FAIL,
          payload: err,
        })
        setNetworkErrorIfExist(err)
      })
  }
}

export const setUserCCShured = (isCCShured) => {
  return (dispatch) => {
    dispatch({
      type: SET_CC_SHURED,
      action: isCCShured,
    })
  }
}

export const resetUserCCShured = () => {
  return (dispatch) => {
    dispatch({
      type: RESET_CC_SHURED,
    })
  }
}
