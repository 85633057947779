import axios from "axios"
import api from "../api"

import { onUserSignInClick } from "./UserSignInActions"
import { axiosPostRetry, axiosPutRetry } from "../utils"

import {
  USERS_REGISTER_STEP_1_DATA_SUBMIT,
  USERS_REGISTER_REQUEST_FAILURE,
  FETCH_ALL_STATIONS_DATA_SUCCESS,
  USERS_REGISTER_SET_PWD,
  ON_PELE_TOKEN_SAVED_IN_RETRY,
  ON_LOADING,
  COMPLETE_USER_DATA_SUCCESS,
  COMPLETE_USER_DATA_FAIL,
  COMPLETE_USER_DATA_RESET,
  USERS_REGISTER_REQUEST_SUCCESS,
  ON_REGISTRATION_VERIFY_SUCCESS,
  ON_REGISTRATION_VERIFY_FAILED,
  CLEAR_REGISTRATION_DATA,
  USERS_REGISTER_STEP_2_DATA_SUBMIT,
  ON_USER_SIGN_IN_SUCCESS,
} from "./types"

import { reportFBEvent } from "./FBEventsActions"

export const handleRegistrationSubmit = (
  userdata,
  stationId,
  signInWhenDone
) => {
  return (dispatch) => {
    const { mobile, firstName, lastName, email, password } = userdata

    dispatch({ type: USERS_REGISTER_SET_PWD, payload: password })

    axiosPostRetry(
      api.registerNewUser,
      api.registerNewUserBody(
        mobile,
        firstName,
        lastName,
        email,
        password,
        stationId
      )
    )
      .then((response) => {
        if (response.status === 200) {
          return
        }
      })
      .then(() => {
        if (signInWhenDone) {
          dispatch(onUserSignInClick(mobile, password))

          dispatch(
            reportFBEvent(
              "User_Registration_Event",
              "A New User Was Just Registered To The System!"
            )
          )
        }
      })
      .catch((error) => {
        dispatch({
          type: USERS_REGISTER_REQUEST_FAILURE,
          payload: error,
        })
      })
  }
}

export const onStep1UserDataSubmit = (
  firstName,
  lastName,
  email,
  mobile,
  password
) => {
  return {
    type: USERS_REGISTER_STEP_1_DATA_SUBMIT,
    payload: { firstName, lastName, mobile, email, password },
  }
}

export const onStep2UserDataSubmit = (password) => {
  return {
    type: USERS_REGISTER_STEP_2_DATA_SUBMIT,
    payload: { password },
  }
}

export const fetchAllStationsData = () => {
  return (dispatch) => {
    axios
      .get(api.getAllStationsData)
      .then((res) => {
        dispatch({
          type: FETCH_ALL_STATIONS_DATA_SUCCESS,
          payload: res.data,
        })
      })
      .catch((err) => console.log(err))
  }
}

export const retryTokenCreation = () => {
  return {
    type: ON_PELE_TOKEN_SAVED_IN_RETRY,
  }
}

export const completeUserData = (userId, firstName, lastName, email) => {
  return (dispatch) => {
    //const { userId, firstName, lastName, email } = userdata;

    dispatch({ type: ON_LOADING })

    axiosPutRetry(api.completeUserDetails(userId), {
      firstName,
      lastName,
      email,
    })
      .then((response) => {
        dispatch({
          type: COMPLETE_USER_DATA_SUCCESS,
          payload: response.data,
        })
      })
      //.then(() => {

      // if (signInWhenDone) {
      //     dispatch(onUserSignInClick(mobile, password));
      //    dispatch(reportFBEvent('User_CompleteUserData_Event', 'A User Data was Completed.'));
      //}
      //})
      .catch((error) => {
        dispatch({
          type: COMPLETE_USER_DATA_FAIL,
          payload: error,
        })
      })
  }
}

export const resetCompleteDetails = () => {
  return (dispatch) => {
    dispatch({ type: COMPLETE_USER_DATA_RESET })
  }
}

export const requestRegisterVerifyCode = (userdata, stationId) => {
  return (dispatch) => {
    const { mobile, firstName, lastName, email, password } = userdata

    const data = {
      mobilePhone: mobile,
      email,
      firstName,
      lastName,
      password,
      //verifyCode
      stationId,
    }

    axiosPostRetry(api.requestRegisterVerifyCode, data)
      .then((response) => {
        if (response.status === 200) {
          dispatch({
            type: ON_REGISTRATION_VERIFY_SUCCESS,
            payload: response.data,
          })
          return
        }
      })

      .catch((error) => {
        dispatch({
          type: USERS_REGISTER_REQUEST_FAILURE,
          payload: error,
        })
      })
  }
}

export const registerWithVerifyCode = (userdata, stationId) => {
  return (dispatch) => {
    const { mobile, firstName, lastName, email, password, verifyCode } =
      userdata

    const data = {
      mobilePhone: mobile,
      email,
      firstName,
      lastName,
      password,
      verifyCode,
      stationId,
    }
    axiosPostRetry(api.registerWithVerifyCode, data)
      .then((response) => {
        if (response.status === 200) {
          const {
            id: userId,
            role,
            firstName,
            lastName,
            token,
            isPasswordTemporary,
            isRefillDetails,
            email,
            creditCardToken,
            isDeliveryRole,
            isShowUnlockScreen,
            isNeedAccessibility,
            isHideDriverReadyItemButton,
          } = response.data

          axios.defaults.headers.common["Authorization"] = `Bearer ${token}`

          dispatch({
            type: ON_USER_SIGN_IN_SUCCESS,
            payload: {
              userId,
              role,
              firstName,
              lastName,
              token,
              isPasswordTemporary,
              isRefillDetails,
              email,
              creditCardToken,
              isDeliveryRole,
              isShowUnlockScreen,
              isNeedAccessibility,
              isHideDriverReadyItemButton,
            },
          })

          dispatch(
            reportFBEvent(
              "User_Registration_Event",
              "A New User Was Just Registered To The System by OTP!"
            )
          )
        } else {
          dispatch({
            type: ON_REGISTRATION_VERIFY_FAILED,
            payload: response,
          })
        }
      })

      .catch((error) => {
        dispatch({
          type: ON_REGISTRATION_VERIFY_FAILED,
          payload: error,
        })
      })
  }
}

export const clearRegisterVerifyCode = () => {
  return (dispatch) => {
    dispatch({
      type: CLEAR_REGISTRATION_DATA,
    })
  }
}
