import React from "react"

import "./style.css"

const Progressbar = ({ max, currVal }) => {
  return (
    <div className="bprg-outer-wrapper">
      <div className="bprg-wrapper">{renderCircles(max, currVal)}</div>
    </div>
  )
}

const renderCircles = (max, currVal) => {
  const numsArr = [...Array(max).keys()]

  return numsArr.map((itm) => {
    return (
      <div className="bprg-wrapper" key={itm}>
        <div
          className={`bprg-circle ${
            itm + 1 === currVal ? "bprg-curr-val" : ""
          }`}
        >
          {itm + 1}
        </div>
        {itm < max - 1 && <div className="bprg-orange-line" />}
      </div>
    )
  })
}

export { Progressbar }
