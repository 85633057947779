import React, { Component } from "react"
import { connect } from "react-redux"
import {
  logScreenEnterance,
  registerWithVerifyCode,
  requestRegisterVerifyCode,
  clearRegisterVerifyCode,
} from "../../../../../actions"
import { validation } from "../../../../../utils"
import {
  DisEnbPendBtn,
  InputFiled,
  OrangeButton,
  Progressbar,
} from "../../../../common"
import ErrorInfoModal from "../../../../Modals/ErrorInfoModal"
import VirtualKeyboard from "../../../../common/VirtualKeyboard"
import TimerBar from "../../../../common/TimerBar"

import { FixedHeader } from "../../../../reduxified"

import "./style.scss"

class StepVerifyScreen extends Component {
  constructor(props) {
    super(props)

    this.INIT = {
      verifyCode: "",
      verifyCodeRef: null,

      activeInput: null,
      activeInputName: "verifyCode",

      err: false,

      isSubmitBtnDisabled: true,
      errorsTimeout: null,

      runValidationsTimeOut: null,
      isSubmitClicked: false,

      inputPasswordType: "password",
      isShowError: false,
      showTimerBar: true,
    }

    this.state = this.INIT
  }

  componentWillMount() {
    if (!this.props.stationId || this.props.stationId.length < 1) {
      this.props.history.push("/")
    }
  }

  componentDidMount() {
    this.props.logScreenEnterance("USER_SIGN_UP_VERIFY_SCREEN")
    if (!this.props.mobile) {
      this.props.history.push("/SignUpStep1Screen")
    }
  }

  componentWillUnmount() {
    this.state = this.INIT
    //this.props.clearRegisterVerifyCode()
  }

  componentWillReceiveProps(newProps) {
    const { authenticated, isErrorRegistrationWithVerify } = newProps

    if (authenticated) {
      this.props.history.push("/SimpleServiceMenuScreen")
    }
    if (isErrorRegistrationWithVerify) {
      this.setState({ isShowError: true, showTimerBar: true })
      //this.props.clearRegisterVerifyCode()
    }
  }

  onKeyboardTextChange(text) {
    const { activeInputName, errorsTimeout } = this.state

    if (errorsTimeout) {
      clearTimeout(errorsTimeout)
    }

    this.setState({
      [activeInputName]: text,
      err: false,
      errorsTimeout: null,
    })

    this.runValidationsWithTimoutDebounce()
  }

  onRefReady(refName, ref) {
    if (!this.state[refName]) {
      this.setState({ [refName]: ref })
    }
  }

  onInputFocusChange(refName, isFocused) {
    if (isFocused) {
      this.setState({
        activeInput: this.state[refName],
        activeInputName: refName.replace("Ref", ""),
      })
    } else {
      if (this.state.activeInput === this.state[refName])
        this.setState({ activeInput: null })
    }
  }

  onSubmitClick() {
    const { mobile, email, firstName, lastName, stationId, password } =
      this.props
    this.setState({ isSubmitClicked: true, showTimerBar: false })

    const userdata = {
      mobile,
      email,
      firstName,
      lastName,
      password,
      verifyCode: this.state.verifyCode,
    }

    this.props.registerWithVerifyCode(userdata, stationId)
  }

  onBackClick() {
    this.props.history.goBack()
  }

  runValidations() {
    const { verifyCode } = this.state

    const isVerifyCode = validation.VerifyCode(verifyCode)
    console.log("isVerifyCode:" + isVerifyCode)
    const isSubmitBtnDisabled = !isVerifyCode
    const err = !isVerifyCode
    this.setState({
      isSubmitBtnDisabled,
      err,
      runValidationsTimeOut: null,
    })
  }

  onInputFieldChange(inputName, val) {
    this.setState({ [inputName]: val })

    this.runValidationsWithTimoutDebounce()
  }

  runValidationsWithTimoutDebounce() {
    let { runValidationsTimeOut } = this.state

    if (runValidationsTimeOut) {
      clearTimeout(runValidationsTimeOut)
    }

    runValidationsTimeOut = setTimeout(() => this.runValidations(), 500)

    this.setState({ runValidationsTimeOut })
  }

  onViewPasswordClick() {
    const { inputPasswordType } = this.state
    if (inputPasswordType === "password") {
      this.setState({ inputPasswordType: "text" })
    } else {
      this.setState({ inputPasswordType: "password" })
    }
  }

  onViewPasswordRetypeClick() {
    const { inputRetypePasswordType } = this.state
    if (inputRetypePasswordType === "password") {
      this.setState({ inputRetypePasswordType: "text" })
    } else {
      this.setState({ inputRetypePasswordType: "password" })
    }
  }

  onClickResendVerificationCode() {
    const { mobile, email, firstName, lastName, stationId, password } =
      this.props

    const userdata = {
      mobile,
      email,
      firstName,
      lastName,
      password,
    }
    this.props.requestRegisterVerifyCode(userdata, stationId)
  }

  render() {
    const {
      verifyCode,
      activeInputName,
      err,
      isSubmitBtnDisabled,
      showTimerBar,
      isSubmitClicked,
      inputPasswordType,
    } = this.state

    const {
      dirRtl,
      welcome_title_text,
      back_btn_text,
      ok_text,
      validationtext,
      verify_code,
      registration_verify_code_info,
      contact_customer_service,
      registration_verify_failed_title,
      close_text,
      resend_verify_code,
    } = this.props

    return (
      <div className="sus2s-wrapper">
        <FixedHeader
          title={welcome_title_text}
          buttonsFlag="passwordUpdateScreen"
        />

        <div className="sus2s-body" dir={dirRtl ? "rtl" : "ltr"}>
          <Progressbar max={2} currVal={2} />

          <div className="sus2s-frame-wrapper">
            <div className="sus2s-frame-inner-wrapper">
              <div className="enter-text" dir={dirRtl}>
                <p className="title-verify-code-input">
                  {registration_verify_code_info}
                </p>
              </div>
              <div className="enter-text" dir={dirRtl}>
                <InputFiled
                  value={verifyCode}
                  type={inputPasswordType}
                  onViewPasswordClick={(e) => this.onViewPasswordClick()}
                  showEye
                  placeholder={verify_code}
                  onRefReady={(ref) => this.onRefReady("verifyCodeRef", ref)}
                  onChange={(e) =>
                    this.onInputFieldChange("verifyCode", e.target.value)
                  }
                  onFocusChanged={(isFocused) =>
                    this.onInputFocusChange("verifyCodeRef", isFocused)
                  }
                  displayErr={err}
                  mandatory
                  dirRtl={dirRtl}
                  icon="mini_lock"
                  validationtext={validationtext.verifyCode}
                  disabled={isSubmitClicked}
                />
              </div>
            </div>
            <TimerBar
              show={showTimerBar}
              onClick={(e) => {
                this.onClickResendVerificationCode()

                console.log("click")
              }}
              text={resend_verify_code}
            />
            <div className="sus2s-details-btns">
              <DisEnbPendBtn
                extraClass="sus2s-details-single-btn"
                onClick={(e) => this.onSubmitClick()}
                isPending={isSubmitClicked}
                isDisabled={isSubmitBtnDisabled}
              >
                {ok_text}
              </DisEnbPendBtn>
              <OrangeButton
                extraClass="sus2s-details-single-btn"
                onClick={(e) => this.onBackClick()}
              >
                {back_btn_text}
              </OrangeButton>
            </div>
          </div>
        </div>
        <VirtualKeyboard
          currentValue={this.state[activeInputName]}
          onKeyboardInputChange={(txt) => this.onKeyboardTextChange(txt)}
          show={true}
          lang="mobile"
          closeOnEnter
          blockLangSwitch
        />
        <ErrorInfoModal
          dirRtl={dirRtl}
          show={this.state.isShowError}
          title={registration_verify_failed_title}
          text={contact_customer_service}
          onModalCloseClick={() => {
            this.setState({ isShowError: false })
            this.props.clearRegisterVerifyCode()
            this.props.history.push("/")
          }}
          closeTrnsl={close_text}
        />
      </div>
    )
  }
}

const mapStateToProps = ({ registration, userLogin, bgService, translate }) => {
  const {
    mobile,
    email,
    firstName,
    lastName,
    allStationsData,
    password,
    isErrorRegistrationWithVerify,
  } = registration
  const {
    authenticated,
    userId,
    role,
    firstName: fName,
    lastName: lName,
    isPasswordTemporary,
    email: userEmail,
    mobile: userMobile,
  } = userLogin
  const { stationId } = bgService

  const {
    dirRtl,
    welcome_title_text,
    back_btn_text,
    ok_text,
    star_pwd_input_text_hint,
    retyped_pwd_input_text_hint,
    star_cmpName_input_text_hint,
    im_approving,
    terms_of_use,
    and_the_privacy_policy,
    validation_empty_text,
    validation_verify_code_text,
    validation_name_text,
    verify_code,
    registration_verify_code_info,
    contact_customer_service,
    registration_verify_failed_title,
    close_text,
    resend_verify_code,
  } = translate

  let validationtext = {
    empty: validation_empty_text,
    verifyCode: validation_verify_code_text,

    name: validation_name_text,
  }
  const termsCbTranslation = {
    im_approving,
    terms_of_use,
    and_the_privacy_policy,
  }

  return {
    mobile,
    email,
    firstName,
    lastName,
    password,
    authenticated,
    stationId,
    userId,
    role,
    fName,
    lName,
    isPasswordTemporary,
    userEmail,
    userMobile,
    allStationsData,

    dirRtl,
    welcome_title_text,
    back_btn_text,
    ok_text,
    close_text,
    star_pwd_input_text_hint,
    retyped_pwd_input_text_hint,
    star_cmpName_input_text_hint,
    termsCbTranslation,
    validationtext,
    verify_code,
    registration_verify_code_info,
    isErrorRegistrationWithVerify,
    contact_customer_service,
    registration_verify_failed_title,
    resend_verify_code,
  }
}

export default connect(mapStateToProps, {
  registerWithVerifyCode,
  requestRegisterVerifyCode,
  logScreenEnterance,
  clearRegisterVerifyCode,
})(StepVerifyScreen)
