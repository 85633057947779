import React from "react"
import Key from "./Key"

import "./style.css"

const Row = ({ keys, onKeyClick, currLang, blockLangSwitch, langName }) => {
  return (
    <div className="vkb-row-wrapper">
      {renderKeys(keys, onKeyClick, currLang, blockLangSwitch, langName)}
    </div>
  )
}

const renderKeys = (keys, onKeyClick, currLang, blockLangSwitch, langName) => {
  let filteredKeys = keys
  if (blockLangSwitch) {
    filteredKeys = keys.filter((key) => key !== "LANG_SWITCH")
  }
  return filteredKeys.map((key, index) => {
    const extraClass = key === "SPACE" ? "space-key" : ""
    if (currLang !== "he" && langName !== "he" && key === "CLOSE")
      key = "ENG_CLOSE"
    if (currLang === "en" && langName !== "he" && key === "GO_BACK")
      key = "ENG_GO_BACK"
    const keyIcon =
      key !== null &&
      key.toLowerCase() !== key.toUpperCase() &&
      key === key.toUpperCase()
        ? key.toLowerCase()
        : null
    return (
      <Key
        keyChar={key}
        key={index}
        onKeyClick={onKeyClick}
        keyIcon={keyIcon}
        extraClass={extraClass}
        currLang={currLang}
      />
    )
  })
}

export default Row
