import {
  ON_CLEAR_ERROR_NETWORK,
  ON_ERROR_NETWORK,
  BG_SERVICE_ALIVE_FAIL,
} from "./types"

export const clearNetworkErrorMessage = () => {
  return (dispatch) => {
    dispatch({
      type: ON_CLEAR_ERROR_NETWORK,
    })
  }
}

export const setNetworkErrorIfExist = (err) => {
  if (err?.code === "ERR_NETWORK" || err?.code === "ECONNABORTED") {
    return (dispatch) => {
      dispatch({
        type: ON_ERROR_NETWORK,
      })
    }
  }
}

export const setBGErrorIfExist = (err) => {
  if (err?.code === "ERR_NETWORK" || err?.code === "ECONNABORTED") {
    return (dispatch) => {
      dispatch({
        type: BG_SERVICE_ALIVE_FAIL,
      })
    }
  }
}
