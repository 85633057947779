import React, { Component } from "react"
import { connect } from "react-redux"
import {
  checkIfMobileAlreadyExists,
  LogoutUser,
  logScreenEnterance,
  recreateUserPassword,
  restoreDeliveryCode,
} from "../../../../actions"
import { DisableEnableButton, InputFiled, OrangeButton } from "../../../common"
import VirtalKeyboard from "../../../common/VirtualKeyboard"
import ErrorInfoModal from "../../../Modals/ErrorInfoModal"
import WarningInfoModal from "../../../Modals/WarningInfoModal"
import { FixedHeader } from "../../../reduxified"
import "./style.css"

class RestoreDeliveryCodeScreen extends Component {
  constructor(props) {
    super(props)
    this.state = {
      mobile: "",
      mobileRef: null,

      activeInput: null,
      activeInputName: null,

      isSubmitBtnDisabled: true,
      mobileErr: false,

      showErrorModal: false,
      showMobileNotExistsModal: false,
      showResetDeliveryCodeModal: false,

      errorsTimeout: null,
      runValidationsTimeOut: null,
    }
  }

  componentDidMount() {
    this.props.logScreenEnterance("USER_RECREATE_PWD_SCREEN")
  }

  componentWillUnmount() {}

  componentWillReceiveProps(newProps) {
    const {
      isLoadingRequest,
      showMobileNotExistsModal,
      showResetDeliveryCodeModal,
      showErrorModal,
    } = newProps

    this.setState({
      showErrorModal: showErrorModal,
      showMobileNotExistsModal: showMobileNotExistsModal,
      showResetDeliveryCodeModal: showResetDeliveryCodeModal,
    })

    if (isLoadingRequest) {
      this.setState({ isSubmitBtnDisabled: true })
    }
  }

  onBackClick() {
    this.props.history.goBack()
  }

  onRefReady(refName, ref) {
    if (!this.state[refName]) this.setState({ [refName]: ref })
  }

  onKeyboardTextChange(text) {
    const { activeInputName, errorsTimeout } = this.state

    if (errorsTimeout) {
      clearTimeout(errorsTimeout)
    }

    this.setState({
      [activeInputName]: text,
      mobileErr: false,
      errorsTimeout: null,
    })

    if (activeInputName === "mobile" && this.isMobileNumValid(text)) {
      this.setState({ isSubmitBtnDisabled: false })
    }

    this.runValidationsWithTimoutDebounce()
  }

  isMobileNumValid(mobile) {
    if (!mobile || mobile.length === 0) {
      return false
    }

    return mobile.length === 10
  }

  runValidations() {
    const { mobile } = this.state

    const isMobileNumValid = this.isMobileNumValid(mobile)

    const isEmptyMobile = mobile.length < 1

    const mobileErr = !isMobileNumValid && !isEmptyMobile

    if (!isMobileNumValid) {
      this.setState({
        isSubmitBtnDisabled: true,
        mobileErr,
        runValidationsTimeOut: null,
      })
    } else {
      const errorsTimeout = setTimeout(
        () =>
          this.setState({
            mobileErr,
            runValidationsTimeOut: null,
          }),
        800
      )

      this.setState({ errorsTimeout })
    }
  }

  onInputFocusChange(refName, isFocused) {
    if (isFocused) {
      this.setState({
        activeInput: this.state[refName],
        activeInputName: refName.replace("Ref", ""),
      })
    } else {
      if (this.state.activeInput === this.state[refName])
        this.setState({ activeInput: null })
    }
  }

  closeRestoreDeliveryCodeModal() {
    this.setState({ showResetDeliveryCodeModal: false })
    this.props.LogoutUser()
    this.props.history.push("")
  }

  closeRestoreDeliveryCodeErrorModal() {
    this.setState({
      showResetDeliveryCodeModal: false,
      showMobileNotExistsModal: false,
      showErrorModal: false,
      mobile: "",
    })
  }

  onInputFieldChange(inputName, val, evt) {
    evt.persist()
    this.setState({ [inputName]: val })
    if (inputName === "mobile" && this.isMobileNumValid(val)) {
      this.setState({ isSubmitBtnDisabled: false })
    }

    this.runValidationsWithTimoutDebounce()
  }

  runValidationsWithTimoutDebounce() {
    let { runValidationsTimeOut } = this.state

    if (runValidationsTimeOut) {
      clearTimeout(runValidationsTimeOut)
    }

    runValidationsTimeOut = setTimeout(() => this.runValidations(), 500)

    this.setState({ runValidationsTimeOut })
  }

  onRestoreDeliveryCodeClick(mobile) {
    const { stationId } = this.props
    this.props.restoreDeliveryCode(stationId, mobile)
  }

  render() {
    const {
      mobile,
      activeInputName,
      mobileErr,
      isSubmitBtnDisabled,
      showMobileNotExistsModal,
      showResetDeliveryCodeModal,
      showErrorModal,
    } = this.state

    const {
      dirRtl,
      welcome_title_text,
      err_title_text,
      ok_text,
      cancel_text,
      mobile_input_hint,
      close_text,
      restore_delivery_code_screen_sub_title,
      restore_delivery_code_modal_title,
      restore_delivery_code_modal_body,
      restore_delivery_code_err_modal_body,
      restore_delivery_code_not_found_error_title,
    } = this.props

    // const keyboardLang = "mobile"

    return (
      <div className="rps-wrapper">
        <FixedHeader />
        <div className="rps-body" dir={dirRtl ? "rtl" : "ltr"}>
          <div className="rps-frame-container">
            <div className="restore-code-titles">
              <div className="restore-code-title">{welcome_title_text}</div>
              <div className="restore-code-sub-title">
                {restore_delivery_code_screen_sub_title}
              </div>
            </div>
            <div className="rps-frame-wrapper">
              <div className="rps-frame-inner-wrapper">
                <InputFiled
                  value={mobile}
                  onChange={(e) =>
                    this.onInputFieldChange("mobile", e.target.value, e)
                  }
                  placeholder={mobile_input_hint}
                  icon="mini_phone"
                  onRefReady={(ref) => this.onRefReady("mobileRef", ref)}
                  onFocusChanged={(isFocused) =>
                    this.onInputFocusChange("mobileRef", isFocused)
                  }
                  displayErr={mobileErr}
                  maxLength={10}
                  type="tel"
                  autoFocus
                />
              </div>
              <div className="rps-details-btns" dir={dirRtl ? "rtl" : "ltr"}>
                <DisableEnableButton
                  extraClass="rps-details-single-btn"
                  onClick={(e) => this.onRestoreDeliveryCodeClick(mobile)}
                  isDisabled={isSubmitBtnDisabled}
                >
                  {ok_text}
                </DisableEnableButton>
                <OrangeButton
                  extraClass="rps-details-single-btn"
                  onClick={(e) => this.onBackClick()}
                >
                  {cancel_text}
                </OrangeButton>
              </div>
            </div>
          </div>
        </div>
        <VirtalKeyboard
          currentValue={this.state[activeInputName]}
          onKeyboardInputChange={(txt) => this.onKeyboardTextChange(txt)}
          show={true}
          lang="mobile"
          closeOnEnter
          maxLength={10}
        />
        <ErrorInfoModal
          dirRtl={dirRtl}
          show={showMobileNotExistsModal}
          title={restore_delivery_code_not_found_error_title}
          text={restore_delivery_code_err_modal_body}
          onModalCloseClick={() => this.closeRestoreDeliveryCodeErrorModal()}
          closeTrnsl={close_text}
        />
        <ErrorInfoModal
          dirRtl={dirRtl}
          show={showErrorModal}
          title={err_title_text}
          text={restore_delivery_code_err_modal_body}
          onModalCloseClick={() => this.closeRestoreDeliveryCodeErrorModal()}
          closeTrnsl={close_text}
        />
        <WarningInfoModal
          dirRtl={dirRtl}
          show={showResetDeliveryCodeModal}
          title={restore_delivery_code_modal_title}
          text={restore_delivery_code_modal_body}
          onModalCloseClick={() => this.closeRestoreDeliveryCodeModal()}
          btnText={close_text}
        />
      </div>
    )
  }
}

const mapStateToProp = ({ bgService, restoreDeliveryCode, translate }) => {
  const {
    mobile,
    isLoadingRequest,
    showMobileNotExistsModal,
    showResetDeliveryCodeModal,
    showErrorModal,
  } = restoreDeliveryCode
  const { stationId } = bgService
  const {
    dirRtl,
    welcome_title_text,
    reset_pwd_screen_sub_title,
    ok_text,
    cancel_text,
    err_title_text,
    reset_pwd_err_modal_body,
    close_text,
    reset_pwd_modal_title,
    reset_pwd_modal_body,
    mobile_input_hint,
    restore_delivery_code_err_modal_body,

    restore_delivery_code_screen_sub_title,
    restore_delivery_code_modal_title,
    restore_delivery_code_modal_body,
    restore_delivery_code_not_found_error_title,
  } = translate

  const pwdModalBody = reset_pwd_modal_body

  return {
    mobile,
    isLoadingRequest,
    pwdModalBody,
    stationId,
    dirRtl,
    welcome_title_text,
    ok_text,
    cancel_text,
    err_title_text,
    reset_pwd_err_modal_body,
    reset_pwd_screen_sub_title,
    reset_pwd_modal_title,

    mobile_input_hint,
    close_text,

    restore_delivery_code_screen_sub_title,
    restore_delivery_code_modal_title,
    restore_delivery_code_modal_body,
    restore_delivery_code_err_modal_body,
    restore_delivery_code_not_found_error_title,

    showMobileNotExistsModal,
    showResetDeliveryCodeModal,
    showErrorModal,
  }
}

export default connect(mapStateToProp, {
  checkIfMobileAlreadyExists,
  LogoutUser,
  recreateUserPassword,
  logScreenEnterance,
  restoreDeliveryCode,
})(RestoreDeliveryCodeScreen)
