import React, { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import OtpInput from "react18-input-otp"
import { clearLoginErrStatus, signInWithOTP } from "../../../actions"
import ErrorInfoModal from "../../Modals/ErrorInfoModal"
import { DisEnbPendBtn } from "../DisEnbPendBtn"
import VirtualKeyboard from "../VirtualKeyboard"
import "./style.scss"

const InputOTP = ({ mobile }) => {
  const errStatus = useSelector(({ userLogin }) => userLogin.errStatus)
  const translate = useSelector(({ translate }) => translate)
  const [showError, setShowError] = useState(false)
  const [valueOTP, setValueOTP] = useState("")
  const [btnDisabled, setBtnDisabled] = useState(true)

  useEffect(() => {
    if (errStatus) {
      setShowError(true)
    }
    if (!errStatus) {
      setShowError(false)
    }
    if (valueOTP.length > 0) {
      setBtnDisabled(false)
    }
  }, [errStatus, valueOTP])

  const dispatch = useDispatch()

  return (
    <>
      <div dir="ltr" className="otp-input-field">
        <OtpInput
          value={valueOTP}
          numInputs={5}
          inputStyle="otp-input"
          isInputNum
        />
      </div>
      <div className="otp-input-submit-btn">
        <DisEnbPendBtn
          show={true}
          onClick={() => {
            dispatch(signInWithOTP(mobile, valueOTP))
          }}
          isDisabled={btnDisabled}
          extraClass="login-btn"
        >
          המשך
        </DisEnbPendBtn>
      </div>
      <ErrorInfoModal
        dirRtl={translate.dirRtl}
        show={showError}
        text="הקוד לא תקין"
        onModalCloseClick={() => {
          setValueOTP("")
          dispatch(clearLoginErrStatus())
        }}
        closeTrnsl={translate.close_text}
      />
      <VirtualKeyboard
        currentValue={valueOTP}
        onKeyboardInputChange={(value) => {
          setValueOTP(value)
        }}
        show={true}
        lang="mobile"
        closeOnEnter
        otpScreen
      />
    </>
  )
}

export default InputOTP
