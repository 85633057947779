import React, { Component } from "react"
import { WhiteButton } from "../WhiteButton"
import "./style.scss"

class TimerBar extends Component {
  constructor(props) {
    super(props)

    this.INIT_STATE = {
      showWaitLine: true,
      showWaitLineTimer: null,
    }

    this.state = this.INIT_STATE
  }

  componentDidMount() {
    const showWaitLineTimer = setTimeout(() => this.togleView(), 120 * 1000)
    this.setState({ showWaitLineTimer })
  }
  componentWillUnmount() {
    this.state = this.INIT
  }

  togleView() {
    let { showWaitLine, showWaitLineTimer } = this.state
    if (!showWaitLine || showWaitLine === false) {
      showWaitLine = true
      showWaitLineTimer = setTimeout(() => this.togleView(), 120 * 1000)
    } else {
      showWaitLine = false
    }
    this.setState({ showWaitLine, showWaitLineTimer })
  }

  render() {
    const { show, onClick } = this.props
    let { text } = this.props

    if (show == undefined || !show) {
      return <noscript></noscript>
    }

    if (this.state.showWaitLine) {
      return (
        <div className="timer-bar-wrapper">
          <div className="timer-bar"></div>
        </div>
      )
    } else {
      return (
        <div className="timer-bar-wrapper">
          <WhiteButton
            onClick={(e) => {
              this.togleView()
              onClick(e)
            }}
            show={true}
          >
            {text}
          </WhiteButton>
        </div>
      )
    }
  }
}

export default TimerBar
