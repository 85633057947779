import React, { Component } from "react"

import VirtualKeyboard from "../VirtualKeyboard"
import "./style.css"

class CommentsTextArea extends Component {
  constructor(props) {
    super(props)

    this.state = {
      content: "",
      isCommentsTxtFocused: false,
      keyboardLang: undefined,
    }
  }
  componentDidMount() {
    const { langName, initVal } = this.props
    this.setState({
      content: initVal,
      keyboardLang: langName !== "ru" ? langName : "en",
    })
  }

  onChange(val) {
    this.setState({ content: val })
  }

  onCommentsFocusChange(isFocused) {
    const {isCommentsTxtFocused} = this.state
    if(isFocused && !isCommentsTxtFocused){

      this.setState({ isCommentsTxtFocused: isFocused }) 
      this.props.moveToShowKeyboard(isFocused)
    }
  }

  onKeyboardTextChange(txt) {
    this.setState({
      content: txt,
    })

    this.props.onCommentContentUpdate(txt)
  }

  onCloseKeyboardClick() {
    this.props.moveToShowKeyboard(false)
  }

  render() {
    const { isCommentsTxtFocused } = this.state

    return (
      <div className="comm-txt-area-wrapper">
        <textarea
          className="comm-txt-area"
          placeholder={this.props.placeHolderTransl}
          value={this.state.content}
          onFocus={() => this.onCommentsFocusChange(true)}
          onBlur={() => this.onCommentsFocusChange(false)}
          onChange={(e) => this.onChange(e.target.value)}
        />

        <VirtualKeyboard
          currentValue={this.state.content}
          onKeyboardInputChange={(txt) => this.onKeyboardTextChange(txt)}
          show={isCommentsTxtFocused}
          lang={this.state.keyboardLang}
          onCloseKeyboardClick={() => this.onCloseKeyboardClick()}
        />
      </div>
    )
  }
}

export { CommentsTextArea }
