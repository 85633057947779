import React from 'react';

import './style.css';

const PendingBtn = ({ sizeInPx }) => {

    const loaderCircleStyle = sizeInPx ? { height: sizeInPx, width: sizeInPx } : undefined;
    const spinningloaderStyle = sizeInPx ? { height: sizeInPx * 0.45, width: sizeInPx * 0.45, borderWidth: sizeInPx * 0.1 } : undefined;

    return (
        <div className='spinning-loader-circle-wrapper'>
            <div className='spinning-loader-circle' style={loaderCircleStyle}>
                <div className="spinning-loader" style={spinningloaderStyle} />
            </div>
        </div>
    );
}

export default PendingBtn;
