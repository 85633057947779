import React, { useState, useRef } from "react"
import { OrangeButton } from "../../../../common"
import { connect } from "react-redux"
import { taskAccomplishedReport } from "../../../../../actions"
import Loader from "../../../../common/Loader"
import VirtualKeyboard from "../../../../common/VirtualKeyboard"
import "./completeTask.scss"

const CompleteTask = (props) => {
  const {
    show,
    close,
    isLoading,
    selectedTaskId,
    taskCompletedSuccess,
    taskCompletedFail,
    taskAccomplishedReport,
    dirRtl,
    task_completed_report,
    add_notes,
    task_completed_success,
    task_completed_fail,
    validate_notes,
  } = props
  const [notes, setNotes] = useState("")
  const [error, setError] = useState("")
  const [isTextAreaFocused, setIsTextAreaFocused] = useState(false)

  if (!show) {
    return null
  }

  const handleTextChange = (event) => {
    setNotes(event.target.value)
  }

  const onCloseClicked = () => {
    setNotes("")
    setError("")
    close()
  }

  const confirmTask = () => {
    if (notes.length < 4) {
      setError(validate_notes)
      return
    }
    taskAccomplishedReport({
      stationTaskId: selectedTaskId,
      note: notes,
    })
    setNotes("")
    setError("")
  }

  const catchFocus = () => {
    setIsTextAreaFocused(!isTextAreaFocused)
  }

  if (isLoading) {
    return (
      <div className="completeTask-wrapper" dir={dirRtl ? "rtl" : "ltr"}>
        <div className="completeTask loading-container">
          <p className="title">{task_completed_report}</p>
          <Loader />
        </div>
      </div>
    )
  }

  return (
    <div className="completeTask-wrapper" dir={dirRtl ? "rtl" : "ltr"}>
      <div className="completeTask">
        {taskCompletedSuccess && (
          <div className="task-status success">
            <p>{task_completed_success}</p>
          </div>
        )}
        {taskCompletedFail && (
          <div className="task-status error">
            <p>{task_completed_fail}</p>
          </div>
        )}
        {!taskCompletedSuccess && !taskCompletedFail && (
          <div>
            <p className="title">{task_completed_report}</p>
            <div className="text-area-wrapper">
              <textarea
                onFocus={() => catchFocus(true)}
                value={notes}
                onChange={handleTextChange}
                rows={6}
                placeholder={add_notes}
              />
            </div>
            <p className="error">{error}</p>
          </div>
        )}
        <div className="btn-holder">
          <OrangeButton
            extraClass={"btn-task-completed"}
            onClick={() => {
              onCloseClicked()
            }}
            disabled={isLoading}
          >
            {taskCompletedFail || taskCompletedSuccess ? "סגור" : "ביטול"}
          </OrangeButton>
          {taskCompletedFail || taskCompletedSuccess ? null : (
            <OrangeButton
              extraClass={"btn-task-completed"}
              onClick={() => {
                confirmTask()
              }}
              disabled={isLoading}
            >
              {"אישור"}
            </OrangeButton>
          )}
        </div>
        <VirtualKeyboard
          currentValue={notes}
          onKeyboardInputChange={(txt) => setNotes(txt)}
          show={isTextAreaFocused}
          lang="he"
          maxLength={500}
        />
      </div>
    </div>
  )
}

const mapStateToProps = ({ translate, stationTasks }) => {
  const {
    dirRtl,
    btn_text_completed,
    close_text,
    task_completed_confirmation,
    yes_text,
    no_text,
    task_completed_report,
    add_notes,
    task_completed_success,
    task_completed_fail,
    validate_notes,
  } = translate

  const { taskCompletedSuccess, taskCompletedFail, isLoading } = stationTasks
  return {
    isLoading,
    dirRtl,
    btn_text_completed,
    close_text,
    task_completed_confirmation,
    yes_text,
    no_text,
    task_completed_report,
    add_notes,
    taskCompletedSuccess,
    taskCompletedFail,
    task_completed_success,
    task_completed_fail,
    validate_notes,
  }
}

export default connect(mapStateToProps, {
  taskAccomplishedReport,
})(CompleteTask)
