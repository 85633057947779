import React from "react"
import Modal from "react-modal"
import { OrangeButton } from "../../common"
import alertIcon from "../../../assets/svg/alert-icon-info.svg"
import "./style.scss"

const CheckLockerModal = ({
  show,
  title,
  onModalCloseClick,
  dirRtl,
  className,
  showReprtEmptyLocker,
  showIfOccupiedLocker,
  reportEmptyLocker,
  emptyLockerBtnText,
  infoEmptyLockerText1,
  infoEmptyLockerText2,
  closeBtnText,
}) => {
  if (!show) {
    return <noscript />
  }
  const longView =
    reportEmptyLocker && showReprtEmptyLocker ? " long-modal-view" : ""
  return (
    <Modal
      isOpen={show}
      onRequestClose={onModalCloseClick}
      className={"react-modal-content " + className + longView}
      overlayClassName="modal-overlay"
    >
      <div className="wheelchair-wrapper">
        <img width={100} src={alertIcon} alt="alertIcon" />
      </div>
      {showIfOccupiedLocker && (
        <>
          <div
            className="errinm-label-extra-class check-locker-title"
            dir={dirRtl ? "rtl" : "ltr"}
          >
            {title}
          </div>

          <OrangeButton onClick={(e) => onModalCloseClick()}>
            {closeBtnText}
          </OrangeButton>
        </>
      )}
      {!showIfOccupiedLocker && (
        <OrangeButton
          extraClass="bottom30"
          onClick={(e) => onModalCloseClick()}
        >
          {closeBtnText}
        </OrangeButton>
      )}
      {reportEmptyLocker && showReprtEmptyLocker && (
        <>
          <div
            className="errinm-label-extra-class check-locker-title"
            dir={dirRtl ? "rtl" : "ltr"}
          >
            {infoEmptyLockerText1}
            <br />
            {infoEmptyLockerText2}
          </div>
          <OrangeButton onClick={(e) => reportEmptyLocker(e)}>
            {emptyLockerBtnText}
          </OrangeButton>
        </>
      )}
    </Modal>
  )
}

export default CheckLockerModal
