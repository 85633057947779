import React from "react"
import BarcodeReader from "../../../assets/images/orange_locker.png"
import Modal from "react-modal"
import "./style.css"
import Loader from "../../common/Loader"

const BarcodeReaderInfoModal = ({ show, title, onModalCancelClick }) => {
  return (
    <Modal
      isOpen={show}
      onRequestClose={onModalCancelClick}
      className={`react-modal-content `}
      overlayClassName="modal-overlay"
    >
      <div className="barcode-reader-container">
        <div className="barcode-reader-title">{title}</div>
        <div className="barcode-reader">
          <img src={BarcodeReader} alt="barcode_reader" />
        </div>
        <Loader />
      </div>
    </Modal>
  )
}

export default BarcodeReaderInfoModal
