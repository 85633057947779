import React from "react"
import "./style.css"

const OrangeButton = ({
  show = true,
  children,
  extraClass,
  isDisabled = false,
  ...props
}) => {
  if (!show) {
    return null
  }

  return (
    <button
      className={`blbtn-wrapper ${extraClass}`}
      {...props}
      tabIndex={isDisabled ? -1 : 0}
      disabled={isDisabled}
    >
      {children}
    </button>
  )
}

export { OrangeButton }
