import React, { Component } from "react"
import { withRouter } from "react-router-dom"
import packageGuy from "../../../assets/svg/package_guy.svg"
import deliveryGirl from "../../../assets/svg/delivery_girl.svg"
import laundryGuy from "../../../assets/svg/laundry_guy.svg"
import lockerToRent from "../../../assets/svg/rent_locker.svg"
import dhlLogo from "../../../assets/svg/dhl_logo.svg"
import exelotLogo from "../../../assets/images/exelot_shein_logo.png"
import oneProjectLogo from "../../../assets/images/one_project_logo_white.png"
import hfdLogo from "../../../assets/images/epost_logo.png"
import returnsExternalLogo from "../../../assets/svg/returns_external_logo.svg"

import "./style.scss"

class ServiceMenuButton extends Component {
  imageSrc(serviceType) {
    if (serviceType === "laundry") {
      return laundryGuy
    }
    if (serviceType === "delivery") {
      return packageGuy
    }
    if (serviceType === "locker-delivery") {
      return deliveryGirl
    }
    if (serviceType === "locker-rent") {
      return lockerToRent
    }
    if (serviceType === "dhl") {
      return dhlLogo
    }
    if (serviceType === "exelot") {
      return exelotLogo
    }
    if (serviceType === "oneProject") {
      return oneProjectLogo
    }
    if (serviceType === "hfd") {
      return hfdLogo
    }
    if (serviceType === "returnsExternal") {
      return returnsExternalLogo
    }
  }

  isDeliveryMenu() {
    return this.props.history.location.pathname === "/ServiceDeliveryMenuScreen"
  }

  render() {
    const { show, serviceType, onClick, infoText, dirRtl, middleImg } =
      this.props
    let { text } = this.props
    text = text != null ? text : ""
    if (show === undefined || !show) {
      return <noscript></noscript>
    }

    return (
      <div
        className={`${
          this.isDeliveryMenu()
            ? "delivery-service-menu-button " + serviceType + "-wrapper"
            : "service-menu-button " + serviceType + "-wrapper"
        } `}
        onClick={(e) => onClick(e)}
      >
        <div className="orange-part">
          <img
            className={serviceType}
            src={this.imageSrc(serviceType)}
            alt="card_img"
          />
        </div>
        <div
          className={`${
            this.isDeliveryMenu() ? "big-white-part" : "white-part"
          }`}
        >
          {this.isDeliveryMenu() && middleImg && (
            <img className="middle-img" src={middleImg} alt="middle-img" />
          )}
          <div className="card-text" dir={dirRtl ? "rtl" : "ltr"}>
            {text}
          </div>
          <div className="package-delivery-info" dir={dirRtl ? "rtl" : "ltr"}>
            {infoText}
          </div>
        </div>
      </div>
    )
  }
}

export default withRouter(ServiceMenuButton)
