import React, { Component } from "react"
import { connect } from "react-redux"

import AppAppleStore from "../../../assets/svg/app_apple_store.svg"
import AppGooglePlay from "../../../assets/svg/app_google_play.svg"

import "./style.css"

class AppQRCodeButton extends Component {
  render() {
    const { show, app_link_title, app_link_text, qr_link_text, dirRtl } =
      this.props

    if (show == undefined || !show) {
      return <noscript></noscript>
    }

    const dir = dirRtl ? "rtl" : "ltr"

    return (
      <div
        className={"app-qr-code-button " + dir}
        dir={dir}
        onClick={(e) => this.props.onClick(e)}
      >
        <div className="white-part">
          <div className="app-info">
            <div className="app-title">{app_link_title}</div>
            <div className="app-text">{app_link_text}</div>
            <div className="app-text">{qr_link_text}</div>
          </div>
          <div className="app-icons">
            <img
              className="google-play-icon"
              src={AppGooglePlay}
              alt="google play"
            />
            <img
              className="apple-store-icon"
              src={AppAppleStore}
              alt="apple store"
            />
          </div>
        </div>
      </div>
    )
  }
}

const mapStateToProps = ({ translate }) => {
  const { dirRtl, langName, app_link_title, app_link_text, qr_link_text } =
    translate

  return {
    dirRtl,
    langName,
    app_link_title,
    app_link_text,
    qr_link_text,
  }
}

export default connect(mapStateToProps, {})(AppQRCodeButton)
