import React from "react"

import "./style.css"

const TableFooter = ({ lable, value, discountText }) => {
  return (
    <div className="tb-wrapper">
      <div className="discount-info">{discountText}</div>
      <div className="amount-info">
        {lable} {value}
      </div>
    </div>
  )
}

export default TableFooter
