import React from 'react';
import { WhiteSquare } from '../../../../common';

import './style.css';

const ItemsSquares = ({ onItemClick, squaresTranslations }) => {

    const {
        ironing, laundry, ironing_and_package, dry_cleaning,
        laundry_ironing_package, laundry_ironing
    } = squaresTranslations;

    return (
        <div className='itsq-wrapper'>
            <div className='itsq-inner-wrapper'>
                <WhiteSquare
                    onWhiteSquareClick={(icon, title) => onItemClick(icon, title)}
                    icon='ironing' title={ironing}
                />
                <WhiteSquare
                    onWhiteSquareClick={(icon, title) => onItemClick(icon, title)}
                    icon='laundry' title={laundry}
                />
                <WhiteSquare
                    onWhiteSquareClick={(icon, title) => onItemClick(icon, title)}
                    icon='ironing and package' title={ironing_and_package}
                />
            </div>
            <div className='itsq-inner-wrapper'>
                <WhiteSquare
                    onWhiteSquareClick={(icon, title) => onItemClick(icon, title)}
                    icon='dry cleaning' title={dry_cleaning}
                />
                <WhiteSquare
                    onWhiteSquareClick={(icon, title) => onItemClick(icon, title)}
                    icon='laundry ironing package' title={laundry_ironing_package}
                />
                <WhiteSquare
                    onWhiteSquareClick={(icon, title) => onItemClick(icon, title)}
                    icon='laundry ironing' title={laundry_ironing}
                />
            </div>
        </div>
    );
}

export default ItemsSquares;
