import React, { Component } from "react"
import { withRouter } from "react-router-dom"
import { connect } from "react-redux"

import {
  fetchAllLockersStatuses,
  fetchAllLockersLayout,
  fetchOrderTypes,
  fetchLockersStatusesForRent,
  setChosenCell,
} from "../../../actions"
import { getOrderTypeTextFromOrderType } from "../../../utils/OrderType"
import Cell from "./Cell"
import "./style.css"

class LockerCells extends Component {
  constructor(props) {
    super(props)

    this.state = {
      mainRef: null,
      calledSmoothScroll: false,
    }
  }

  componentDidMount() {
    const { stationId, isNeedAccessibility, orderTypesArr, lockersLayout } =
      this.props
    console.log("isNeedAccessibility", isNeedAccessibility)
    if (!orderTypesArr || orderTypesArr.length === 0) {
      this.props.fetchOrderTypes()
    }

    if (this.props.lockersForRent || this.props.lockerToLockerDelivery) {
      this.props.fetchLockersStatusesForRent(
        stationId,
        "lockersForRent",
        isNeedAccessibility
      )
    } else if (this.props.shipingPackage) {
      this.props.fetchLockersStatusesForRent(
        stationId,
        "shipingPackage",
        isNeedAccessibility
      )
    } else {
      this.props.fetchAllLockersStatuses(stationId, isNeedAccessibility)
    }
    if (!lockersLayout || lockersLayout.length === 0) {
      this.props.fetchAllLockersLayout(stationId)
    }
  }

  async onCellClick(cellNum) {
    const { lockersData, lockersForRent, setChosenCell } = this.props
    const currCell = lockersData[cellNum - 1]

    if (this.props.onCellClick && currCell && !currCell.disabled) {
      this.props.onCellClick(cellNum, currCell)
    }

    if (currCell && !currCell.disabled && lockersForRent) {
      await setChosenCell(cellNum)
      this.props.history.push("OrderItemsDepositScreen", "rentLocker")
    }
  }
  maxHorizontal(cellsArr) {
    if (cellsArr.length < 1) return

    let maxHorizontal = cellsArr
      .map((arr) => arr.length)
      .reduce((max, curr) => Math.max(max, curr))

    return maxHorizontal
  }

  renderCells(
    cellsArr,
    blinkers,
    greens,
    reds,
    blacks,
    greys,
    blues,
    yellows,
    errLockers,
    isAccessibles
  ) {
    const { lockersData, hideInfo, orderTypesArr, whiteCells } = this.props

    const res = []
    if (cellsArr.length < 1) return

    // CALCULATING THE REPUDING FROM THE RIGHT SIDE.. :
    let maxHorizontal = this.maxHorizontal(cellsArr)
    cellsArr.push(new Array(0)) // RIPUD MILEMATA..!

    for (let j = 0; j < maxHorizontal; j++) {
      const iterationRes = []

      let cellNum = cellsArr[0][j]
      let currCellSize = 0

      for (let i = 0; i < cellsArr.length; i++) {
        let currCell = cellsArr[i][j]

        if (cellNum !== currCell) {
          const orderData = lockersData[cellNum - 1]
            ? lockersData[cellNum - 1].order
            : null

          if (orderData) {
            orderData.orderTypeName = getOrderTypeTextFromOrderType(
              orderTypesArr,
              orderData.orderType
            )
          }

          iterationRes.push(
            <Cell
              num={cellNum}
              size={currCellSize}
              blink={blinkers[`${cellNum}`]}
              red={reds[`${cellNum}`]}
              green={greens[`${cellNum}`]}
              black={blacks[`${cellNum}`]}
              grey={greys[`${cellNum}`]}
              blue={blues[`${cellNum}`]}
              yellow={yellows[`${cellNum}`]}
              accessible={isAccessibles[`${cellNum}`]}
              errLocker={errLockers[`${cellNum}`]}
              hideInfo={hideInfo}
              whiteCells={whiteCells}
              order={orderData}
              onClick={this.onCellClick.bind(this)}
              key={`${i} ${j}`}
              onRefReady={this.handleCellRef.bind(this)}
            />
          )

          cellNum = currCell
          currCellSize = 1
        } else {
          currCellSize++
        }
      }

      res.push(
        <div className="lockcell-col-wrap" key={res.length}>
          {iterationRes}
        </div>
      )
    }

    return res
  }

  handleCellRef(cellNum, ref) {
    const { mainRef } = this.state
    const { blinkers, showMinified } = this.props

    if (!showMinified) return

    const { calledSmoothScroll } = this.state

    if (calledSmoothScroll) {
      return
    }

    // JUMPINT TO THE BLINKING ELEMENT..
    if (blinkers[`${cellNum}`] && mainRef && ref) {
      this.setState({ calledSmoothScroll: true })

      const refRect = ref.getBoundingClientRect()
      const mainRect = mainRef.getBoundingClientRect()

      const scrollLeftBy = refRect.x - mainRect.x - 10

      mainRef.scrollLeft += scrollLeftBy
      // TODO - FIX WHEN THERE'S TIME
      // setTimeout(() => this.scrollSmoothly(mainRef, scrollLeftBy), 1000);
    }
  }

  scrollSmoothly(mainRef, scrollLeftBy, iteration = 0) {
    if (iteration === 100) {
      return
    }
    mainRef.scrollLeft += scrollLeftBy / 100
    setTimeout(
      () => this.scrollSmoothly(mainRef, scrollLeftBy, iteration + 1),
      scrollLeftBy / 50
    )
  }

  onMainRefReady(ref) {
    const { mainRef } = this.state

    if (!mainRef) {
      this.setState({ mainRef: ref })
    }
  }

  render() {
    const { lockersLayout } = this.props

    const {
      greens,
      reds,
      blacks,
      yellows,
      greys,
      blues,
      isAccessibles,
      blinkers = [],
      errLockers = [],
      extraClass,
    } = this.props
    const lockCellClassName = `lockcell-wrapper ${extraClass} ${
      this.maxHorizontal(lockersLayout) < 7 && "centered"
    }`

    return (
      <div
        className={lockCellClassName}
        ref={(ref) => this.onMainRefReady(ref)}
      >
        {this.renderCells(
          lockersLayout,
          blinkers,
          greens,
          reds,
          blacks,
          greys,
          blues,
          yellows,
          errLockers,
          isAccessibles
        )}
      </div>
    )
  }
}

const mapStateToProps = ({
  lockersCells,
  bgService,
  orderTypes,
  userLogin,
}) => {
  const { lockersData, lockersLayout } = lockersCells
  const { stationId } = bgService
  const { isNeedAccessibility } = userLogin
  const { orderTypesArr } = orderTypes
  const blacks = {}
  const yellows = {}
  const reds = {}
  const greens = {}
  const greys = {}
  const blues = {}
  const isAccessibles = {}

  lockersData.map(
    ({ lockerStatus, isAccessible, lockerName, disabled, pickUpDisabled }) => {
      if (lockerStatus === 4) blacks[parseInt(lockerName)] = 1
      if (lockerStatus === 0) yellows[parseInt(lockerName)] = 1
      if (lockerStatus === 1) reds[parseInt(lockerName)] = 1
      if (lockerStatus === 2) greens[parseInt(lockerName)] = 1
      if (disabled) greys[parseInt(lockerName)] = 1
      if (pickUpDisabled) blues[parseInt(lockerName)] = 1
      if (isAccessible) isAccessibles[parseInt(lockerName)] = 1
      return null
    }
  )

  return {
    lockersData,
    lockersLayout,
    orderTypesArr,
    blacks,
    yellows,
    reds,
    greens,
    greys,
    blues,
    stationId,
    isAccessibles,
    isNeedAccessibility,
  }
}

export default connect(mapStateToProps, {
  fetchAllLockersStatuses,
  fetchAllLockersLayout,
  fetchOrderTypes,
  fetchLockersStatusesForRent,
  setChosenCell,
})(withRouter(LockerCells))
