import React from "react"
import Modal from "react-modal"
import { OrangeButton, PendingSuccessFailBtn } from "../../common"
import "./style.scss"

const ErrorInfoModalWithIconText = ({
  show,
  title,
  text1,
  text2,
  icon,
  iconInfoText,
  onModalCloseClick,
  dirRtl,
  closeTrnsl,
}) => {
  if (!show) {
    return <noscript />
  }

  //   dirRtl ? "dirrtl" : "dirltr"

  return (
    <Modal
      isOpen={show}
      onRequestClose={onModalCloseClick}
      className="react-modal-content scan-waybill-barcode-error"
      overlayClassName="modal-overlay"
    >
      <PendingSuccessFailBtn status={-1} />
      <div className="errinm-label-extra-class" dir={dirRtl ? "rtl" : "ltr"}>
        {title}
      </div>
      <div
        className="errinm-label-extra-class error-text1"
        dir={dirRtl ? "rtl" : "ltr"}
      >
        {text1}
      </div>
      <div
        className="errinm-label-extra-class error-text1"
        dir={dirRtl ? "rtl" : "ltr"}
      >
        {text2}
      </div>
      <div className="error-info" dir={dirRtl ? "ltr" : "rtl"}>
        {icon && (
          <div className="error-info-icon">
            <img src={icon} alt="Icon" />
          </div>
        )}
        <div className="error-info-text" dir={dirRtl ? "rtl" : "ltr"}>
          {iconInfoText}
        </div>
      </div>
      <OrangeButton onClick={(e) => onModalCloseClick()}>
        {closeTrnsl}
      </OrangeButton>
    </Modal>
  )
}

export default ErrorInfoModalWithIconText
