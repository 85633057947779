import React, { useState } from "react"
import Modal from "react-modal"
import { DisableEnableButton, OrangeButton } from "../../common"
import VirtualKeyboard from "../../common/VirtualKeyboard"

import "./style.scss"

const ManualPackageSearchModal = ({
  show,
  onCloseClicked,
  title,
  onClickSearch,
  btnText,
  dirRtl,
}) => {
  const [barcode, setBarcode] = useState("")

  return (
    <Modal
      isOpen={show}
      onRequestClose={() => {
        setBarcode("")
        onCloseClicked()
      }}
      className="react-modal-content manual-package-search"
      overlayClassName="modal-overlay"
    >
      <div className="dms-title" dir={dirRtl ? "rtl" : "ltr"}>
        {title}
      </div>
      <div className="barcode-row">
        <OrangeButton
          onClick={() => {
            setBarcode("")
            onCloseClicked()
          }}
        >
          סגור
        </OrangeButton>
        <DisableEnableButton
          onClick={() => {
            onClickSearch(barcode)
            setBarcode("")
            onCloseClicked()
          }}
          isDisabled={!barcode}
        >
          {btnText}
        </DisableEnableButton>
        <input
          className="collect-input-barcode"
          name="barcode"
          value={barcode}
          onChange={(e) => {
            setBarcode(e.target.value)
          }}
          maxLength={50}
          autoFocus
        />
      </div>

      <VirtualKeyboard
        currentValue={barcode}
        onKeyboardInputChange={(txt) => setBarcode(txt)}
        show={true}
        lang="en_modal"
      />
    </Modal>
  )
}

export default ManualPackageSearchModal
