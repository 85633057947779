import React, { Component } from "react"
import { connect } from "react-redux"
import BarcodeReader from "react-barcode-reader"
import {
  logScreenEnterance,
  reportFBEvent,
  setShipingPackageLoading,
  setShipingPackageOrder,
  validateShipingPackageNumber,
  readBgServiceConfiguration,
  readStationIdFromBgService,
  reportSuspiciousBrokenLockers,
  clearShipingPackage,
  clearValidationFields,
} from "../../../../../actions"
import { providerManager } from "../../../../../providers/providerManager"
import ErrorInfoModal from "../../../../Modals/ErrorInfoModal"
import { FixedFooter, FixedHeader } from "../../../../reduxified"
import OrangeLocker from "../../../../../assets/images/locker.png"

import QRcode from "../../../../../assets/images/qrcode_dhl.png"

import "./style.scss"

import ErrorInfoModalWithIconText from "../../../../Modals/ErrorInfoModalWithIconText"
import LoaderModal from "../../../../Modals/LoaderModal"

class ScanQRcodeForReturn extends Component {
  constructor(props) {
    super(props)

    this.INIT_STATE = {
      showScanError: false,
      showErrorModal: false,
      showBaseErrorModal: false,
      serviceName: "shipingPackage",
      showNotValidBarcodeErrorModal: false,
      showNotAllowedStationErrorModal: false,
      packageNumber: null,
      externalOrderNumber: null,
    }

    this.state = this.INIT_STATE
    this.onGetBarcode = this.onGetBarcode.bind(this)
    this.handleScanError = this.handleScanError.bind(this)
  }

  componentDidMount() {
    this.props.clearValidationFields()
    this.setState(this.INIT_STATE)
    this.props.logScreenEnterance(
      "SCAN_QRCODE_FOR_RETURN_SCREEN",
      this.props.history.location.state
    )
    const { stationId } = this.props
    const from = this.props.history.location.state
    this.setState({ serviceName: this.props.history.location.state })

    if (
      stationId === null ||
      stationId < 0 ||
      stationId === undefined ||
      from === ""
    ) {
      this.props.reportFBEvent(
        "ScanQRcodeForReturnScreen",
        "No stationId. Redirect to homepage."
      )
      this.props.history.push("/")
    }
    this.props.clearShipingPackage()
  }

  componentWillReceiveProps(newProps) {
    const {
      errTitle,
      errText,
      stationId,
      isError,
      order,
      isPackageNumberValid,
      isPackageNumberExist,
      isStationAllowed,
      isLoading,
      history,
    } = newProps
    this.setState({ showBaseErrorModal: isError })
    const { serviceName } = this.state

    if (
      isPackageNumberExist ||
      (!isPackageNumberValid && !isLoading && this.state.packageNumber)
    ) {
      this.setState({ showNotValidBarcodeErrorModal: true })
    }

    if (
      !isPackageNumberExist &&
      isPackageNumberValid &&
      !isLoading &&
      this.state.packageNumber &&
      !isStationAllowed
    ) {
      this.setState({ showNotAllowedStationErrorModal: true })
    }

    if (
      isPackageNumberValid &&
      !isPackageNumberExist &&
      !isLoading &&
      this.state.packageNumber &&
      isStationAllowed
    ) {
      order.packageNumber = this.state.packageNumber
      order.externalOrderNumber = this.state.externalOrderNumber
      order.haveQRcode = true
      this.props.setShipingPackageOrder(order)
      history.push("/ShipingPackageScreen/SenderDetails", serviceName)
    }

    const { errTitle: oldErrTitle, errText: oldErrText } = this.props

    if (oldErrTitle !== errTitle || oldErrText !== errText) {
      this.setState({
        showErrorModal: true,
      })
    }

    if (!stationId || stationId === null || stationId < 0) {
      this.props.reportFBEvent(
        "ScanQRcodeForReturnScreen",
        "No stationId. Redirect to homepage."
      )
      this.props.history.push("/")
    }
  }

  onGetBarcode(barcode) {
    console.log("onGetBarcode:")
    console.log(barcode)
    if (this.props.isLoading) {
      return
    }
    const { serviceName } = this.state
    this.setState({ packageNumber: null, externalOrderNumber: null })
    const params = barcode.split("|")

    const isQRcode = params && params.length === 15
    console.log("isQRcode:" + isQRcode)
    if (!isQRcode) {
      this.handleScanError(barcode)
      return
    }
    console.log("params:")
    console.log(params)
    console.log("packageNumber:" + params[2])
    this.setState({
      showScanError: false,
      showErrorModal: false,
      packageNumber: params[2],
      externalOrderNumber: barcode,
    })
    const orderType = providerManager.orderType(serviceName)

    this.props.setShipingPackageLoading()

    this.props.validateShipingPackageNumber(
      params[2],
      orderType,
      this.props.stationNumber
    )
  }

  handleScanError(barcode) {
    const { serviceName } = this.state
    this.props.reportFBEvent(
      "ScanQRcodeForReturnScreen",
      "Error scan for " + serviceName + " QRCode:" + barcode
    )

    this.setState({ showScanError: true })
  }

  render() {
    const {
      showScanError,
      showErrorModal,
      showBaseErrorModal,
      serviceName,
      showNotValidBarcodeErrorModal,
      showNotAllowedStationErrorModal,
    } = this.state

    const {
      errTitle,
      errText,
      dirRtl,
      langName,
      close_text,
      login_laundry,
      base_error_title,
      base_error_text,
      history,
      scan_qrcode_error_title,
      scan_qrcode_error_text1,
      scan_qrcode_error_text2,
      scan_qrcode_error_info_text,
      additional_scan_try,
      isLoading,
      please_wait,
      shiping_package_qrcode_scan_title,
      shiping_package_qrcode_scan_text,
      shiping_package_qrcode_scan_info,
      not_valid_barcode_error_title,
      not_valid_barcode_error_text,
      isPackageNumberExist,
      qrcode_exist_error_title,
      barcode_exist_error_text,
      not_allowed_station_title,
      not_allowed_station_error_text,
      exit_btn_text,
    } = this.props
    console.log("serviceName: " + serviceName)

    const barcodeMinLength = providerManager.barcodeMinLength(serviceName)
    return (
      <div className={"ls-wrapper shiping-barcode-scan"}>
        <FixedHeader
          title={login_laundry}
          subTitle=""
          buttonsFlag="scan_waybill"
        />

        <div className="shiping-qrcode-scan-container ">
          <div className="title-text">
            <h1 dir={dirRtl ? "rtl" : "ltr"}>
              {shiping_package_qrcode_scan_title}
            </h1>
          </div>

          {providerManager.renderLogo(serviceName)}

          {serviceName === "dhl" && (
            <div className="barcode-reader-info dhl" dir="rtl">
              <img
                className="locker-icon"
                src={OrangeLocker}
                alt="DONE Locker"
              />
              <img
                className="rounded-barcode-icon qrcode-dhl"
                src={QRcode}
                alt="DHL qrcode"
              />

              <div className="info-text">
                {shiping_package_qrcode_scan_text}
              </div>

              <div
                className={
                  "info-text-bottom-wrapper " +
                  (dirRtl ? "rtl" : "ltr") +
                  " " +
                  langName
                }
              >
                <div className="info-text-bottom">
                  {scan_qrcode_error_info_text}
                  {/*shiping_package_qrcode_scan_info*/}
                </div>
                <div className="info-bottom-img">
                  <img className="qrcode-dhl" src={QRcode} alt="DHL qrcode" />
                </div>
              </div>
            </div>
          )}

          <BarcodeReader
            minLength={30}
            onError={(e) => this.handleScanError(e)}
            onScan={(e) => this.onGetBarcode(e)}
          />
        </div>

        <LoaderModal show={isLoading} title={please_wait} />
        {serviceName === "dhl" && (
          <ErrorInfoModalWithIconText
            dirRtl={dirRtl}
            show={(showErrorModal && errTitle && errText) || showScanError}
            title={scan_qrcode_error_title}
            text1={scan_qrcode_error_text1}
            text2={scan_qrcode_error_text2}
            //icon={QRcode}
            //iconInfoText={scan_qrcode_error_info_text}
            iconInfoText=""
            closeTrnsl={additional_scan_try}
            onModalCloseClick={() => {
              this.setState({ showErrorModal: false, showScanError: false })
              this.props.clearValidationFields()
            }}
          />
        )}

        <ErrorInfoModal
          dirRtl={dirRtl}
          show={showNotValidBarcodeErrorModal}
          title={
            isPackageNumberExist
              ? qrcode_exist_error_title
              : not_valid_barcode_error_title
          }
          text={
            isPackageNumberExist
              ? barcode_exist_error_text
              : not_valid_barcode_error_text
          }
          onModalCloseClick={() => {
            this.setState({ showNotValidBarcodeErrorModal: false })
          }}
          closeTrnsl={additional_scan_try}
          className="shiping-barcode-scan"
        />

        <ErrorInfoModal
          dirRtl={dirRtl}
          show={showNotAllowedStationErrorModal}
          title={not_allowed_station_title}
          text={not_allowed_station_error_text}
          onModalCloseClick={() => {
            this.setState({ showNotAllowedStationErrorModal: false })
            this.props.history.push("/")
          }}
          closeTrnsl={exit_btn_text}
          className="shiping-barcode-scan"
        />

        <ErrorInfoModal
          dirRtl={dirRtl}
          show={showBaseErrorModal}
          title={base_error_title}
          text={base_error_text}
          onModalCloseClick={() => {
            this.setState({ showBaseErrorModal: false })
          }}
          closeTrnsl={close_text}
          className="shiping-barcode-scan"
        />

        <FixedFooter history={history} />
      </div>
    )
  }
}

const mapStateToProps = ({
  userLogin,
  bgService,
  translate,
  shipingPackage,
}) => {
  const { stationId, stationNumber } = bgService
  const {
    order,
    isPackageNumberValid,
    isPackageNumberExist,
    isLoading,
    isStationAllowed,
  } = shipingPackage
  const {
    authenticated,
    errTitle,
    errText,
    isLoadingRequest,
    userId,
    isError,
  } = userLogin
  const {
    dirRtl,
    langName,
    close_text,
    login_laundry,
    enter,
    base_error_title,
    base_error_text,
    scan_qrcode_error_title,
    scan_qrcode_error_text1,
    scan_qrcode_error_text2,
    scan_qrcode_error_info_text,
    additional_scan_try,
    please_wait,
    shiping_package_qrcode_scan_title,
    shiping_package_qrcode_scan_text,
    shiping_package_qrcode_scan_info,
    not_valid_barcode_error_title,
    not_valid_barcode_error_text,
    qrcode_exist_error_title,
    barcode_exist_error_text,
    not_allowed_station_title,
    not_allowed_station_error_text,
    exit_btn_text,
  } = translate

  return {
    authenticated,
    errTitle: translate[errTitle],
    errText: translate[errText],
    isLoadingRequest,
    userId,
    stationId,
    stationNumber,
    isError,
    enter,
    dirRtl,

    langName,
    close_text,
    login_laundry,
    base_error_title,
    base_error_text,
    scan_qrcode_error_title,
    scan_qrcode_error_text1,
    scan_qrcode_error_text2,
    scan_qrcode_error_info_text,
    additional_scan_try,
    please_wait,
    shiping_package_qrcode_scan_title,
    shiping_package_qrcode_scan_text,
    shiping_package_qrcode_scan_info,
    not_valid_barcode_error_title,
    not_valid_barcode_error_text,
    qrcode_exist_error_title,
    barcode_exist_error_text,
    order,
    isPackageNumberValid,
    isLoading,
    isPackageNumberExist,
    isStationAllowed,
    not_allowed_station_title,
    not_allowed_station_error_text,
    exit_btn_text,
  }
}

export default connect(mapStateToProps, {
  validateShipingPackageNumber,
  setShipingPackageLoading,
  setShipingPackageOrder,
  readStationIdFromBgService,
  readBgServiceConfiguration,
  reportSuspiciousBrokenLockers,
  logScreenEnterance,
  reportFBEvent,
  clearShipingPackage,
  clearValidationFields,
})(ScanQRcodeForReturn)
