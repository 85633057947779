import { 
    SET_PAGE_AS_VISITED,

    ON_USER_SIGN_IN_SUCCESS,
    ON_USER_SIGN_OUT,
    RESET_ALL_REDUCERS,
} from '../actions/types';


const INIT_STATE = {
    isCollectOrdersScreenVisited: false,
    isLoadCleanOrdersScreenVisited:false,
};

const VisitingScreensReducer = (state = INIT_STATE, action) => {
    switch (action.type) {
        case RESET_ALL_REDUCERS:
        case ON_USER_SIGN_OUT:
        case ON_USER_SIGN_IN_SUCCESS:
            return {
                ...INIT_STATE
            };
       
        case SET_PAGE_AS_VISITED:
            const pageName = action.payload;
            return {
                ...state,
                ['is'+pageName+'Visited']: true,
            };
               
                
            
        default:
            return state;
    }
}

export default VisitingScreensReducer;
