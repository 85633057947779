import React from 'react';

import './style.css';

const TableHeader = ({ miniHeader, children }) => {

    return (
        <div className={`prls-tabl-hdr-wrapper ${miniHeader ? 'prls-tabl-hdr-mini' : ''}`}>
            {children}
        </div>
    );
}

export default TableHeader;
