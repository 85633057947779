import {
  FETCH_ALL_USER_ACTIVE_ORDERS_SUCCESS,
  RESET_ALL_REDUCERS,
  SET_RENTAL_ORDER,
  ON_GET_ORDERS_REQUESTS_SUCCESS,
  ON_GET_ORDERS_REQUESTS_FAIL,
  ON_ADD_COUPON_TO_ORDER_SUCCESS,
  ON_ADD_COUPON_TO_ORDER_FAIL,
  ON_LOADING,
  FETCH_ORDER_BY_DELIVERY_CODE_SUCCESS,
} from "../actions/types"

const INIT_STATE = {
  userActiveOrders: [],
  displayedOrder: null,
  rentalOrder: null,
  numOfReadyOrdersToCollect: 0,
  ordersRequestsList: [],
  isLoading: false,
}

const ActiveOrdersReducer = (state = INIT_STATE, action) => {
  switch (action.type) {
    case FETCH_ALL_USER_ACTIVE_ORDERS_SUCCESS:
      return handArrivedActiveOrdersResponse(state, action.payload)

    case SET_RENTAL_ORDER:
      return {
        ...state,
        rentalOrder: action.payload,
      }
    case ON_GET_ORDERS_REQUESTS_SUCCESS:
      return {
        ...state,
        ordersRequestsList: action.payload,
        isLoading: false,
      }
    case ON_GET_ORDERS_REQUESTS_FAIL:
      return {
        ...state,
        ordersRequestsList: [],
        isLoading: false,
      }
    case ON_ADD_COUPON_TO_ORDER_SUCCESS:
      return {
        ...state,
        displayedOrder: action.payload,
        rentalOrder: action.payload,
        isLoading: false,
      }

    case ON_ADD_COUPON_TO_ORDER_FAIL:
      return {
        ...state,
        isLoading: false,
        //displayedOrder: action.payload,
      }
    case FETCH_ORDER_BY_DELIVERY_CODE_SUCCESS:
      return {
        ...state,
        numOfReadyOrdersToCollect: action.payload?.numOfReadyOrdersToCollect,
      }
    case ON_LOADING:
      return {
        ...state,
        isLoading: true,
      }
    case RESET_ALL_REDUCERS:
      return {
        ...INIT_STATE,
      }
    default:
      return state
  }
}

const handArrivedActiveOrdersResponse = (state, actionPayload) => {
  const { isRent, ordersList } = actionPayload

  const filteredOrders = ordersList.filter(
    (item) => item.orderStatus === 4 || item.orderStatus === 13
  )

  const displayedOrder = !isRent && filteredOrders[0]

  return {
    ...state,
    isLoading: false,
    userActiveOrders: filteredOrders,
    displayedOrder,
    numOfReadyOrdersToCollect: filteredOrders.length,
    //numOfReadyOrdersToCollect: filteredOrders.length - 1    // EXCEPT THE CURRENT ONE (len - 1..)
  }
}

export default ActiveOrdersReducer
