import axios from 'axios';
import api from '../api';

import { axiosGetRetry, axiosPutRetry } from '../utils';

import {
    ON_RECREATE_USER_PASSWORD_SUCCESS,
    ON_RECREATE_USER_PASSWORD_FAIL,
    ON_RECREATE_USER_PASSWORD_REQUEST,
    ON_UPDATE_USER_PASSWORD_SUCCESS,
    ON_UPDATE_USER_PASSWORD_FAIL,
    ON_UPDATE_USER_PASSWORD_REQUEST,
    RESSET_UPDATE_USER_PASSWORD_FLOW,
} from '../actions/types';

export const recreateUserPassword = mobile => {
    return (dispatch) => {
        dispatch({
            type: ON_RECREATE_USER_PASSWORD_REQUEST
        });

        // axios.get(api.recreateUserPassword(mobile))
        axiosGetRetry(api.recreateUserPassword(mobile))
            .then(res => {
                const { temporaryPassword } = res.data;
                
                dispatch({
                    type: ON_RECREATE_USER_PASSWORD_SUCCESS,
                    payload: temporaryPassword
                });

                console.log(`A NEW PASSWORD WAS SENT TO: `, mobile);
            })
            .catch(err => {
                dispatch({
                    type: ON_RECREATE_USER_PASSWORD_FAIL
                })

                console.log(`FAIL TO A SEND A NEW PASSWORD TO: `, mobile);
            });
    };
};

export const resetUpdatePassword = () => {
    return (dispatch) => {
        dispatch({type: RESSET_UPDATE_USER_PASSWORD_FLOW });
    }
}

export const updateUserPassword = (userdata) => {
    return (dispatch) => {

        dispatch({type: ON_UPDATE_USER_PASSWORD_REQUEST });

        const { password, userId } = userdata;

        //PUT RETRY..!
        axiosPutRetry(api.updateUserPassword(userId), api.updateUserPasswordBody(password))
            .then((response) => {
                dispatch({
                    type: ON_UPDATE_USER_PASSWORD_SUCCESS
                });

                console.log(`A NEW PASSWORD WAS UPDATED`);
            })
            .catch(err => {
                dispatch({
                    type: ON_UPDATE_USER_PASSWORD_FAIL
                });

                console.log(`FAIL TO A UPDATE USER PASSWORD! `, err);
            });
    };
};






