import React, { useState } from "react"
import Modal from "react-modal"
import { OrangeButton } from "../../common"
import { QRCode } from "react-qrcode-logo"
import DhlLogo from "../../../assets/images/dhl_qr_logo.jpg"

import "./style.scss"

const ShowOrderQRcodeModal = ({
  show,
  onCloseClicked,
  title,
  orderData,
  onClickContinue,
  btnText,
  lockerNum,
  user_name_text,
  locker_number,
  order_number,
  package_number,
  dirRtl,
}) => {
  if (!show || !orderData) {
    return <></>
  }

  const {
    orderNumber,
    firstName,
    lastName,
    customerName,
    externalOrderNumber,
    packageNumber,
    accountType,
  } = orderData

  let customerNameDisplay = customerName
  if (
    !customerNameDisplay ||
    customerNameDisplay == null ||
    customerNameDisplay.length < 2
  ) {
    customerNameDisplay = `${firstName} ${lastName}`
  }
  const logoSrc = DhlLogo
  // if (accountType || accountType !== 2) {
  //  logoSrc = ""
  //}
  return (
    <Modal
      isOpen={show}
      onRequestClose={() => {
        onCloseClicked()
      }}
      className="react-modal-content order-qr-content-wrapper"
      overlayClassName="modal-overlay"
    >
      <div className="order-qr-wrapper">
        <div className="order-details-wrapper">
          <div className="order-details-title" dir={dirRtl ? "rtl" : "ltr"}>
            {title}
          </div>
          <div className="order-details-list" dir={dirRtl ? "rtl" : "ltr"}>
            <div className="title-in-list">
              {`${user_name_text}: `}
              <span className="order-detail">{customerNameDisplay}</span>
            </div>
            <div className="title-in-list">
              {`${order_number}: `}
              <span className="order-detail">{orderNumber}</span>
            </div>
            <div className="title-in-list">
              {`${order_number}: `}
              <span className="order-detail">{packageNumber}</span>
            </div>
            <div className="title-in-list">
              {`${locker_number}: `}
              <span className="order-detail">{lockerNum}</span>
            </div>
          </div>
          <QRCode
            value={externalOrderNumber}
            size={300}
            quietZone={30}
            logoImage={logoSrc}
            logoWidth={150}
            logoHeight={30}
            removeQrCodeBehindLogo={true}
          />
          <OrangeButton onClick={(e) => onClickContinue(e)}>
            {btnText}
          </OrangeButton>
        </div>
      </div>
    </Modal>
  )
}

export default ShowOrderQRcodeModal
