import React from "react"
import error from "../../../../assets/svg/error-sign.svg"
import successSign from "../../../../assets/svg/success.svg"
import "./style.css"

const SuccessFailBtn = ({ success }) => {
  return success ? (
    <img src={successSign} alt="_V_" />
  ) : (
    <img src={error} alt="_X_" />
  )
}

export default SuccessFailBtn
