import React, { Component } from "react"
import { connect } from "react-redux"
import {
  fetchAllLockersLayout,
  fetchAllLockersStatuses,
  fetchUserActiveOrders,
  getAllDynamicProducts,
  LogoutUser,
  getOrdersRequests,
  getOrderForPay,
  logScreenEnterance,
  resetLockersErrorsAttemptsOpened,
  clearOrderForConfirmCreate,
} from "../../../../actions"
import { MenuOrangeButton, ScrollBoxWithTitle } from "../../../common"
import Loader from "../../../common/Loader"
import WarningInfoModal from "../../../Modals/WarningInfoModal"
import { FixedFooter, FixedHeader } from "../../../reduxified"
import ItemRow from "./ItemRow"
import iconShirtBlack from "../../../../assets/svg/icon_shirt_black.svg"
import "./style.css"

class UserMenuScreen extends Component {
  constructor(props) {
    super(props)

    this.state = {
      showOutOfLockersModal: false,
    }
  }

  componentDidMount() {
    this.props.logScreenEnterance("USER_MENU_SCREEN")
    const { authenticated } = this.props
    if (!authenticated) {
      this.props.LogoutUser()
      this.props.history.push("")
    }
    const { userId, stationId, langName, isNeedAccessibility, lockersLayout } =
      this.props

    this.props.resetLockersErrorsAttemptsOpened()
    this.props.fetchUserActiveOrders(userId, stationId)
    this.props.getOrdersRequests(stationId, userId, 1)
    this.props.fetchAllLockersStatuses(stationId, isNeedAccessibility)
    if (!lockersLayout || lockersLayout.length === 0) {
      this.props.fetchAllLockersLayout(stationId)
    }
    this.props.getAllDynamicProducts(stationId, langName)
  }

  onNewOrderClick() {
    //TODO CLEAR PRE ORDER
    this.props.clearOrderForConfirmCreate()
    const { isOutOfAvailableLockers } = this.props
    if (isOutOfAvailableLockers) {
      this.setState({ showOutOfLockersModal: true })
      return
    }
    this.props.history.push("NewOrderScreen")
  }

  redirectToNewOrderIfHaveEmptyLockers() {
    const { isOutOfAvailableLockers } = this.props
    if (isOutOfAvailableLockers) {
      this.setState({ showOutOfLockersModal: true })
      return
    }
    this.props.history.push("NewOrderScreen", "confirmOrder")
  }

  onNewPurchaseClick() {
    this.props.history.push("NewPurchaseScreen")
  }

  onReviewOrderList() {
    this.props.history.push("ReviewOrderList")
  }

  onCollectOrderClick() {
    this.props.history.push("CollectOrderScreen")
  }

  closeOutOfLockersModal() {
    this.setState({ showOutOfLockersModal: false })
  }

  onContinueRequestClick(request) {
    //SET ORDER to CONTINUE
    this.props.getOrderForPay(request.orderId)
    this.redirectToNewOrderIfHaveEmptyLockers()
  }

  renderLoader() {
    const { isLoading } = this.props
    if (!isLoading) {
      return <></>
    }
    return <Loader />
  }

  render() {
    const {
      hideCollectItemsBtn,
      create_new_order,
      collect_order,
      all_lockers_full_err_title,
      all_lockers_full_err_body,
      close_text,
      firstName,
      hello_title_text,
      user_menu_what_sub_title_text,
      dirRtl,
      ordersRequestsList,
      isLoading,
      laundry_request_list_title,
      order_request_btn_text,
      orderItemsNamesTranslation,
    } = this.props
    const { showOutOfLockersModal } = this.state

    return (
      <>
        <div className="user-menu">
          <FixedHeader buttonsFlag="userMenuScreen" />
          <div
            className="ums-title"
            dir={dirRtl ? "rtl" : "ltr"}
          >{`${hello_title_text}, ${firstName}`}</div>
          <div className="ums-subtitle" dir={dirRtl ? "rtl" : "ltr"}>
            {user_menu_what_sub_title_text}
          </div>

          <div className="ums-wrapper">
            <MenuOrangeButton onClick={(e) => this.onNewOrderClick()}>
              {create_new_order}
            </MenuOrangeButton>
            <MenuOrangeButton
              onClick={() => this.onCollectOrderClick()}
              disabled={hideCollectItemsBtn}
            >
              {collect_order}
            </MenuOrangeButton>

            <ScrollBoxWithTitle
              title={laundry_request_list_title}
              dirRtl={dirRtl}
              show={
                !isLoading &&
                ordersRequestsList &&
                ordersRequestsList.length > 0
              }
              icon={iconShirtBlack}
            >
              {!isLoading &&
                ordersRequestsList.map((request) => {
                  return (
                    <ItemRow
                      item={request}
                      onClick={(e) => this.onContinueRequestClick(request)}
                      btnText={order_request_btn_text}
                      dir={dirRtl ? "rtl" : "ltr"}
                      names={orderItemsNamesTranslation}
                    />
                  )
                })}
            </ScrollBoxWithTitle>
            {this.renderLoader()}
          </div>

          <WarningInfoModal
            show={showOutOfLockersModal}
            title={all_lockers_full_err_title}
            text={all_lockers_full_err_body}
            onModalCloseClick={() => this.closeOutOfLockersModal()}
            btnText={close_text}
          />
        </div>
        <FixedFooter
          history={this.props.history}
          //hideBack
        />
      </>
    )
  }
}

const mapStateToProps = ({
  userLogin,
  activeOrders,
  lockersCells,
  bgService,
  translate,
}) => {
  const { stationId } = bgService
  const { userId, role, authenticated, firstName, isNeedAccessibility } =
    userLogin
  const { displayedOrder, ordersRequestsList, isLoading } = activeOrders
  const { nextAllAvailableCells } = lockersCells
  const {
    langName,
    dirRtl,
    user_menu_what_sub_title_text,
    user_menu_new_order_btn_text,
    close_text,
    user_menu_watch_orders_btn_text,
    user_menu_item_collection_btn_text,
    user_menu_product_purchase_btn_text,
    all_lockers_full_err_title,
    all_lockers_full_err_body,
    hello_title_text,
    laundry_request_list_title,
    order_request_btn_text,

    ironing_item,
    ironing_and_package_item,
    laundry_ironing_item,
    dry_cleaning_item,
    leather_cleaning_item,
    laundry_item,
    laundry_ironing_package_item,
  } = translate

  const orderItemsNamesTranslation = {
    ironing_item,
    ironing_and_package_item,
    laundry_ironing_item,
    dry_cleaning_item,
    leather_cleaning_item,
    laundry_item,
    laundry_ironing_package_item,
  }

  const hideCollectItemsBtn = !displayedOrder

  const isOutOfAvailableLockers =
    !nextAllAvailableCells || nextAllAvailableCells.length < 1

  return {
    userId,
    dirRtl,
    role,
    authenticated,
    displayedOrder,
    hideCollectItemsBtn,
    isOutOfAvailableLockers,
    stationId,
    firstName,
    langName,
    create_new_order: user_menu_new_order_btn_text,
    watch_order: user_menu_watch_orders_btn_text,
    collect_order: user_menu_item_collection_btn_text,
    purchase_products: user_menu_product_purchase_btn_text,
    all_lockers_full_err_title,
    close_text,
    all_lockers_full_err_body,
    hello_title_text,
    user_menu_what_sub_title_text,
    ordersRequestsList,
    isLoading,
    isNeedAccessibility,
    laundry_request_list_title,
    order_request_btn_text,
    orderItemsNamesTranslation,
  }
}

export default connect(mapStateToProps, {
  fetchUserActiveOrders,
  fetchAllLockersStatuses,
  logScreenEnterance,
  fetchAllLockersLayout,
  getAllDynamicProducts,
  resetLockersErrorsAttemptsOpened,
  LogoutUser,
  getOrdersRequests,
  getOrderForPay,
  clearOrderForConfirmCreate,
})(UserMenuScreen)
