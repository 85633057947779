import axios from "axios"
import React, { Component } from "react"
import Modal from "react-modal"
import { connect } from "react-redux"
import {
  payWithPelecard,
  payWithPelecardToken,
  resetPaymentToTryAgain,
  saveCreditTokenInServer,
} from "../../../actions"
import azureApi from "../../../api/AzureApi"
import api from "../../../api/LocalServiceApi"
import Card_in from "../../../assets/images/Payment/card-in.svg"
import Card_on from "../../../assets/images/Payment/card-on.svg"
import ManualEnter from "../../../assets/svg/enter_credit_card.svg"
import NewCard from "../../../assets/svg/new_credit_card.svg"
import SavedCard from "../../../assets/svg/saved_credit_card.svg"
import { axiosPutRetry } from "../../../utils"
import {
  OrangeButton,
  PendingSuccessFailBtn,
  WhiteCheckBox,
  WhiteLabel,
} from "../../common"
import Loader from "../../common/Loader"
import ErrorInfoModal from "../../Modals/ErrorInfoModal"
import { CardManuallyInputs } from "../../reduxified"
import "./style.css"
class PaymentModal3 extends Component {
  constructor(props) {
    super(props)

    this.INIT_STATE = {
      enterCardDataManually: false,
      showChoiceBtns: true,
      showPayWithNayax: false,
      nayaxPayStatus: null,
      paymentSuccess: false,
      cancelSession: false,
      showError: false,
      isRequestInProgress: false,
    }

    this.state = this.INIT_STATE
  }

  getTitleFromReqState() {
    const { showChoiceBtns, enterCardDataManually } = this.state
    const {
      loadingRequest,
      paymentFailed,
      pay_modal_swipe_title,
      pay_modal_pls_wait_while_loading,
      payment_error,
      payment_success,
      payment_pick_your_option,
      payment_payment_details,
      paymentSuccess,
    } = this.props
    const { nayaxPayStatus, showPayWithNayax } = this.state

    if (loadingRequest) {
      return pay_modal_pls_wait_while_loading
    }

    if (paymentFailed) {
      // return err_title_text;
      return payment_error
    }

    if (paymentSuccess || nayaxPayStatus === 200 || nayaxPayStatus === 409) {
      return payment_success
    }

    if (showChoiceBtns) {
      return payment_pick_your_option
    }

    if (showPayWithNayax) {
      // return pay_modal_swipe_title
      return ""
    }

    if (enterCardDataManually) {
      return payment_payment_details
    }
  }

  renderCloseBtn(show) {
    if (!show) {
      return
    }

    const { close_text } = this.props

    return (
      <OrangeButton
        extraClass="payment-modal-close-btn"
        onClick={() => {
          this.props.onCloseModalClick()
          this.onTryAgainClicked()
        }}
      >
        {close_text}
      </OrangeButton>
    )
  }

  renderPayWithExistingTokenBtn(show) {
    if (!show) {
      return
    }

    const { userTokenInServer, payment_pay_with_last_payment_details } =
      this.props

    if (!userTokenInServer) {
      return
    }

    return (
      <button
        className="payment-method-btn"
        onClick={(e) => this.onPayWithSavedTokenClick()}
      >
        <img src={SavedCard} alt="saved-card" />
        {payment_pay_with_last_payment_details}
      </button>
    )
  }

  renderPaymentInputMagnetOrManualButtons(show) {
    // show = !loadingRequest && !paymentFailed && !paymentSuccess;
    if (!show) {
      return
    }

    const { showChoiceBtns } = this.state

    if (showChoiceBtns) {
      return this.renderPaymentMenu(show)
    }
  }

  renderPaymentMenu(show) {
    const { payment_swipe_a_card, payment_for_manual_input } = this.props

    return (
      <>
        {this.renderPayWithExistingTokenBtn(show)}

        <button
          className="payment-method-btn"
          onClick={() => {
            this.setState({
              showChoiceBtns: false,
              enterCardDataManually: false,
              showPayWithNayax: true,
            })
          }}
        >
          <img src={NewCard} alt="new-card" />
          {payment_swipe_a_card}
        </button>
        <button
          className="payment-method-btn"
          onClick={() => {
            this.setState({
              showChoiceBtns: false,
              enterCardDataManually: true,
            })
          }}
        >
          <img src={ManualEnter} alt="manual-enter" />
          {payment_for_manual_input}
        </button>
      </>
    )
  }

  renderCancelLoader(show) {
    if (!show) return
    const { we_are_canceling_payment } = this.props
    return (
      <div>
        <div className="cancel-loader-title">{we_are_canceling_payment}</div>
        <Loader />
      </div>
    )
  }

  renderPaymentInputManual(show) {
    if (!show) {
      return
    }
    return (
      <CardManuallyInputs
        show={show}
        startPayingProcess={(creditCardData, dateMMYY) =>
          this.startPayingProcess(creditCardData, dateMMYY)
        }
        closeEnterCardDataManually={() => this.closeEnterCardDataManually()}
        closeManualEnter={true}
      />
    )
  }

  closeEnterCardDataManually() {
    this.setState(this.INIT_STATE)
  }

  startPayingProcess(creditCardData, dateMMYY) {
    const { total, orderId } = this.props
    this.props.payWithPelecard(
      creditCardData,
      dateMMYY,
      "id",
      "cvv",
      total,
      orderId
    )
  }

  onPayWithSavedTokenClick() {
    const { userTokenInServer, total, orderId } = this.props
    this.props.payWithPelecardToken(userTokenInServer, total, orderId)
  }

  renderRequestStateSpinnerAndResultButtons(show) {
    if (!show) {
      return
    }

    const {
      loadingRequest,
      paymentFailed,
      showTryAgain,
      showConnectionErrMsg,
      paymentSuccess,
      ok_text,
    } = this.props
    const { nayaxPayStatus } = this.state

    let currOkBtnStatus = loadingRequest
      ? 0
      : paymentFailed
      ? -1
      : paymentSuccess
      ? 1
      : 0

    let statusClass = loadingRequest
      ? " status-loading-request"
      : paymentFailed
      ? " status-payment-failed"
      : paymentSuccess
      ? " status-payment-success"
      : " status-other"

    if (nayaxPayStatus) {
      currOkBtnStatus = nayaxPayStatus === 200 || nayaxPayStatus === 409 ? 1 : 0
      statusClass =
        nayaxPayStatus === 200 || nayaxPayStatus === 409
          ? " status-nayax-pay-success"
          : " status-nayax-pay-fail"
    }

    return (
      <div
        className={"error-or-success-content payment-version-3" + statusClass}
      >
        <PendingSuccessFailBtn
          status={currOkBtnStatus}
          onBtnClick={() => this.onOkClicked()}
        >
          {ok_text}
        </PendingSuccessFailBtn>

        {this.renderTryAgainBtn(showTryAgain)}
        {this.renderConnectionErrMsg(showConnectionErrMsg)}
        {this.renderSuccessSaveTokenCheckboxContinueBtn(currOkBtnStatus === 1)}
      </div>
    )
  }

  renderSuccessSaveTokenCheckboxContinueBtn(show) {
    if (!show) {
      return
    }

    const {
      userTokenInServer,
      pelecardToken,
      payment_press_for_locker_opening,
    } = this.props
    const { nayaxPayStatus } = this.state

    return (
      <div>
        {this.renderSaveMyNewTokenCheckBox(
          userTokenInServer !== pelecardToken && !nayaxPayStatus
        )}

        <OrangeButton
          activable
          extraClass="paymdl2-fade-in paymdl2-err-btn"
          onClick={() => this.onSuccessfulPaymentContinueBtnClick()}
        >
          {payment_press_for_locker_opening}
        </OrangeButton>
      </div>
    )
  }

  renderSaveMyNewTokenCheckBox(show) {
    if (!show) {
      return
    }

    const { saveCardPeleToken } = this.state
    const { payment_save_payment_details_for_future_use, dirRtl } = this.props

    return (
      <div className="paymdl2-fade-in paymdl2-save-token-wrap paymdl2-err-msg">
        <WhiteCheckBox
          checked={saveCardPeleToken}
          onChange={(e) =>
            this.setState({ saveCardPeleToken: e.target.checked })
          }
        />

        <WhiteLabel dirRtl={dirRtl}>
          {payment_save_payment_details_for_future_use}
        </WhiteLabel>
      </div>
    )
  }

  renderTryAgainBtn(show) {
    if (!show) {
      return
    }

    const { pay_modal_click_to_retry, payment_input_error, dirRtl } = this.props
    const {
      loadingRequest,
      paymentFailed,
      showConnectionErrMsg,
      paymentSuccess,
    } = this.props
    const { nayaxPayStatus } = this.state

    let statusClass = loadingRequest
      ? " status-loading-request"
      : paymentFailed
      ? " status-payment-failed"
      : paymentSuccess
      ? " status-payment-success"
      : " status-other"

    if (nayaxPayStatus) {
      statusClass =
        nayaxPayStatus === 200 || nayaxPayStatus === 409
          ? " status-nayax-pay-success"
          : " status-nayax-pay-fail"
    }

    statusClass = showConnectionErrMsg
      ? statusClass + " connection-error"
      : statusClass

    return (
      <div
        className={"error-or-success-content payment-version-3" + statusClass}
      >
        <WhiteLabel
          extraClass="paymdl2-fade-in paymdl2-err-msg"
          dirRtl={dirRtl}
        >
          {payment_input_error}
        </WhiteLabel>

        <OrangeButton
          activable
          extraClass="paymdl2-fade-in paymdl2-err-btn"
          onClick={() => this.onTryAgainClicked()}
        >
          {pay_modal_click_to_retry}
        </OrangeButton>
      </div>
    )
  }

  renderPayByNayax() {
    const {
      choose_the_option,
      attach_to_the_reader,
      insert_into_bottom,
      cancel_payment,
    } = this.props
    return (
      <>
        <div className="nayax-wrapper">
          <div className="card-reader-3-title">{choose_the_option}</div>
          <div className="card-reader-3-wrapper">
            <div className="reader-with-title">
              <p>{attach_to_the_reader}</p>
              <img className="card-reader-3-2" src={Card_on} alt="Card3_2" />
            </div>
            <div className="reader-with-title">
              <p>{insert_into_bottom}</p>
              <img className="card-reader-3-1" src={Card_in} alt="Card3_1" />
            </div>
          </div>
        </div>
        <OrangeButton
          extraClass="payment-modal-close-btn"
          onClick={() => {
            this.cancelPaymentNayax()
            this.props.onCloseModalClick()
            this.onTryAgainClicked()
          }}
        >
          {cancel_payment}
        </OrangeButton>
      </>
    )
  }

  cancelPaymentNayax() {
    axios
      .get(api.cancelNayaxMultiSession)
      .then(() => {
        this.setState({ showPayWithNayax: false, cancelSession: true })
      })
      .catch((err) => {
        console.log(err)
      })
  }

  payWithNayax(price) {
    if (this.state.isRequestInProgress) {
      return this.renderPayByNayax()
    }
    this.setState({ isRequestInProgress: true })
    const { orderId, orderNumber } = this.props
    axios
      .get(api.payWithNayax(price, orderNumber))
      .then((res) => {
        if (res.status === 406) {
          return
        }
        if (res.status === 200 || res.status === 409) {
          axiosPutRetry(azureApi.markOrderAsPayedByNAYAX(orderId), res.data)
            .then((res) => console.log(res.data))
            .catch((err) => console.log(err))
          this.setState({
            nayaxPayStatus: res.status,
            showPayWithNayax: false,
          })
        }
      })
      .catch((err) => {
        this.setState({
          showPayWithNayax: false,
          showChoiceBtns: true,
          cancelSession: false,
          showError: true,
          nayaxPayStatus: err?.response?.status,
        })
      })
      .finally(() => {
        this.setState({ isRequestInProgress: false })
      })

    return this.renderPayByNayax()
  }

  onTryAgainClicked() {
    this.setState({ ...this.INIT_STATE })
    this.props.resetPaymentToTryAgain()
  }

  renderConnectionErrMsg(show) {
    if (!show) {
      return
    }

    const { pay_modal_network_err, pay_modal_click_to_retry, dirRtl } =
      this.props

    const {
      loadingRequest,
      paymentFailed,
      showConnectionErrMsg,
      paymentSuccess,
    } = this.props
    const { nayaxPayStatus } = this.state

    let statusClass = loadingRequest
      ? " status-loading-request"
      : paymentFailed
      ? " status-payment-failed"
      : paymentSuccess
      ? " status-payment-success"
      : " status-other"

    if (nayaxPayStatus) {
      statusClass =
        nayaxPayStatus === 200 || nayaxPayStatus === 409
          ? " status-nayax-pay-success"
          : " status-nayax-pay-fail"
    }

    statusClass = showConnectionErrMsg
      ? statusClass + " connection-error"
      : statusClass

    return (
      <div
        className={"error-or-success-content payment-version-3" + statusClass}
      >
        <WhiteLabel
          extraClass="paymdl2-fade-in paymdl2-err-msg"
          dirRtl={dirRtl}
        >
          {pay_modal_network_err}
        </WhiteLabel>
        <OrangeButton onClick={() => this.onTryAgainClicked()}>
          {pay_modal_click_to_retry}
        </OrangeButton>
      </div>
    )
  }

  onContinueBtnClick() {
    this.props.onPaymentNetworkFail()
  }

  onSuccessfulPaymentContinueBtnClick() {
    const { saveCardPeleToken } = this.state
    const { userId, pelecardToken } = this.props

    if (saveCardPeleToken && pelecardToken) {
      this.props.saveCreditTokenInServer(userId, pelecardToken)
    }

    this.props.onUserPaymentSuccess()
  }

  render() {
    const { show, total } = this.props

    if (!show) {
      return <noscript />
    }
    const {
      enterCardDataManually,
      showPayWithNayax,
      nayaxPayStatus,
      showError,
    } = this.state
    const {
      loadingRequest,
      paymentFailed,
      paymentSuccess,
      dirRtl,
      close_text,
      payment_error,
    } = this.props

    const showPaymentInputs =
      !loadingRequest &&
      !paymentFailed &&
      !paymentSuccess &&
      nayaxPayStatus !== 200 &&
      nayaxPayStatus !== 409

    const wrapperClassName = `paymdl2-modal-wrap ${
      dirRtl ? "dirrtl" : "dirltr"
    }`

    return (
      <>
        <Modal
          extraClass={wrapperClassName}
          isOpen={show}
          onRequestClose={() => {
            this.props.onCloseModalClick()
            this.onTryAgainClicked()
          }}
          className={`react-modal-content medium-react-modal ${
            enterCardDataManually ? "payment-modal-move-up" : ""
          }`}
          overlayClassName="modal-overlay"
          shouldCloseOnOverlayClick={false}
        >
          <div className="payment-modal-title">
            {this.getTitleFromReqState()}
          </div>
          <div className="payment-btns">
            {this.renderPaymentInputMagnetOrManualButtons(showPaymentInputs)}
          </div>

          {this.renderRequestStateSpinnerAndResultButtons(!showPaymentInputs)}
          {showPayWithNayax && this.payWithNayax(total)}

          {this.renderCloseBtn(
            !loadingRequest &&
              !paymentSuccess &&
              !this.props.showTryAgain &&
              !this.state.enterCardDataManually &&
              !showPayWithNayax &&
              !this.state.cancelSession &&
              nayaxPayStatus !== 200 &&
              nayaxPayStatus !== 409
          )}
          {this.renderPaymentInputManual(this.state.enterCardDataManually)}
          {this.renderCancelLoader(this.state.cancelSession)}
        </Modal>
        <ErrorInfoModal
          dirRtl={dirRtl}
          show={showError}
          title={payment_error}
          onModalCloseClick={() => {
            if (nayaxPayStatus !== 200 && nayaxPayStatus !== 409) {
              this.setState({
                showError: false,
                cancelSession: true,
                showChoiceBtns: false,
              })
              setTimeout(() => {
                this.setState(this.INIT_STATE)
              }, 5000)
            }
            this.cancelPaymentNayax()
            this.setState({ showError: false })
          }}
          closeTrnsl={close_text}
        />
      </>
    )
  }
}

const mapStateToProps = ({ userLogin, paymentModal, translate }) => {
  const { userId, creditCardToken, authenticated } = userLogin
  const {
    loadingRequest,
    paymentFailed,
    paymentSuccess,
    showTryAgain,
    showConnectionErrMsg,
    pelecardToken,
  } = paymentModal

  const {
    dirRtl,
    ok_text,
    close_text,
    pay_modal_swipe_title,
    pay_modal_pls_wait_while_loading,
    pay_modal_click_to_retry,
    pay_modal_network_err,
    continue_text,
    payment_error,
    payment_success,
    payment_pick_your_option,
    payment_payment_details,
    payment_pay_with_last_payment_details,
    payment_swipe_a_card,
    payment_for_manual_input,
    payment_for_manual_input_click_here,
    payment_credit_card_number,
    payment_month,
    payment_year,
    payment_approval,
    payment_swipe_a_card_click_here,
    payment_press_for_locker_opening,
    payment_save_payment_details_for_future_use,
    payment_input_error,
    we_are_canceling_payment,
    choose_the_option,
    attach_to_the_reader,
    insert_into_bottom,
    cancel_payment,
  } = translate

  return {
    userId,
    loadingRequest,
    paymentFailed,
    paymentSuccess,
    showTryAgain,
    showConnectionErrMsg,
    pelecardToken,
    userTokenInServer: creditCardToken,
    authenticated,
    dirRtl,
    ok_text,
    close_text,
    pay_modal_swipe_title,
    pay_modal_pls_wait_while_loading,
    pay_modal_click_to_retry,
    pay_modal_network_err,
    continue_text,
    payment_error,
    payment_success,
    payment_pick_your_option,
    payment_payment_details,
    payment_pay_with_last_payment_details,
    payment_swipe_a_card,
    payment_for_manual_input,
    payment_for_manual_input_click_here,
    payment_credit_card_number,
    payment_month,
    payment_year,
    payment_approval,
    payment_swipe_a_card_click_here,
    payment_press_for_locker_opening,
    payment_save_payment_details_for_future_use,
    payment_input_error,
    we_are_canceling_payment,
    choose_the_option,
    attach_to_the_reader,
    insert_into_bottom,
    cancel_payment,
  }
}

export default connect(mapStateToProps, {
  payWithPelecard,
  payWithPelecardToken,
  resetPaymentToTryAgain,
  saveCreditTokenInServer,
})(PaymentModal3)
