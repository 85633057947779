import axios from "axios"
import api from "../api"
import {
  ON_STATION_TASKS_LOADING,
  ON_GET_STATION_TASKS_SUCCESS,
  ON_GET_STATION_TASKS_FAIL,
  ON_TASK_COMPLETED_LOADING,
  ON_TASK_COMPLETED_SUCCESS,
  ON_TASK_COMPLETED_FAIL,
  CLEAR_TASK_COMPLETED_STATE,
} from "./types"

export const getTasksByStation = (stationId) => {
  return (dispatch) => {
    dispatch({ type: ON_STATION_TASKS_LOADING })
    axios
      .get(api.getTasksByStation(stationId))
      .then((res) => {
        dispatch({
          type: ON_GET_STATION_TASKS_SUCCESS,
          payload: res.data,
        })
      })
      .catch((err) => {
        dispatch({
          type: ON_GET_STATION_TASKS_FAIL,
          payload: err,
        })
      })
  }
}

export const taskAccomplishedReport = (params) => {
  return (dispatch) => {
    dispatch({ type: ON_TASK_COMPLETED_LOADING })
    const formData = new FormData()
    formData.append("note", params?.note)

    axios
      .put(api.TaskAccomplishedReport(params?.stationTaskId), formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
      .then((res) => {
        dispatch({
          type: ON_TASK_COMPLETED_SUCCESS,
          payload: res.data,
        })
      })
      .catch((err) => {
        dispatch({
          type: ON_TASK_COMPLETED_FAIL,
          payload: err,
        })
      })
  }
}

export const clearTaskCompletedState = () => {
  return (dispatch) => {
    dispatch({ type: CLEAR_TASK_COMPLETED_STATE })
  }
}
