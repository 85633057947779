import React, { useState } from "react"
import { OrangeButton } from "../../../../../common"
import AccessibilityModal from "../../../../../Modals/AccessibilityModal"
import "./style.css"

const ItemRow = ({
  item,
  roundBottomCorners,
  onRowItemClick,
  isOrange,
  refOnSelect,
  background,
  hideSelectReadyItemBtn,
}) => {
  const {
    orderId,
    orderNumber,
    customerName,
    orderDate,
    orderType,
    customerMobilePhone,
    packageNumber,
    orderTypeName,
    isNeedAccessibility,
  } = item

  const [firstName, lastName] = customerName.split(" ")
  const [showAccessibilityModal, setShowAccessibilityModal] = useState(false)

  const orderData = {
    orderNumber,
    packageNumber,
    firstName,
    lastName,
    orderDate,
    customerName,
  }

  const className = `rodl-itm-wrap ${
    roundBottomCorners ? "rodl-itm-round-btm-crnrs" : ""
  } ${isOrange ? (background = "rodl-orange") : ""}`

  const ref = isOrange ? refOnSelect : null

  return (
    <>
      <div className={` ${className} ${background}`} ref={ref}>
        <div className="rodl-itm-row-f">{orderNumber}</div>
        <div className="rodl-itm-row-f">{packageNumber}</div>
        <div className="rodl-itm-row-f">{customerMobilePhone}</div>
        <div className="rodl-itm-row-f rold-itm-flex-2">{customerName}</div>
        <div className="rodl-itm-row-f">{orderTypeName}</div>
        <div className="rodl-itm-row-f">
          <OrangeButton
            extraClass="rodl-frame-single-btn"
            onClick={() => {
              setShowAccessibilityModal(isNeedAccessibility)
              onRowItemClick(orderId, orderData)
            }}
            show={!hideSelectReadyItemBtn}
          >
            פתח תא פנוי
          </OrangeButton>
        </div>
      </div>
      <AccessibilityModal
        show={showAccessibilityModal}
        title="לוקר עם נגישות"
        text="שים לב, החבילה מיועדת לאדם עם נכות. אם לוקר עם אייקון הנגישות פנוי, נא לבחור אותו"
        onModalCloseClick={() =>
          setShowAccessibilityModal(!showAccessibilityModal)
        }
        btnText="סגירה"
        dirRtl
      />
    </>
  )
}

const getIconFromOrderType = (orderType) => {
  switch (
    orderType // 1-Laundry 2-mail 0-none
  ) {
    case 1:
      return require("../../../../../../assets/images/fashion.png") // fashionSrc
    case 2:
      return require("../../../../../../assets/images/close-envelope.png") // closeEnvelopeSrc
    default:
      return ""
  }
}

export default ItemRow
