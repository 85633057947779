import React, { Component } from "react"
import { connect } from "react-redux"
import Modal from "react-modal"
import { OrangeButton } from "../../common"
import { clearNetworkErrorMessage } from "../../../actions"
import wardrobe from "../../../assets/images/wardrobe.svg"
import "./style.scss"

class NetworkErrorModal extends Component {
  constructor(props) {
    super(props)

    this.INIT_STATE = {}

    this.state = this.INIT_STATE
  }

  componentDidMount() {
    //setTimeout(() => this.props.clearNetworkErrorMessage(), 120 * 1000)
  }

  componentWillUnmount() {}

  newlineText = (text) => {
    if (text === undefined || text === null || text.length <= 0) {
      return text
    }
    return (
      <>
        {text.split("\n").map((str) => (
          <p>{str}</p>
        ))}
      </>
    )
  }

  render() {
    const {
      showNetworkErrorMessage,
      title,
      text,
      onModalCloseClick,
      dirRtl,
      btnText,
      icon,
    } = this.props

    if (!showNetworkErrorMessage) {
      return <noscript />
    }

    let iconSrc = ""
    if (icon) {
      iconSrc = require(`../../../assets/images/${icon}.png`)
    }

    return (
      <Modal
        isOpen={showNetworkErrorMessage}
        onRequestClose={onModalCloseClick}
        className="react-modal-content"
        overlayClassName="modal-overlay"
      >
        <div className="orange-circle">
          <div className="orange-stick"></div>
          <div className="orange-dot"></div>
        </div>

        <div className="ooalo-label-extra-class" dir={dirRtl ? "rtl" : "ltr"}>
          {title}
        </div>
        <div
          className="ooalo-label-extra-class ooalo-label-extra-class2"
          dir={dirRtl ? "rtl" : "ltr"}
        >
          {text}
        </div>
        <OrangeButton onClick={() => onModalCloseClick()}>
          {btnText}
        </OrangeButton>
      </Modal>
    )
  }
}

const mapStateToProps = ({ userLogin, errorMessages, translate }) => {
  const { userId } = userLogin
  const { showNetworkErrorMessage } = errorMessages

  const { back_btn_text, close_text } = translate

  const translations = {
    back_btn_text,
    close_text,
  }

  return {
    userId,
    showNetworkErrorMessage,
    translations,
  }
}

export default connect(mapStateToProps, {
  clearNetworkErrorMessage,
})(NetworkErrorModal)
