import React, { Component } from "react"
import { connect } from "react-redux"

import { LogoutUser, logScreenEnterance, setFlowActivePage } from "../../../../actions"
import ThxImg from "../../../../assets/images/you_got_it_done.png"
import "./style.css"

class ThankYouScreen extends Component {
  componentDidMount() {
    this.props.setFlowActivePage("ThankYouScreen");
    setTimeout(() => this.onTimeOut(), 5000)

    this.props.logScreenEnterance("USER_THANK_YOU_SCREEN")
  }

  onTimeOut() {
    this.props.LogoutUser()
    this.props.setFlowActivePage(null);
    this.props.history.push("")
  }

  render() {
    return (
      <div className="thanks-img-wrapper">
        <img src={ThxImg} alt="thanks-img" className="thanks-img" />
      </div>
    )
  }
}

const mapStateToProps = ({ thankYou, translate }) => {
  const { subTitle } = thankYou
  const { thank_you_title } = translate

  return {
    subTitle,
    thank_you_title,
  }
}

export default connect(mapStateToProps, { LogoutUser, logScreenEnterance, setFlowActivePage })(
  ThankYouScreen
)
