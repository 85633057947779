import React, { Component } from "react"
import { withRouter } from "react-router-dom"
import { connect } from "react-redux"

import { LogoutUser } from "../../actions"

import CircularProgressBarModal from "../Modals/CircularProgressBarModal"

import "./style.css"

class AutoLogouter extends Component {
  constructor(props) {
    super(props)

    this.state = {
      timer60Timeout: null,
      timer120Timeout: null,
      timer150Timeout: null,
      timer180Timeout: null,

      progressBar30sInterval: null,
      progressBar30sValue: 30,
    }
  }

  componentDidMount() {
    this.props.triggerCallbackSetter(this.autoLogouterTrigger, this)
  }

  componentWillReceiveProps(newProps) {
    const {
      loadingRequest,
      paymentFailed,
      paymentSuccess,
      isLoadingLockerToLockerDelivery,
      isLoadingShipingPackage,
      flowActivePage,
    } = newProps
    if (
      loadingRequest ||
      paymentFailed ||
      paymentSuccess ||
      isLoadingLockerToLockerDelivery ||
      isLoadingShipingPackage ||
      flowActivePage
    ) {
      this.props.triggerCallbackSetter(this.autoLogouterTrigger, this)
      console.log("*** Reset timeout ***")
    }
  }

  autoLogouterTrigger(logouterCtx) {
    logouterCtx.cancelRunningTimers()
    logouterCtx.setUpTimers()
    logouterCtx.stopAndHide30sProgressBar()
  }

  cancelRunningTimers() {
    const {
      timer60Timeout,
      timer120Timeout,
      timer150Timeout,
      timer180Timeout,
    } = this.state

    if (timer60Timeout) {
      clearTimeout(timer60Timeout)
    }

    if (timer120Timeout) {
      clearTimeout(timer120Timeout)
    }

    if (timer150Timeout) {
      clearTimeout(timer150Timeout)
    }

    if (timer180Timeout) {
      clearTimeout(timer180Timeout)
    }

    this.setState({
      timer60Timeout: null,
      timer120Timeout: null,
      timer150Timeout: null,
      timer180Timeout: null,
    })
  }

  setUpTimers() {
    const timer60Timeout = setTimeout(
      () => this.onTimerTicksOver(60),
      60 * 1000
    )
    const timer120Timeout = setTimeout(
      () => this.onTimerTicksOver(120),
      120 * 1000
    )
    const timer150Timeout = setTimeout(
      () => this.onTimerTicksOver(150),
      150 * 1000
    )
    const timer180Timeout = setTimeout(
      () => this.onTimerTicksOver(180),
      180 * 1000
    )

    this.setState({
      timer60Timeout,
      timer120Timeout,
      timer150Timeout,
      timer180Timeout,
    })
  }

  onTimerTicksOver(secsPassed) {
    console.log("onTimerTicksOver - ", secsPassed)

    const { pathname } = this.props.location
    console.log("pathname - ", pathname)
    switch (pathname) {
      case "/NewOrderScreen":
      case "/NewPurchaseScreen":
      case "/AboutRental":
      case "/RentLockerScreen":
      case "/RentLockerMenuScreen":
      case "/LockerToLockerDeliveryScreen/Info":
      case "/LockerToLockerDeliveryScreen/SelectStation":
      case "/LockerToLockerDeliveryScreen/AddresseeDetails":
      case "/LockerToLockerDeliveryScreen/PayOrder":
      case "/LockerToLockerDeliveryScreen/SelectLocker":
      case "/LockerToLockerDeliveryScreen/SelectLocker ":
      case "/SignUpStepVerifyScreen":
        this.onTimeOverForNewOrderScreen(secsPassed)
        break
      case "/SignUpStep1Screen":
      case "/SignUpStep2Screen":
      case "/SignUpStep3Screen":
        this.onTimeOverForSignUpScreen(secsPassed)
        break
      case "/LoginScreen":
      case "/UserLoginScreen":
      case "/UserLoginWithDoneLoginScreen":
        this.onTimeOverForLoginScreen(secsPassed)
        break
      case "/SmsLoginScreen":
      case "/RestoreDeliveryCodeScreen":
        this.onTimeOverForDeliveryScreen(secsPassed)
        break
      case "/ThankYouScreen":
        this.onTimeOverForThankYouScreen(secsPassed)
        break
      default:
        this.handleTimerOverForAllUndefinedScreens(secsPassed)
        break
    }
  }

  handleTimerOverForAllUndefinedScreens(secsPassed) {
    console.log(
      "handleTimerOverForAllUndefinedScreens on" + secsPassed + "secs"
    )

    if (secsPassed !== 180) {
      return
    }
    console.log("TimerOverForAllUndefinedScreens")
    this.logoutUserAndGoToMainScreen()
  }

  onTimeOverForNewOrderScreen(secsPassed) {
    if (secsPassed === 120) {
      this.startAndDisplay30sProgressBar()
      return
    }

    if (secsPassed === 150) {
      this.logoutUserAndGoToMainScreen()
    }
  }

  onTimeOverForSignUpScreen(secsPassed) {
    if (secsPassed === 120) {
      this.startAndDisplay30sProgressBar()
      return
    }

    if (secsPassed === 150) {
      this.logoutUserAndGoToMainScreen()
    }
  }
  onTimeOverForLoginScreen(secsPassed) {
    if (secsPassed === 120) {
      this.logoutUserAndGoToMainScreen()
    }
  }
  onTimeOverForDeliveryScreen(secsPassed) {
    if (secsPassed === 60) {
      this.logoutUserAndGoToMainScreen()
    }
  }
  onTimeOverForThankYouScreen(secsPassed) {
    if (secsPassed === 30) {
      this.logoutUserAndGoToMainScreen()
    }
  }

  logoutUserAndGoToMainScreen() {
    const { authenticated } = this.props

    this.stopAndHide30sProgressBar()

    if (authenticated) {
      this.props.LogoutUser()
    }

    this.props.history.push("")
  }

  startAndDisplay30sProgressBar() {
    const progressBar30sInterval = setInterval(
      () =>
        this.setState({
          progressBar30sValue: this.state.progressBar30sValue - 1,
        }),
      1000
    )

    this.setState({ progressBar30sInterval })
  }

  stopAndHide30sProgressBar() {
    const { progressBar30sInterval } = this.state

    if (progressBar30sInterval) {
      clearInterval(progressBar30sInterval)
    }

    this.setState({
      progressBar30sInterval: null,
      progressBar30sValue: 30,
    })
  }

  render() {
    const { progressBar30sInterval, progressBar30sValue } = this.state
    const { system_inactive, after_sec, autologout_msg, dirRtl, close_text } =
      this.props

    return (
      <CircularProgressBarModal
        show={!!progressBar30sInterval}
        title={system_inactive}
        dirRtl={dirRtl}
        text={`${after_sec} ${progressBar30sValue} ${autologout_msg}`}
        maxVal={progressBar30sValue}
        close_text={close_text}
      />
    )
  }
}

const mapStateToProps = ({
  userLogin,
  translate,
  paymentModal,
  lockerToLockerDelivery,
  shipingPackage,
  flow,
}) => {
  const { authenticated } = userLogin
  const { system_inactive, after_sec, autologout_msg, dirRtl, close_text } =
    translate
  const { loadingRequest, paymentFailed, paymentSuccess } = paymentModal
  const { isLoading: isLoadingLockerToLockerDelivery } = lockerToLockerDelivery
  const { isLoading: isLoadingShipingPackage } = shipingPackage
  const { flowActivePage } = flow

  return {
    authenticated,
    after_sec,
    close_text,
    autologout_msg,
    dirRtl,
    system_inactive,
    loadingRequest,
    paymentFailed,
    paymentSuccess,
    isLoadingLockerToLockerDelivery,
    isLoadingShipingPackage,
    flowActivePage,
  }
}

export default connect(mapStateToProps, { LogoutUser })(
  withRouter(AutoLogouter)
)
