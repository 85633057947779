import React from "react"
import Header from "./Header"

import "./style.css"
import ItemRow from "./ItemRow"
import TableFooter from "./TableFooter"

const ItemsList = ({
  HeaderItems,
  Items,
  onItemClick,
  onDeleteItemClick,
  totalItmsTranslation,
  discountText = "",
  orderNumber = null,
  order_number = "",
  dirRtl,
}) => {
  let totalAmount
  if (!Items || Items.length < 1) totalAmount = 0
  else
    totalAmount =
      Items.map((itm) => itm.amount).reduce((total = 0, num) => total + num) ||
      0

  return (
    <div className={"list-and-footer " + dirRtl ? "rtl" : "ltr"}>
      {renderOrderNumber(orderNumber, order_number, dirRtl)}
      <Header>{HeaderItems}</Header>
      <div className="itls-wrapper">
        <div className="itls-itms-wrapper">
          {renderItems(Items, onItemClick, onDeleteItemClick)}
        </div>
      </div>
      <TableFooter
        lable={totalItmsTranslation}
        value={totalAmount}
        discountText={discountText}
      />
    </div>
  )
}

const renderItems = (items, onItemClick, onDeleteItemClick) => {
  if (items.length < 9) {
    for (let i = 0; i < 9; i++) {
      items.push({ title: "", amount: "" })
    }
  }

  return items.map((itm, index) => {
    return (
      <ItemRow
        item={itm}
        key={index}
        onItemClick={onItemClick}
        onDeleteClick={onDeleteItemClick}
        background={index % 2 ? "orange" : "grey"}
      />
    )
  })
}

const renderOrderNumber = (orderNumber, order_number, dirRtl) => {
  if (!orderNumber) {
    return <></>
  }
  return (
    <div className="order-number">
      <div className={`${dirRtl ? "rtl" : "ltr"}`}>{order_number}</div>
      <div className={`${dirRtl ? "rtl" : "ltr"}`}>{orderNumber}</div>
    </div>
  )
}

export default ItemsList
