import React, { useState } from "react"
import Modal from "react-modal"
import { connect } from "react-redux"
import { OrangeButton } from "../../common"
import store from "../../../store"
import PaymentInput from "../../common/PaymentInput"
import PaymentInput2 from "../../common/PaymentInput2"
import { CardManuallyInputs } from "../../reduxified"

import "./style.css"

const CardCheckModal = ({ show, checkCreditCard, onCloseModal }) => {
  const [showManual, setShowManual] = useState(false)
  const renderManualInputs = (show) => {
    if (!show) return
    return (
      <div className="card-check-manual-wrapper">
        <CardManuallyInputs
          show={showManual}
          justCheck={true}
          startPayingProcess={checkCreditCard}
          closeEnterCardDataManually={() => onCloseModal()}
        />
      </div>
    )
  }

  const { manual_input, card_check_sub_title, card_check_title } =
    store.getState().translate
  const { stationPaymentVersion } = store.getState().stationServiceTypes
  return (
    <>
      <Modal
        isOpen={show}
        className="react-modal-content medium-react-modal"
        overlayClassName="modal-overlay"
        onRequestClose={() => onCloseModal()}
      >
        <button className="check-card-close-btn" onClick={() => onCloseModal()}>
          X
        </button>
        {!showManual && (
          <div className="check-card-title">{card_check_title}</div>
        )}
        {showManual && renderManualInputs(show)}
        <div className="card-input-wrapper">
          {stationPaymentVersion === 1 && !showManual && (
            <div className="card-check-spinner">
              <PaymentInput
                onPaymentDataReady={checkCreditCard}
                justCheck={true}
              />
            </div>
          )}
          {stationPaymentVersion === 2 && !showManual && (
            <div className="card-check-reader">
              <PaymentInput2
                onPaymentDataReady={checkCreditCard}
                justCheck={true}
              />
            </div>
          )}
        </div>
        <div className="cardCheck-btn-mt">
          {!showManual && (
            <>
              <OrangeButton onClick={() => setShowManual(true)}>
                {manual_input}
              </OrangeButton>
              <div className="check-card-text" dir="rtl">
                {card_check_sub_title}
              </div>
            </>
          )}
        </div>
      </Modal>
    </>
  )
}

export default CardCheckModal
