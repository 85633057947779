
import {
    SET_PAGE_AS_VISITED
} from './types';


export const setPageAsVisited = (pageName) => {

    return (dispatch) => {

        dispatch({
            type: SET_PAGE_AS_VISITED,
            payload:pageName
        });
    }


}