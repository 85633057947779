import en from "./eng.json"
import he from "./heb.json"
import ru from "./rus.json"
import num from "./num.json"
import email from "./email.json"
import mobile from "./mobile.json"
import code from "./code.json"
import en_modal from "./eng_modal.json"

const langs = {
  en,
  he,
  ru,
  num,
  email,
  mobile,
  code,
  en_modal,
}

const layout = (lang = "he") => {
  return langs[lang]
}

export default layout
