import React from "react"
import Modal from "react-modal"
import { OrangeButton } from "../../common"
import "./style.css"

const YesNoInfoModal = ({
  show,
  title,
  text,
  onModalOkClick,
  onModalCancelClick,
  okTransl,
  cancelTransl,
  dirRtl,
}) => {
  return (
    <Modal
      isOpen={show}
      onRequestClose={onModalCancelClick}
      className="react-modal-content"
      overlayClassName="modal-overlay"
    >
      <div className="cancel-order-title" dir={dirRtl ? "rtl" : "ltr"}>
        {title}
      </div>
      <div className="cancel-order-title" dir={dirRtl ? "rtl" : "ltr"}>
        {text}
      </div>
      <div className="yninmo-yes-no-wrapper" dir={dirRtl ? "rtl" : "ltr"}>
        <OrangeButton
          onClick={(e) => onModalOkClick()}
          extraClass="cancel-order-button"
        >
          {okTransl}
        </OrangeButton>
        <OrangeButton
          onClick={(e) => onModalCancelClick()}
          extraClass="cancel-order-button"
        >
          {cancelTransl}
        </OrangeButton>
      </div>
    </Modal>
  )
}

export default YesNoInfoModal
