import {
    SET_LOADING_COUPON, 
    FETCH_COUPON_BY_NAME_SUCCESS, 
    FETCH_COUPON_BY_NAME_FAIL, 
    RESET_COUPONS_REDUCER,
    ON_USER_SIGN_OUT,
    RESET_ALL_REDUCERS
} from '../actions/types';

const EMPTY_COUPON = {
        couponId:'',
        couponName:'',
        couponDescription:'',
        discount:0,
        discountType:0,
    };
const EMPTY_ERROR = {
    isError:false,
    errorType: 0,
    errorMessage:'',
};

const DEFAULT_ERROR = {
    isError:true,
    errorType: 1,
    errorMessage:'לא ניתן לצרף את הקופון',
};

const INIT_STATE = {
    coupon: EMPTY_COUPON,
    isLoading: false,
    isChecked:false, 
    error:EMPTY_ERROR,
};

const CouponReducer = (state = INIT_STATE, action) => {
    
    switch (action.type) {
        case SET_LOADING_COUPON:             
            return { 
                coupon: EMPTY_COUPON,
                isLoading: true,
                isChecked:false, 
                error: EMPTY_ERROR,
            };
        case FETCH_COUPON_BY_NAME_SUCCESS:
            if(!action.payload || action.payload.length<1){
                return { 
                    coupon: EMPTY_COUPON,
                    isLoading: false,
                    isChecked:true, 
                    error:DEFAULT_ERROR,
                };
            }
             return { 
                coupon:  action.payload,
                isLoading: false,
                isChecked:true, 
                error:EMPTY_ERROR,
            };
            
        case FETCH_COUPON_BY_NAME_FAIL:
            return {
                coupon: EMPTY_COUPON,
                isLoading: false,
                isChecked:true, 
                error: DEFAULT_ERROR,//TODO action.payload

            }
        case ON_USER_SIGN_OUT:
        case RESET_COUPONS_REDUCER:
            return {
                 ...INIT_STATE
            };
        
        case RESET_ALL_REDUCERS:
            return {
                ...INIT_STATE
            };
        default:
            return state;
    }
}


export default CouponReducer;