import { dhlProvider } from "./DHLProvider"
import { exelotProvider } from "./ExelotProvider"
//import { oneProjectProvider } from "./OneProjectProvider"
import { hfdProvider } from "./HFDProvider"
import { returnsExternalProvider } from "./ReturnsExternalProvider"

let provider = {}

provider.isProvider = (serviceName) => {
  switch (serviceName) {
    case "dhl":
      return true
    case "exelot":
      return true
    case "oneProject":
      return true
    case "hfd":
      return true
    case "returnsExternal":
      return true
    default:
      return false
  }
}

provider.GetProvider = (serviceName) => {
  switch (serviceName) {
    case "dhl":
      return dhlProvider
    case "exelot":
      return exelotProvider
    // case "oneProject":
    //   return oneProjectProvider
    case "hfd":
      return hfdProvider
    case "returnsExternal":
      return returnsExternalProvider
    default:
      return {}
  }
}

provider.orderType = (serviceName) => {
  if (!provider.isProvider(serviceName)) {
    return 0
  }
  const s = provider.GetProvider(serviceName)
  return s.orderType
}

provider.accountType = (serviceName) => {
  if (!provider.isProvider(serviceName)) {
    return 0
  }
  const s = provider.GetProvider(serviceName)
  return s.accountType
}

provider.renderLogo = (serviceName) => {
  if (!provider.isProvider(serviceName)) {
    return
  }
  const s = provider.GetProvider(serviceName)
  return s.renderLogo()
}

provider.formatWaybill = (serviceName, value) => {
  if (!provider.isProvider(serviceName)) {
    return value
  }
  const s = provider.GetProvider(serviceName)
  return s.formatWaybill(value)
}

provider.validatePackageBarcode = (serviceName, barcode) => {
  if (!provider.isProvider(serviceName)) {
    return false
  }
  const s = provider.GetProvider(serviceName)
  return s.validatePackageBarcode(barcode)
}

provider.barcodeMinLength = (serviceName) => {
  if (!provider.isProvider(serviceName)) {
    return 1
  }
  const s = provider.GetProvider(serviceName)
  return s.barcodeMinLength
}
export const providerManager = provider
