import React, { Component } from "react"
import Modal from "react-modal"
import { connect } from "react-redux"
import { withRouter } from "react-router-dom"
import {
  fetchAvailableLockersStatus,
  isUserHadOrderBefore,
  LogoutUser,
  logScreenEnterance,
} from "../../../../actions"
import errorImg from "../../../../assets/svg/error-sign.svg"
import { validation } from "../../../../utils/Validations"
import { OrangeButton } from "../../../common"
import { FixedFooter, FixedHeader, LockerCells } from "../../../reduxified"
import "./style.css"

class LockerToLockerDeliveryScreen extends Component {
  constructor(props) {
    super(props)
    this.INIT_STATE = {
      isClickWaiting: false,
      showYesNoInfoModal: false,
      cardToken: "",
      invalidToken: false,
    }
    this.state = this.INIT_STATE
  }

  componentDidMount() {
    const { authenticated, stationId } = this.props
    if (!authenticated || !stationId) {
      this.props.LogoutUser()
      this.props.history.push("")
    }

    this.props.logScreenEnterance("LOCKER_TO_LOCKER_DELIVERY_SCREEN")

    this.props.fetchAvailableLockersStatus(stationId)
  }

  render() {
    const {
      blinkers,
      errLockers,
      isHadOrderBefore,
      isCheckedHadOrderBefore,
      toStationId,
      addresseeDetails,
      choose_locker,
    } = this.props
    const { showYesNoInfoModal, invalidToken } = this.state

    const isAddresseeDetails = validation.AddresseeDetails(addresseeDetails)

    const step1_check_user =
      (isCheckedHadOrderBefore && !isHadOrderBefore) ||
      (!isCheckedHadOrderBefore && !isHadOrderBefore) ||
      invalidToken
    const step4_lockers_cells =
      (isHadOrderBefore || !invalidToken) &&
      toStationId != null &&
      isAddresseeDetails

    if (step1_check_user) {
      const show_CardCheck_IsUserHadOrderCheck_Modal =
        !invalidToken && !isHadOrderBefore

      return (
        <>
          <div className="ls-wrapper ">
            <FixedHeader buttonsFlag="unlockLockersScreen" />
            <div className="rent-locker-title">{choose_locker}</div>

            <FixedFooter history={this.props.history} />
          </div>
        </>
      )
    }

    if (step4_lockers_cells) {
      return (
        <>
          <div className="ls-wrapper ">
            <FixedHeader buttonsFlag="unlockLockersScreen" />

            <div className="rent-locker-title">{choose_locker}</div>

            <div className="ddocs-inner-wrapper">
              <LockerCells
                blinkers={blinkers}
                errLockers={errLockers}
                rentLocker
              />
            </div>
            <FixedFooter history={this.props.history} />
          </div>
        </>
      )
    }

    return <>NO DATA</>
  }
}

const mapStateToProps = ({
  userLogin,
  lockersCells,
  translate,
  bgService,
  lockerToLockerDelivery,
}) => {
  const {
    authenticated,
    role,
    userId,
    isHadOrderBefore,
    isCheckedHadOrderBefore,
  } = userLogin
  const { openedLockersArr, errorLockersArr } = lockersCells
  const {
    driver_menu_lockers_cells_opening,
    driver_lockers_cells_opening_subtitle,
    choose_locker,
  } = translate
  const blinkers = {}
  openedLockersArr.map((lockNum) => (blinkers[lockNum] = lockNum))
  const errLockers = {}
  errorLockersArr.map((lockNum) => (errLockers[lockNum] = lockNum))
  const { stationId } = bgService
  const { order } = lockerToLockerDelivery
  const { toStationId } = order
  return {
    authenticated,
    role,
    userId,
    isHadOrderBefore,
    isCheckedHadOrderBefore,
    blinkers,
    errLockers,
    driver_menu_lockers_cells_opening,
    driver_lockers_cells_opening_subtitle,
    choose_locker,
    stationId,
    toStationId,
  }
}

export default connect(mapStateToProps, {
  logScreenEnterance,
  isUserHadOrderBefore,
  fetchAvailableLockersStatus,
  LogoutUser,
})(withRouter(LockerToLockerDeliveryScreen))
