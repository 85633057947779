import React from "react"
import Modal from "react-modal"
import { OrangeButton, PendingSuccessFailBtn } from "../../common"
import "./style.css"

const ErrorInfoModal = ({
  show,
  title,
  text,
  onModalCloseClick,
  dirRtl,
  closeTrnsl,
  className,
}) => {
  if (!show) {
    return <noscript />
  }

  // const wrapperClassName = `errinm-details-modal-wrap ${
  //   dirRtl ? "dirrtl" : "dirltr"
  // }`

  return (
    <Modal
      isOpen={show}
      onRequestClose={onModalCloseClick}
      className={"react-modal-content " + className}
      overlayClassName="modal-overlay"
    >
      <PendingSuccessFailBtn status={-1} />
      <div className="errinm-label-extra-class" dir={dirRtl ? "rtl" : "ltr"}>
        {title}
      </div>
      <div
        className="errinm-label-extra-class errinm-label-extra-class2"
        dir={dirRtl ? "rtl" : "ltr"}
      >
        {text && text.split("\n").map((str) => <p key={str}>{str}</p>)}
      </div>
      <OrangeButton onClick={(e) => onModalCloseClick()}>
        {closeTrnsl}
      </OrangeButton>
    </Modal>
  )
}

export default ErrorInfoModal
