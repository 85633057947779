import exelotLogo from "../assets/images/exelot_logo.png"

let exelot = {}

exelot.orderType = 7
exelot.accountType = 9

exelot.renderLogo = () => {
  return (
    <img
      className="logo-service-icon exelot"
      src={exelotLogo}
      alt={"logo exelot"}
    />
  )
}

exelot.formatWaybill = (value) => {
  let v = value.replace(/\s+/g, "").replace(/[^0-9a-zA-Z]/gi, "")

  const matches = v.match(/\[^0-9a-zA-Z]{3,12}/g)
  let match = (matches && matches[0]) || ""

  let parts = []
  let i = 0
  let len
  for (i = 0, len = match.length; i < len; i += i === 0 || i === 3 ? 2 : 4) {
    parts.push(match.substring(i, i + (i === 0 || i === 3 ? 2 : 4)))
  }

  if (parts.length) {
    return parts.join(" ")
  } else {
    return value
  }
}
exelot.barcodeMinLength = 12
exelot.validatePackageBarcode = (barcode) => {
  if (!barcode || barcode.length < 12 || barcode.length > 12) {
    return false
  }
  const barcodeRegex = /^([0-9a-zA-Z]{12}$)/
  const res = barcodeRegex.test(barcode)
  return res
}

export const exelotProvider = exelot
