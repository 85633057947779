import { THANK_YOU_SUB_TITLE_SET, RESET_ALL_REDUCERS } from '../actions/types';

const INIT_STATE = {
    subTitle: ''
};

const ThankYouReducer = (state = INIT_STATE, action) => {
    switch (action.type) {
        case THANK_YOU_SUB_TITLE_SET:
            return {
                ...state,
                subTitle: action.payload
            };
        case RESET_ALL_REDUCERS:
            return {
                ...INIT_STATE
            };
        default:
            return state;
    }
};

export default ThankYouReducer;
