import { isProdEnv } from "../utils"

const api = {}

let BASE_URL

if (isProdEnv()) {
  BASE_URL = `https://k-api.cleanbox.co.il/api/logs`
} else {
  BASE_URL = `https://k-api-test.cleanbox.co.il/api/logs`
}

var stationNumber = ""

api.fbSetStationNumber = (stationNum) => {
  stationNumber = stationNum
}

api.reportFBEventUrl = BASE_URL

api.reportFBEventBody = (
  eventName,
  eventMsg,
  TimeStamp = new Date(),
  StationNumber = stationNumber
) => {
  return { eventName, eventMsg, TimeStamp, StationNumber }
}

export default api
