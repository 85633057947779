import React from "react"

import Header from "./Header"
import ItemRow from "./ItemRow"

import "./style.css"

const OrderDetailsList = ({
  HeaderItems,
  Items,
  onItemClick,
  onDeleteItemClick,
  modalOrderDetailList,
  itemListNames = [],
}) => {
  let totalAmount
  if (!Items || Items.length < 1) totalAmount = 0
  else if (Items.amount) {
    totalAmount = Items.amount
  } else {
    totalAmount = Items.map((itm) => itm.amount).reduce(
      (total = 0, num) => total + num
    )
  }

  return (
    <div
      className={`itls-wrapper ${
        modalOrderDetailList ? "modal-details-list" : ""
      }`}
    >
      <Header>{HeaderItems}</Header>
      <div className="itls-itms-wrapper">
        {renderItems(Items, onItemClick, onDeleteItemClick, itemListNames)}
      </div>
    </div>
  )
}

const renderItems = (items, onItemClick, onDeleteItemClick, itemListNames) => {
  if (items.amount) {
    return (
      <ItemRow
        item={items}
        key={1}
        onItemClick={onItemClick}
        onDeleteClick={onDeleteItemClick}
        roundBottomCorners={true}
        background={"orange"}
        itemListNames={itemListNames}
      />
    )
  }
  return items.map((itm, index) => {
    return (
      <ItemRow
        item={itm}
        key={index}
        onItemClick={onItemClick}
        onDeleteClick={onDeleteItemClick}
        roundBottomCorners={index === items.length - 1}
        background={index % 2 ? "orange" : "grey"}
        itemListNames={itemListNames}
      />
    )
  })
}

export { OrderDetailsList }
