import axios from "axios"
import api from "../api"

import { PRICE_LIST_FETCH_SUCCESS } from "../actions/types"

export const fetchPriceListByStationId = (stationId, lang) => {
  return (dispatch) => {
    axios
      .get(api.getStationPriceList(stationId, lang))
      .then((res) => {
        dispatch({
          type: PRICE_LIST_FETCH_SUCCESS,
          payload: res.data,
        })
      })
      .catch((err) => console.log(err))
  }
}
