import React from "react"
import Modal from "react-modal"
import { OrangeButton, PendingSuccessFailBtn } from "../../common"
import "./style.css"

const ErrorInfoWithLinkModal = ({
  show,
  title,
  text,
  onModalCloseClick,
  dirRtl,
  closeTrnsl,
  linkText,
  onLinkClick
}) => {
  if (!show) {
    return <noscript />
  }


  return (
    <Modal
      isOpen={show}
      onRequestClose={onModalCloseClick}
      className="react-modal-content error-info-with-link"
      overlayClassName="modal-overlay"
    >
      <PendingSuccessFailBtn status={-1} />
      <div className="errinm-label-extra-class" dir={dirRtl ? "rtl" : "ltr"}>
        {title.split('\n').map(str =>  <p>{str}</p>)}
      </div>
      <div
        className="errinm-label-extra-class errinm-label-extra-class2"
        dir={dirRtl ? "rtl" : "ltr"}
      >
        {text.split('\n').map(str =>  <p>{str}</p>)}
      </div>
      <div className="error-info-with-link-buttons">
        <OrangeButton onClick={(e) => onModalCloseClick()}>
          {closeTrnsl.split('\n').map(str =>  <p>{str}</p>)}
        </OrangeButton>
        <div 
          className="link-btn"
          onClick={()=> onLinkClick()}
          role="button"
        >
          {linkText.split('\n').map(str =>  <p>{str}</p>)}
        </div>
      </div>
      
    </Modal>
  )
}

export default ErrorInfoWithLinkModal
