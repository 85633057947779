import React from "react"

import "./style.css"

const ItemRow = ({ item, background, itemListNames }) => {
  let { title, amount, price } = item

  const itemName = itemListNames[title] ? itemListNames[title] : title
  const itemPoints = title === "Compensation" ? "₪" : ""
  const itemValue = title === "Compensation" ? -price : amount

  return (
    <div className={`ordl-itm-wrap ${background}`}>
      <div className={`ordl-itm-row-f itls-itm-row-flex2`}>{itemName}</div>
      <div className="ordl-itm-row-f">
        {itemValue}
        {itemPoints}
      </div>
    </div>
  )
}

export default ItemRow
