import {
  ON_USER_SIGN_IN_SUCCESS,
  ON_USER_SIGN_IN_FAIL,
  ON_DRIVER_SIGN_IN_SUCCESS,
  ON_DRIVER_SIGN_IN_FAIL,
  ON_USER_SIGN_OUT,
  RESET_ALL_REDUCERS,
  ON_USER_SIGN_IN_REQUEST,
  ON_TRANSLATION_CHANGE,
  FETCH_ORDER_BY_DELIVERY_CODE_SUCCESS,
  FETCH_ORDER_BY_DELIVERY_CODE_FAIL,
  USER_MOBILE_ALREADY_TAKEN_REQUEST,
  USER_MOBILE_ALREADY_TAKEN_TRUE,
  USER_MOBILE_ALREADY_TAKEN_FALSE,
  USER_OTP_MOBILE_ALREADY_EXIST,
  ON_BASE_ERROR,
  GET_IS_USER_HAD_ORDER_BEFORE_SUCCESS,
  GET_IS_USER_HAD_ORDER_BEFORE_FAIL,
  SET_CC_SHURED,
  RESET_CC_SHURED,
  SIGN_IN_WITH_OTP,
  SIGN_IN_WITH_OTP_FAIL,
  CLEAR_ERR_STATUS,
  ON_DRIVER_SIGN_IN_NOT_ALLOWED,
} from "../actions/types"

const INIT_STATE = {
  userId: null,
  role: null,
  firstName: null,
  lastName: null,
  token: null,
  authenticated: false,
  errTitle: null,
  errText: null,
  errStatus: null,
  isLoadingRequest: false,
  isPasswordTemporary: false,
  isRefillDetails: false,
  creditCardToken: null,
  isError: false,
  isDeliveryRole: false,
  isShowUnlockScreen: true,
  isHadOrderBefore: false,
  isCheckedHadOrderBefore: false,
  isCCShured: false,
  isCheckedCCShured: false,
  checkedMobile: null,
  mobile: null,
  isNeedAccessibility: false,
  isUserExist: false,
  isHideDriverReadyItemButton: false,
  showDriverNoLoginPermission: false,
  isMustCollectFirstRole: false,
}

const UserLoginReducer = (state = INIT_STATE, action) => {
  switch (action.type) {
    case ON_USER_SIGN_IN_REQUEST:
      return {
        ...INIT_STATE,
        isLoadingRequest: true,
      }
    case USER_MOBILE_ALREADY_TAKEN_REQUEST:
      return {
        ...INIT_STATE,
        isLoadingRequest: true,
      }
    case ON_USER_SIGN_IN_SUCCESS:
      return {
        ...state,
        ...action.payload,
        authenticated: true,
        isLoadingRequest: false,
        showDriverNoLoginPermission: false,
      }
    case ON_DRIVER_SIGN_IN_SUCCESS:
      return {
        ...state,
        ...action.payload,
        authenticated: true,
        isLoadingRequest: false,
      }
    case SIGN_IN_WITH_OTP:
      return {
        ...state,
        ...action.payload,
        authenticated: true,
      }
    case SIGN_IN_WITH_OTP_FAIL:
      return {
        ...state,
        ...action.payload,
      }
    case ON_USER_SIGN_IN_FAIL:
      return {
        ...state,
        authenticated: false,
        errTitle: action.payload.errTitle,
        errText: action.payload.errText,
        errStatus: action.payload.errStatus,
        isLoadingRequest: false,
        showDriverNoLoginPermission: false,
      }
    case ON_DRIVER_SIGN_IN_NOT_ALLOWED:
      return {
        ...state,
        authenticated: false,
        isLoadingRequest: false,
        showDriverNoLoginPermission: true,
      }

    case ON_DRIVER_SIGN_IN_FAIL:
      return {
        ...state,
        authenticated: false,
        errTitle: action.payload.errTitle,
        errText: action.payload.errText,
        errStatus: action.payload.errStatus,
        isLoadingRequest: false,
        showDriverNoLoginPermission: false,
      }
    case FETCH_ORDER_BY_DELIVERY_CODE_SUCCESS:
      return {
        ...state,
        ...action.payload.user,
        authenticated: true,
        isLoadingRequest: false,
      }

    case FETCH_ORDER_BY_DELIVERY_CODE_FAIL:
      return {
        ...INIT_STATE,
        errTitle: action.payload.status,
        errText: action.payload.status,
      }
    case USER_OTP_MOBILE_ALREADY_EXIST:
      return {
        ...state,
        isLoadingRequest: false,
        isPasswordTemporary: false,
        isUserExist: true,
        isError: false,
      }
    case USER_MOBILE_ALREADY_TAKEN_TRUE:
      return {
        ...state,
        isLoadingRequest: false,
        isPasswordTemporary: action.payload,
        isUserExist: true,
        isError: false,
      }
    case USER_MOBILE_ALREADY_TAKEN_FALSE:
      return {
        ...state,
        isLoadingRequest: false,
        isPasswordTemporary: false,
        isError: false,
      }
    case GET_IS_USER_HAD_ORDER_BEFORE_SUCCESS:
      return {
        ...state,
        isHadOrderBefore: action.payload,
        isCheckedHadOrderBefore: true,
        isError: false,
      }
    case GET_IS_USER_HAD_ORDER_BEFORE_FAIL:
      return {
        ...state,
        isHadOrderBefore: false,
        isCheckedHadOrderBefore: false,
        isError: true,
      }
    case SET_CC_SHURED:
      return {
        ...state,
        isCCShured: action.payload,
        isCheckedCCShured: true,
      }
    case RESET_CC_SHURED:
      return {
        ...state,
        isCCShured: false,
        isCheckedCCShured: false,
      }
    case ON_BASE_ERROR:
      return {
        ...state,
        isLoadingRequest: false,
        isError: true,
      }
    case ON_USER_SIGN_OUT:
      return {
        ...INIT_STATE,
      }
    case ON_TRANSLATION_CHANGE:
      return {
        ...INIT_STATE,
      }
    case CLEAR_ERR_STATUS:
      return {
        ...state,
        errStatus: null,
        showDriverNoLoginPermission: false,
      }
    case RESET_ALL_REDUCERS:
      return {
        ...INIT_STATE,
      }
    default:
      return state
  }
}

export default UserLoginReducer
