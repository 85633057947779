export const itemProccesesName = (proccess, translatedNames) => {
  switch (proccess) {
    case "ironing":
      return translatedNames.ironing_item
    case "ironing and package":
      return translatedNames.ironing_and_package_item
    case "laundry ironing":
      return translatedNames.laundry_ironing_item
    case "dry cleaning":
      return translatedNames.dry_cleaning_item
    case "leather cleaning":
      return translatedNames.leather_cleaning_item
    case "laundry":
      return translatedNames.laundry_item
    case "laundry detergents sale":
      return "מכירת חומרי כביסה"
    case "laundry ironing package":
      return translatedNames.laundry_ironing_package
    default:
      return proccess
  }
}
