import {
  USERS_REGISTER_REQUEST_FAILURE,
  USERS_REGISTER_STEP_1_DATA_SUBMIT,
  RESET_ALL_REDUCERS,
  USER_MOBILE_ALREADY_TAKEN_REQUEST,
  USER_MOBILE_ALREADY_TAKEN_TRUE,
  USER_MOBILE_ALREADY_TAKEN_FALSE,
  FETCH_ALL_STATIONS_DATA_SUCCESS,
  USERS_REGISTER_SET_PWD,
  ON_PELE_TOKEN_SAVED_IN_SERVER_SUCCESS,
  ON_PELE_TOKEN_SAVED_IN_SERVER_FAIL,
  ON_PELE_TOKEN_SAVED_IN_SERVER_REQUEST,
  ON_PELE_TOKEN_SAVED_IN_RETRY,
  ON_LOADING,
  COMPLETE_USER_DATA_SUCCESS,
  COMPLETE_USER_DATA_FAIL,
  COMPLETE_USER_DATA_RESET,
  ON_BASE_ERROR,
  ON_REGISTRATION_VERIFY_SUCCESS,
  ON_REGISTRATION_VERIFY_FAILED,
  CLEAR_REGISTRATION_DATA,
  USERS_REGISTER_STEP_2_DATA_SUBMIT,
  ON_USER_SIGN_IN_SUCCESS,
} from "../actions/types"

const INIT_STATE = {
  mobile: "",
  email: "",
  firstName: "",
  lastName: "",
  password: "",
  is1stStepSubmitted: false,
  is2ndStepSubmitted: false,
  mobileChecked: false,
  userMobileFree: false,
  loadingIfMobileTakenInBg: false,
  allStationsData: [],
  userTokenSavedStatus: -100, // 0 - PENDING, 1 - SUCCESS, -1 - ERROR -> -100 - NO OPERATION
  isLoading: false,
  isCompleteDetailsSuccessed: false,
  isCompleteDetails: false,
  isErrorRegistrationWithVerify: false,
}

const RegistrationReducer = (state = INIT_STATE, action) => {
  switch (action.type) {
    case USERS_REGISTER_STEP_1_DATA_SUBMIT:
      return {
        ...state,
        ...action.payload,
        is1stStepSubmitted: true,
        isErrorRegistrationWithVerify: false,
      }
    case USERS_REGISTER_STEP_2_DATA_SUBMIT:
      return {
        ...state,
        ...action.payload,
        is2ndStepSubmitted: true,
        isErrorRegistrationWithVerify: false,
      }
    case USER_MOBILE_ALREADY_TAKEN_REQUEST:
      return {
        ...state,
        mobile: null,
        loadingIfMobileTakenInBg: true,
        mobileChecked: false,
        userMobileFree: false,
      }
    case USER_MOBILE_ALREADY_TAKEN_TRUE:
      return {
        ...state,
        mobileChecked: true,
        userMobileFree: false,
        loadingIfMobileTakenInBg: false,
      }
    case USER_MOBILE_ALREADY_TAKEN_FALSE:
      return {
        ...state,
        mobile: action.payload,
        mobileChecked: true,
        userMobileFree: true,
        loadingIfMobileTakenInBg: false,
      }

    case FETCH_ALL_STATIONS_DATA_SUCCESS:
      return {
        ...state,
        allStationsData: action.payload,
      }
    case USERS_REGISTER_SET_PWD:
      return {
        ...state,
        password: action.payload,
      }
    case ON_PELE_TOKEN_SAVED_IN_SERVER_REQUEST:
      return {
        ...state,
        userTokenSavedStatus: 0,
      }
    case ON_PELE_TOKEN_SAVED_IN_SERVER_SUCCESS:
      return {
        ...state,
        userTokenSavedStatus: 1,
      }
    case ON_PELE_TOKEN_SAVED_IN_SERVER_FAIL:
      return {
        ...state,
        userTokenSavedStatus: -1,
      }
    case ON_PELE_TOKEN_SAVED_IN_RETRY:
      return {
        ...state,
        userTokenSavedStatus: -100,
      }
    case ON_LOADING:
      return {
        ...state,
        isLoading: true,
        isCompleteDetails: false,
        isCompleteDetailsSuccessed: false,
      }
    case COMPLETE_USER_DATA_SUCCESS:
      return {
        ...state,
        isLoading: false,
        isCompleteDetails: true,
        isCompleteDetailsSuccessed: true,
      }
    case COMPLETE_USER_DATA_FAIL:
      return {
        ...state,
        isLoading: false,
        isCompleteDetails: true,
        isCompleteDetailsSuccessed: false,
      }
    case ON_BASE_ERROR:
      return {
        ...state,
        isLoading: false,
      }
    case COMPLETE_USER_DATA_RESET:
      return {
        ...state,
        isLoading: false,
        isCompleteDetails: false,
        isCompleteDetailsSuccessed: false,
      }
    case ON_USER_SIGN_IN_SUCCESS:
    case ON_REGISTRATION_VERIFY_SUCCESS:
      return {
        ...state,
        isLoading: false,
        isErrorRegistrationWithVerify: false,
      }
    case ON_REGISTRATION_VERIFY_FAILED:
      return {
        ...state,
        isLoading: false,
        isErrorRegistrationWithVerify: true,
      }
    case CLEAR_REGISTRATION_DATA:
      return {
        mobile: "",
        email: "",
        firstName: "",
        lastName: "",
        password: "",
        is1stStepSubmitted: false,
        is2ndStepSubmitted: false,
        mobileChecked: false,
        userMobileFree: false,
        loadingIfMobileTakenInBg: false,
        isErrorRegistrationWithVerify: false,
        isLoading: false,
      }
    case RESET_ALL_REDUCERS:
      return {
        ...INIT_STATE,
      }
    default:
      return state
  }
}

export default RegistrationReducer
