import Leaflet from "leaflet"
import "leaflet/dist/leaflet.css"
import React, { Component } from "react"
import { MapContainer, Marker, TileLayer, Tooltip, useMap } from "react-leaflet"
import locationIcon from "./Icons/locationIcon"
import locationIconActive from "./Icons/locationIconActive"
import "./style.css"

Leaflet.Icon.Default.imagePath = "../node_modules/leaflet"

delete Leaflet.Icon.Default.prototype._getIconUrl

Leaflet.Icon.Default.mergeOptions({
  iconRetinaUrl: require("leaflet/dist/images/marker-icon-2x.png"),
  iconUrl: require("leaflet/dist/images/marker-icon.png"),
  shadowUrl: require("leaflet/dist/images/marker-shadow.png"),
})

const zoom = 13

function ChangeView({ center, zoom }) {
  const map = useMap()
  map.setView(center, zoom)
  return null
}

class StationsMap extends Component {
  showPreview(station) {
    // TODO show place's description
  }

  render() {
    const { stationsList, activeStation, bigMapClass } = this.props
    let center = [32.162577, 34.809667]

    if (activeStation) {
      center = [activeStation.latitude, activeStation.longitude]
    } else if (stationsList && stationsList.length > 0) {
      center = [stationsList[0].latitude, stationsList[0].longitude]
    }
    return (
      <div className={`map-wrapper ${bigMapClass}`}>
        <MapContainer
          center={center}
          zoom={zoom}
          scrollWheelZoom={false}
          attributionControl={false}
        >
          <ChangeView center={center} zoom={zoom} />
          <TileLayer url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png" />
          {stationsList.map((station) => {
            let icon = locationIcon
            if (activeStation && activeStation.stationId == station.stationId) {
              icon = locationIconActive
            }
            return (
              <Marker
                key={station.stationId}
                position={[station.latitude, station.longitude]}
                eventHandlers={{ click: () => this.props.onClick(station) }}
                icon={icon}
              >
                <Tooltip>{station.name}</Tooltip>
              </Marker>
            )
          })}
        </MapContainer>
      </div>
    )
  }
}

export default StationsMap
