import React, { useState } from "react"
import Modal from "react-modal"
import { DisableEnableButton, OrangeButton } from "../../common"
import VirtualKeyboard from "../../common/VirtualKeyboard"
import "./style.css"

const CollectWithBarcodeModal = ({
  show,
  onCloseClicked,
  title,
  orderData,
  onAddingBarcode,
  btnText,
  lockerNum,
  user_name_text,
  locker_number,
  order_number,
  dirRtl,
}) => {
  const [barcode, setBarcode] = useState("")

  const renderOrderDetails = (orderData) => {
    if (!orderData) {
      return
    }

    const { orderNumber, firstName, lastName, customerName } = orderData

    let customerNameDisplay = customerName
    if (
      !customerNameDisplay ||
      customerNameDisplay == null ||
      customerNameDisplay.length < 2
    ) {
      customerNameDisplay = `${firstName} ${lastName}`
    }

    return (
      <div className="order-details-wrapper">
        <div className="order-details-title" dir={dirRtl ? "rtl" : "ltr"}>
          {title}
        </div>
        <div className="order-details-list" dir={dirRtl ? "rtl" : "ltr"}>
          <div className="title-in-list">
            {`${user_name_text}: `}
            <span className="order-detail">{customerNameDisplay}</span>
          </div>
          <div className="title-in-list">
            {`${order_number}: `}
            <span className="order-detail">{orderNumber}</span>
          </div>
          <div className="title-in-list">
            {`${locker_number}: `}
            <span className="order-detail">{lockerNum}</span>
          </div>
        </div>
      </div>
    )
  }

  return (
    <Modal
      isOpen={show}
      onRequestClose={() => {
        setBarcode("")
        onCloseClicked()
      }}
      className="react-modal-content collect-modal-move-up"
      overlayClassName="modal-overlay"
    >
      {renderOrderDetails(orderData)}
      <div className="barcode-row">
        <DisableEnableButton
          onClick={() => {
            onAddingBarcode(barcode)
            setBarcode("")
            onCloseClicked()
          }}
          isDisabled={!barcode}
        >
          {btnText}
        </DisableEnableButton>
        <input
          className="collect-input-barcode"
          name="barcode"
          value={barcode}
          onChange={(e) => {
            setBarcode(e.target.value)
          }}
          maxLength={5}
          autoFocus
        />
      </div>
      <VirtualKeyboard
        currentValue={barcode}
        onKeyboardInputChange={(txt) => setBarcode(txt)}
        show={true}
        lang="en"
      />
    </Modal>
  )
}

export default CollectWithBarcodeModal
